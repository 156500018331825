import React from 'react';
import './Checkbox.css';

const Checkbox = ({
  ariaProps,
  checked = false,
  children,
  className = '',
  containerClassName = '',
  error = false,
  errorClasName = '',
  errorMessage = '',
  id = '',
  isRounded = false,
  keepSpaceError = false,
  name = '',
  onClick = () => { },
  readOnly = false,
}) => (
  <div className={containerClassName}>
    <label
      className={`Checkbox-container Check-box-label ${className}`
          + ` ${keepSpaceError ? 'Checkbox-keep-space' : ''}
        ${isRounded ? ' Checkbox-container-rounded' : ''}`}
      htmlFor={id}
    >
      <input
        {...ariaProps}
        aria-labelledby={`${id}-label`}
        checked={checked}
        className="Checkbox-input"
        id={id}
        name={name}
        onChange={onClick}
        readOnly={readOnly}
        type="checkbox"
      />

      <p className="Checkbox-content" id={`${id}-label`}>
        {children}
      </p>
    </label>

    <span
      aria-live="polite"
      className={`Checkbox-error-label ${errorClasName}`}
    >
      {error && errorMessage}
    </span>
  </div>
);

export default Checkbox;
