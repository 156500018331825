export const LANDING_URL = '/getQuote';
export const URL_YOUR_INFO = '/quoting/getquote/yourinfo/';
export const URL_ROOT_QUOTE = '/quoting/getquote';
export const URL_PURCHASE = '/quoting/purchase/success';
export const URL_PURCHASE_RETRY = '/quoting/purchase/retry';
export const URL_PURCHASE_ERROR = '/quoting/purchase/error';
export const URL_PURCHASE_BACKEND_ERROR = '/quoting/purchase/errors';
export const URL_COSTCO = '/Costco';
export const URL_COSTCO_CX = '/costco-cx';
export const URL_LOGOUT = '/logout';
export const URL_OPEN_QUOTE = '/openQuote';
export const URL_OPEN_QUOTE_ERROR = '/openQuote/error';
export const URL_LOGIN_ACCOUNT = '/login';
export const URL_GOOD_DOG = '/gooddog';
export const URL_USAA = '/usaa';
