import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const usePartnerNotes = () => {
  const store = useSelector((stores) => stores.quoting, shallowEqual);
  const {
    partners: {
      partnerId,
      data: {
        CustomFields,
      },
    },
  } = store;

  const disclaimers = useMemo(() => {
    if (!partnerId) {
      return [];
    }

    const disclaimerSection = CustomFields
      .filter((field) => field.Section === 'Disclaimer');

    return disclaimerSection || [];
  }, [CustomFields, partnerId]);

  return disclaimers;
};

export { usePartnerNotes };
