import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { saveNewPetPickupDate, updateRateQuote } from '../actions';
import { validatePickupDate, validatePickupDate7Days } from './partners.util';

export const useGoodDogData = () => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const dispatch = useDispatch();
  const { petPickupDate } = store?.partners;

  const isPickupDateValid = React
    .useMemo(() => validatePickupDate(petPickupDate), [petPickupDate]);

  const isPickupDateValid7Days = React
    .useMemo(() => validatePickupDate7Days(petPickupDate), [petPickupDate]);

  const onPetPickupDateChange = useCallback(({ target }) => {
    const { value } = target;

    validatePickupDate(value);

    dispatch(saveNewPetPickupDate(value));
  }, [dispatch]);

  const updateRateByPickupDate = useCallback(() => {
    const petQuoteList = store.data.petQuoteResponseList;
    const petSelected = petQuoteList.find((pet) => (
      pet.petQuoteId === store.petQuoteSelected));

    const {
      parameters,
      guidData,
      sessionInformation: { nopCommerceUser, userEmail },
    } = store;

    dispatch(updateRateQuote({
      diamondClientId: nopCommerceUser.DiamonClientdId,
      effectiveDateCustom: '',
      eMail: userEmail || parameters.email,
      employerName: '',
      guidPayload: guidData,
      modifiers: petSelected.modifiers,
      nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
      parameters,
      petQuoteList,
      promoCode: store.data.groupCode,
      quoteId: petSelected.petQuoteId,
      showLoading: true,
    }));
  }, [dispatch, store]);

  return {
    isPickupDateValid,
    isPickupDateValid7Days,
    onPetPickupDateChange,
    petPickupDate,
    updateRateByPickupDate,
  };
};
