const STORE_ATTRIBUTE = {
  GTM: 'store.gtm',
  PET_QUOTE_RESPONSE_LIST: 'petQuoteResponseList',
  QUOTING: 'store.quoting',
};
const DATALAYER_EVENT_TYPE = {
  ADD_TO_CART: 'add_to_cart',
  BEGIN_CHECKOUT: 'begin_checkout',
  PURCHASE: 'purchase',
  VIEW_ITEM: 'view_item',
};
const EMPTY_STRING = 'empty';
const FULL_POLICY = 'Full Policy';
const NO_POWERUPS = 'No Powerups';
const ALL_POWERUPS = 'All Powerups';
const NO_PARTNER_CODE = 'No Partner Code';
const PATH_ALLOWED = {
  VIEW_ITEM: ['/quoting/getquote'],
};

const NO_PARTNER = 'No Partner';

export {
  ALL_POWERUPS,
  DATALAYER_EVENT_TYPE,
  EMPTY_STRING,
  FULL_POLICY,
  NO_PARTNER_CODE,
  NO_POWERUPS,
  PATH_ALLOWED,
  STORE_ATTRIBUTE,
  NO_PARTNER,
};
