import React, { useState } from 'react';

import infoIcon from '../../assets/info.png';

import './TooltipDiscount.css';

const TooltipDiscount = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  function handleHoverIn() {
    setTooltipVisible(true);
  }

  function handleHoverOut() {
    setTooltipVisible(false);
  }

  const contentContent = () => {
    if (!tooltipVisible) {
      return null;
    }

    return (
      <div className="Tooltip-discount-overlayed-container">
        <span>
          Estimated annual savings may vary from final amount at checkout.
        </span>

        <div className="Tooltip-discount-arrow-down" />
      </div>
    );
  };

  return (
    <div className={tooltipVisible ? 'Tooltip-discount-main-container' : ''}>
      <div
        className="Tooltip-discount-icon-container"
        onMouseEnter={handleHoverIn}
        onMouseLeave={handleHoverOut}
      >
        <img alt="" src={infoIcon} />
      </div>

      {contentContent()}
    </div>
  );
};

export { TooltipDiscount };
