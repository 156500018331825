import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import infoSelected from '../../../assets/info-blue-light.svg';
import info from '../../../assets/info-white.svg';

import { WhatsCoveredLink } from '../WhatsCoveredLink';
import { PowerUpTitle } from './PowerUpTitle';
import Switch from '../../common/Switch';

import {
  currencyFormat,
  isPaycheck,
  usePowerupsRadioButtons,
} from '../../../util';

import './PowerUpCard.css';
import { RadioButton } from '../../common/RadioButton';
import { useResponsive } from '../../../hooks';
import { removePowerupIdError } from '../../../actions';

const PowerUpCard = ({
  ariaLabel = '',
  bundleCoverageClick = () => { },
  bundleModifierAdd = () => { },
  bundleModifierCheckSelection = () => { },
  content,
  error = false,
  handleAddPowerup,
  handleRemovePowerup,
  hideInfoImage,
  onClickWhatsCovered,
  petQuoteSelected,
  powerUpInfo,
  price,
  selected = null,
  startingText,
  title,
}) => {
  const { t } = useTranslation('quoting');
  const [isMounted, setMounted] = useState(false);
  const [localSelected, setLocalSelected] = useState(selected);
  const switchRef = useRef(null);
  const { isMobile } = useResponsive();
  const dispatch = useDispatch();

  useEffect(() => {
    setLocalSelected(selected);
  }, [petQuoteSelected, selected]);

  useEffect(() => {
    if (!isMounted) {
      setMounted(true);
    }
  }, [isMounted]);

  const handleInfoPowerup = ({ powerUp }) => () => {
    if (powerUp.PMSModifierId) {
      onClickWhatsCovered();
    }
  };

  function renderInfoImage() {
    if (hideInfoImage) {
      return null;
    }

    return (
      <WhatsCoveredLink
        className="Powerup-whats-covered "
        icon={localSelected ? infoSelected : info}
        id={title}
        onClick={handleInfoPowerup({ powerUp: powerUpInfo })}
      />
    );
  }

  const handleAddPowerupCallback = useCallback((event) => {
    if (!localSelected && handleAddPowerup) {
      handleAddPowerup(event);
    }

    if (error) {
      dispatch(removePowerupIdError(powerUpInfo.Id));
    }
  }, [dispatch, error, handleAddPowerup, localSelected, powerUpInfo.Id]);

  const handleRemovePowerupCallback = useCallback((event) => {
    if ((localSelected || localSelected === null) && handleRemovePowerup) {
      handleRemovePowerup(event);
      if (!usePowerupsRadioButtons) {
        switchRef.current.focus();
      }
    }
    if (error) {
      dispatch(removePowerupIdError(powerUpInfo.Id));
    }
  }, [dispatch, error, handleRemovePowerup, localSelected, powerUpInfo.Id]);

  const onClick = useCallback((event) => {
    if (!localSelected) {
      handleAddPowerupCallback(event);
    }

    if (localSelected) {
      handleRemovePowerupCallback(event);
    }
  }, [
    handleAddPowerupCallback,
    handleRemovePowerupCallback,
    localSelected,
  ]);

  function renderPowerupPrice() {
    if (String(price) === '0.00') {
      return null;
    }

    return (
      <section aria-labelledby={t('paymentPriceAndBilling')}>
        <span className="Powerup-card-price-text">
          {startingText}${String(price).split('.')[0]}.{String(price)
            .split('.')[1]}{isPaycheck
              ? t('powerUpsSection.paymentCycle.payCheck')
              : t('powerUpsSection.paymentCycle.monthly')}
        </span>
      </section>
    );
  }

  const handleAddPowerupOption = useCallback((event) => {
    if (!localSelected) {
      handleAddPowerupCallback(event);
    }
  }, [
    handleAddPowerupCallback,
    localSelected,
  ]);

  const handleRemovePowerupOption = useCallback((event) => {
    if (localSelected || localSelected === null) {
      handleRemovePowerupCallback(event);
    }
  }, [
    handleRemovePowerupCallback,
    localSelected,
  ]);

  function renderOptions() {
    const contentModifiers = powerUpInfo.BundleInsuranceModifiers
      ?.filter((modifier) => modifier.IsVisible && modifier.IsActive);

    if (contentModifiers && contentModifiers.length) {
      const bundleModifiers = [...contentModifiers].reverse();

      return bundleModifiers.map((item, index) => (
        <RadioButton
          key={item.Id}
          checked={!!bundleModifierCheckSelection(item.Id)}
          name={`${index}-${title.replace(/\s/g, '')}`}
          onChange={bundleModifiers.length >= 2
            ? bundleModifierAdd(
              {
                isSelected: !bundleModifierCheckSelection(item.Id),
                powerUp: item,
              },
            )
            : handleAddPowerupOption}
        >
          <div className="Powerup-card-option-wrapper">
            <span className="Powerup-card-option">
              {t('powerUpsSection.yesPowerup', {
                powerup: `${powerUpInfo.TitleText} ${item.TitleText}`,
              })}

              <span>
                ${currencyFormat({ value: item.FeeAmount })}

                {isMobile
                  ? t('powerUpsSection.paymentCycle.monthly')
                  : t('powerUpsSection.paymentCycle.monthlyLong')}
              </span>
            </span>

            <WhatsCoveredLink
              className="Powerup-wellness-link-content-radio"
              icon={bundleModifierCheckSelection(item.Id)
                ? infoSelected : info}
              id={`whatsCovered${item.Id}`}
              onClick={bundleCoverageClick(item)}
            />
          </div>
        </RadioButton>
      ));
    }

    return (
      <RadioButton
        checked={!!localSelected}
        name={`yes-${title.replace(/\s/g, '')}`}
        onChange={handleAddPowerupOption}
      >
        <span className="Powerup-card-option">
          {t('powerUpsSection.yesPowerup', { powerup: powerUpInfo.TitleText })}

          <span>
            ${currencyFormat({ value: price })}

            {isMobile
              ? t('powerUpsSection.paymentCycle.monthly')
              : t('powerUpsSection.paymentCycle.monthlyLong')}
          </span>
        </span>
      </RadioButton>
    );
  }

  return (
    <section
      aria-labelledby={t('PowerupContainerDetails')}
      className={`Powerup-card-container${localSelected ? '-selected' : ''}`}
      data-error={error}
      id={`Powerup-card-section-${powerUpInfo.Id}`}
    >
      <div className="Powerup-card-body-content">
        <PowerUpTitle
          addedLabel={t('powerUpsSection.added')}
          localSelected={localSelected}
          title={title}
        >
          {usePowerupsRadioButtons
            ? renderInfoImage()
            : renderPowerupPrice()}
        </PowerUpTitle>

        <div className="Powerup-card-content">
          <section aria-labelledby={t('PowerupButtons')}>
            {content}

            <div
              className={'Powerup-card-actions'
                + `${usePowerupsRadioButtons ? '-radios' : ''}`
                + `${hideInfoImage ? ' Powerup-card-actions-end' : ''}`}
            >
              {usePowerupsRadioButtons
                ? (
                  <>
                    {renderOptions()}

                    <RadioButton
                      checked={localSelected === false}
                      name={`no-${title.replace(/\s/g, '')}`}
                      onChange={handleRemovePowerupOption}
                    >
                      {t('powerUpsSection.noPowerup')}
                    </RadioButton>
                  </>
                )
                : (
                  <>
                    {renderInfoImage()}

                    <Switch
                      active={localSelected}
                      ariaProps={{
                        'aria-label': `${localSelected ? 'Remove Powerup'
                          : 'Add Powerup'} ${ariaLabel}`,
                      }}
                      customClassname={'Powerup-switch'
                        + `${localSelected ? ' Powerup-added' : ''}`}
                      onClick={onClick}
                      switchBallColor={localSelected
                        ? 'var(--deep-blue-main)'
                        : '#fff'}
                      switchRef={switchRef}
                    />
                  </>
                )}

              <span aria-live="polite" className="Powerup-card-error">
                {error ? t('powerUpsSection.required') : ''}
              </span>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export { PowerUpCard };
