import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import cloudsIcon from '../../assets/clouds-icon-citron.svg';
import successIcon from '../../assets/ills/ill-7.svg';
import comingSoonIcon from '../../assets/coming-soon-icon.svg';
import { CustomModal } from '../common/CustomModal';
import FigoLottie from '../common/FigoLottie';
import InputWithError from '../common/InputWithError';
import { CUSTOMER_SERVICE_PHONE_NUMBER_COMING_SOON } from '../../constants';
import './ComingSoonModal.css';
import {
  COMING_SOON_ERROR_MODAL_CLOSED,
  COMING_SOON_MODAL_CLOSED,
  COMING_SOON_SUCCESS_MODAL_CLOSED,
  NEW_CUSTOMER_RESET_EMAIL,
  ZIP_CODE_VALIDATION_FAILED,
} from '../../actions/types';
import { newCustomerChanged, notifyMeEmailQuote } from '../../actions/quoting';
import { validateEmail } from '../../util';
import { URL_COSTCO } from '../../routes';

const ComingSoonModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const {
    newCustomer: {
      address,
      email,
    },
    sessionInformation: {
      notifyMeButtonLoading,
      showComingSoonModal,
      showErrorComingSoonModal,
      showSuccessComingSoonModal,
    },
    partners: {
      isCostco,
      notifyMeEmail,
    },
  } = store;
  const modalVisible = showComingSoonModal && isCostco;
  const [modalEmail, setModalEmail] = useState(email);
  const [modalEmailError, setModalEmailError] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (showComingSoonModal) {
      setModalEmail(notifyMeEmail.email || email);
    }
  }, [showComingSoonModal, notifyMeEmail.email, email]);

  function setInitialEmail(initialEmail) {
    setModalEmail(initialEmail);
  }

  function onBlurEmail(value) {
    let error = value ? '' : 'Email Address is required';

    if (modalEmail) {
      error = validateEmail(modalEmail) ? '' : 'Invalid email address';
    }
    setModalEmailError(error);
  }

  function handleEmailChanged(event) {
    const { target: { value } } = event;
    setModalEmail(value);
  }

  function onFocusEmail() {
    setModalEmailError('');
  }

  function renderPrimaryButtonText() {
    if (notifyMeButtonLoading) {
      return (
        <FigoLottie
          height={30}
          width={30}
        />
      );
    }

    return <span>Notify me</span>;
  }

  const resetEmailLogin = location.pathname.includes('quoting/getQuote');

  const handleCloseModal = (elementId) => () => {
    let type = null;
    const { invalidQuote } = notifyMeEmail;

    switch (elementId) {
      case 'ComingSoonModal':
        setInitialEmail(notifyMeEmail.email);
        type = COMING_SOON_MODAL_CLOSED;
        break;
      case 'SuccessComingSoonModal':
        type = COMING_SOON_SUCCESS_MODAL_CLOSED;
        break;
      case 'ErrorComingSoonModal':
        type = COMING_SOON_ERROR_MODAL_CLOSED;
        break;
      default: {
        break;
      }
    }

    if (invalidQuote) {
      history.push(URL_COSTCO);
      return;
    }

    if (type) {
      dispatch({ type });
    }

    if (resetEmailLogin) {
      dispatch(newCustomerChanged({
        type: NEW_CUSTOMER_RESET_EMAIL,
        value: true,
      }));
    }
  };

  function errorModalButtonClick() {
    dispatch({ type: ZIP_CODE_VALIDATION_FAILED });
  }

  function renderErrorModal() {
    const errorMessage = `Sorry, we're unable to process this request.
    Please try again or contact Customer Service
    at ${CUSTOMER_SERVICE_PHONE_NUMBER_COMING_SOON}.`;

    return (
      <CustomModal
        buttonText="Try Again"
        description={errorMessage}
        displayButton
        handleOutClick={handleCloseModal('ErrorComingSoonModal')}
        id="ErrorComingSoonModal"
        imageSrc={cloudsIcon}
        imgAlt="errorClouds"
        onButtonClick={errorModalButtonClick}
        title="An error ocurred."
        visible={showErrorComingSoonModal}
      />
    );
  }

  function renderSuccessModal() {
    const successMessage =
      `We’ll be in contact when this offering is available in
      your state.`;

    return (
      <CustomModal
        buttonText="Close"
        description={successMessage}
        disableClickOutside
        displayButton
        handleOutClick={handleCloseModal('SuccessComingSoonModal')}
        id="SuccessComingSoonModal"
        imageSrc={successIcon}
        imgAlt="desk-help-lady-and-dog"
        onButtonClick={handleCloseModal('SuccessComingSoonModal')}
        title="All set!"
        visible={showSuccessComingSoonModal}
      />
    );
  }

  async function onSubmitEmail(event) {
    event.preventDefault();

    onBlurEmail(modalEmail);

    if (modalEmailError || modalEmail === '') {
      return;
    }

    const { stateAbbr } = address;
    const {
      partners: { comingSoonZipcode, partnerId },
    } = store;
    const {
      petAge,
      petBreed,
      petBreedId,
      petName,
      petSex,
    } = notifyMeEmail;

    await dispatch(notifyMeEmailQuote({
      age: petAge,
      breed: petBreed,
      email: modalEmail,
      gender: petSex,
      partnerId,
      petBreedId,
      petName,
      state: stateAbbr,
      zipCode: comingSoonZipcode,
    }));

    setInitialEmail(notifyMeEmail.email);
  }

  function renderComingSoonModal() {
    if (modalVisible) {
      const modalDescription = `Sign up to be notified when this offering is
    available to Costco members in your state!`;

      return (
        <>
          <CustomModal
            description={modalDescription}
            disableClickOutside
            displayButton={false}
            handleOutClick={(handleCloseModal('ComingSoonModal'))}
            id="ComingSoonModal"
            imageSrc={comingSoonIcon}
            imgAlt="coming-soon-icon-usa"
            title="Coming soon to your state!"
            visible={modalVisible}
          >
            <form
              aria-label="Coming Soon Modal"
              className="Coming-soon-button-container"
              onSubmit={onSubmitEmail}
            >
              <div className="Coming-soon-input-container">
                <div className="Coming-soon-input-email-container">
                  <span className="Coming-soon-input-text">Email</span>
                  <InputWithError
                    containerClass="Coming-soon-container-class"
                    id="inpEmailUserComingSoon"
                    inputClass="Coming-soon-input-email"
                    labelError={modalEmailError}
                    onBlur={onBlurEmail}
                    onChange={handleEmailChanged}
                    onFocus={onFocusEmail}
                    placeholder="Email"
                    required
                    showError={modalEmailError}
                    type="email"
                    value={modalEmail}
                  />
                </div>

                <button
                  aria-label="Notify me"
                  className="Coming-soon-notify-user-primary-button"
                  disabled={modalEmailError}
                  onClick={onSubmitEmail}
                  title="Notify me"
                  type="submit"
                >
                  <span
                    className="Coming-soon-notify-user-primary-button-text"
                  >
                    {renderPrimaryButtonText()}
                  </span>
                </button>
              </div>
            </form>
          </CustomModal>

          {renderSuccessModal()}

          {renderErrorModal()}
        </>
      );
    }

    return null;
  }

  return renderComingSoonModal();
};

export { ComingSoonModal };
