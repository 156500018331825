import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import checkIcon from '../../../assets/purchase/check.svg';
import { SMSSignedUp } from '../../../actions';
import {
  PRIVACY_POLICY_LINK,
  TEN_DIGIT_NUMBER,
  TERMS_OF_USE_LINK_SMS,
} from '../../../constants';
import { phoneMask } from '../../../util';

import InputWithError from '../../common/InputWithError';
import { LoadingButton } from '../../common/LoadingButton';
import Toggle from '../../common/Toggle';

import './TextAlertsSection.css';

const TextAlertsSection = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [acceptSMS, setAcceptSMS] = useState(false);
  const [signUpError, setSignUpError] = useState('');
  const store = useSelector(({ quoting }) => ({
    purchase: quoting.purchase,
    sessionInformation: quoting.sessionInformation,
    textAlerts: quoting.textAlerts,
  }));
  const { t } = useTranslation('purchaseResult');
  const dispatch = useDispatch();

  useEffect(() => {
    setSignUpError(store.textAlerts.error ? t('somethingWentWrong') : '');
  }, [t, store.textAlerts.error]);

  const signUpAvailable = useMemo(() => (acceptSMS
    && TEN_DIGIT_NUMBER.test(phoneNumber)), [acceptSMS, phoneNumber]);

  function handlePhoneNumberChange(event) {
    setPhoneNumber(event.target.value.replace(/\D/g, ''));
  }

  function handleSwitchChange() {
    setSignUpError('');
    setAcceptSMS((prevAcceptSMS) => {
      if (!phoneNumber.length) {
        setPhoneNumberError(!prevAcceptSMS ? t('requiredPhone') : '');
      }
      return !prevAcceptSMS;
    });
  }

  function handlePhoneNumberBlur() {
    if (phoneNumber.length && !TEN_DIGIT_NUMBER.test(phoneNumber)) {
      setPhoneNumberError(t('invalidPhoneNumber'));
    } else if (acceptSMS && !phoneNumber.length) {
      setPhoneNumberError(t('requiredPhone'));
    } else {
      setPhoneNumberError('');
    }
  }

  function handlePhoneNumberFocus() {
    setPhoneNumberError('');
    setSignUpError('');
  }

  function handleSignUp() {
    if (signUpAvailable) {
      const { sessionInformation, purchase } = store;
      dispatch(SMSSignedUp({
        customerId: purchase.customerId,
        diamondClientId: sessionInformation.nopCommerceUser.DiamonClientdId,
        email: sessionInformation.userEmail,
        phoneNumber,
        policyNumbers: purchase.policyNumbers
          .map((policyBuyed) => policyBuyed.PolicyNumber),
      }));
      return;
    }

    if (!acceptSMS) {
      setSignUpError(t('requiredToggle'));
    } else if (!phoneNumber.length) {
      setPhoneNumberError(t('requiredPhone'));
    }
  }

  const openExternal = (url) => () => {
    window.open(url, '_blank');
  };

  return (
    <div className="Text-alerts-section">
      <h3>{t('textAlertsTitle')}</h3>

      <p className="Text-alerts-section-desc">{t('textAlertsDescription')}</p>

      <InputWithError
        containerClass="Text-alerts-phone"
        id="phoneNumberSMS"
        label={t('phoneNumber')}
        labelClass="Text-alerts-phone-label"
        labelError={phoneNumberError}
        maxLength={12}
        onBlur={handlePhoneNumberBlur}
        onChange={handlePhoneNumberChange}
        onFocus={handlePhoneNumberFocus}
        placeholder={t('optional')}
        showError={phoneNumberError}
        type="text"
        value={phoneMask(phoneNumber)}
      />

      <Toggle
        checked={acceptSMS}
        className="Text-alert-toggle"
        id="smsAlertsToggle"
        labelClassName="Text-alert-toggle-label"
        onChange={handleSwitchChange}
      >
        <b>{t('smsDisclaimer.start')}</b>

        &nbsp;{t('smsDisclaimer.disclaimer')}

        <button
          className="Text-alert-terms-button"
          onClick={openExternal(TERMS_OF_USE_LINK_SMS)}
          type="button"
        >
          {t('smsDisclaimer.termsAndConditions')}
        </button>

        &nbsp;&amp;&nbsp;

        <button
          className="Text-alert-terms-button"
          onClick={openExternal(PRIVACY_POLICY_LINK)}
          type="button"
        >
          {t('smsDisclaimer.privacyPolicy')}
        </button>
      </Toggle>

      <div aria-live="polite" className="Text-alerts-section-submit-section">
        {store.textAlerts.success ? (
          <div className="Text-alert-success">
            <img alt="" src={checkIcon} />

            <span>{t('successText')}</span>
          </div>
        ) : (
          <LoadingButton
            buttonEvents={{
              'aria-disabled': !!signUpError,
              onClick: handleSignUp,
            }}
            className="Text-alerts-section-submit"
            isLoading={store.textAlerts.loading}
            loadingColor="white"
            submit={false}
          >
            {t('signUp')}
          </LoadingButton>
        )}
      </div>

      <span aria-live="polite" className="Text-alert-error">{signUpError}</span>
    </div>
  );
};

export { TextAlertsSection };
