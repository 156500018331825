import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { DropdownAddPet } from '../DropdownAddPet';
import FigoLottie from '../FigoLottie';
import Shimmer from '../Shimmer';

import {
  PRIVACY_POLICY_LINK,
  PetGender,
  PetType,
  TERMS_OF_USE_LINK_GENERAL,
} from '../../../constants';
import { AddPetSpecieSelector } from './AddPetSpecieSelector';
import {
  saveNewPetType,
  saveNewPetSex,
  changeTermsAndPolicyCheckbox,
} from '../../../actions/quoting';
import { AddPetGenderSelector } from './AddPetGenderSelector';
import { useSavedElement, useShowFF } from '../../../hooks';
import { usePartnersData } from '../../../partners/usePartnersData';
import TooltipContainer from '../TooltipContainer';
import Checkbox from '../Checkbox';

import GoodDogInfoIcon from '../../../assets/tooltip-good-dog.svg';
import { pickupDateMin } from '../../../partners/partners.util';

const PICKUP_DATE_MIN = pickupDateMin();

const AddPetForm = ({
  ageError = '',
  breedError = '',
  emailError = '',
  getQuote = false,
  handleEmail = () => { },
  handleOnCancelClick = () => { },
  handlePetNameChange = () => { },
  handlePromoCodeChange = () => { },
  handleSubmitPetForm = () => { },
  handleZipcodeChange = () => { },
  InputError = () => { },
  isEditing = false,
  onBlurEmail = () => { },
  onBlurPetName = () => { },
  onBlurPromoCode = () => { },
  onBlurZipcode = () => { },
  onFocusEmail = () => { },
  onFocusPetName = () => { },
  onFocusZipcode = () => { },
  onKeyPress = () => { },
  onPetPickupDateChange = () => { },
  petAgeRef = null,
  petBreedRef = null,
  petEmailRef = null,
  petGenderError = '',
  petGenderRef = null,
  petNameError = '',
  petNameRef = null,
  petPickupDateRef = null,
  petTypeError = '',
  petTypeRef = null,
  petZipcodeRef = null,
  setPetGenderError = () => { },
  setPetTypeError = () => { },
  setTermsPolicyError = () => { },
  show,
  termsPolicyError = false,
  zipCodeError = '',
}) => {
  const store = useSelector((stores) => stores.quoting);
  const {
    acceptedTermsAndPolicy,
    ageData,
    sessionInformation,
    newPet: {
      breedCat,
      breedDog,
      email,
      groupCodeDscr,
      petAge,
      petBreed,
      petName,
      petSex,
      petType,
      promoCode,
      zipcode,
    },
    parameters,
  } = store;
  const {
    isGoodDog,
    isPartner,
    goodDogData: { petPickupDate, isPickupDateValid },
  } = usePartnersData();

  const { t } = useTranslation('quoting');
  const dispatch = useDispatch();
  const showShimmer = !ageData && !breedDog.length && !breedCat.length;
  const shimmerWidth = (getQuote || showShimmer) ? { width: '100%' } : {};
  const promoDesc = parameters.groupCodeDscr || '';

  /* For testing purposes, remove once FF are validated */
  const showFF = useShowFF();

  const { goToSavedElementAndForgot } = useSavedElement();

  const breedList = useMemo(() => {
    if (petType.value === PetType.None.value) {
      return [];
    }

    return petType.value === PetType.Dog.value ? breedDog : breedCat;
  }, [petType, breedDog, breedCat]);

  const hasCodeGroupDscr = useMemo(() => (groupCodeDscr && getQuote),
    [getQuote, groupCodeDscr]);

  const dogSelected = petType.value === PetType.Dog.value
    ? {
      label: 'Dog Selected',
      selected: true,
    } : {
      label: 'Dog UnSelected',
      selected: false,
    };

  const catSelected = petType.value === PetType.Cat.value
    ? {
      label: 'Cat Selected',
      selected: true,
    } : {
      label: 'Cat UnSelected',
      selected: false,
    };

  const petGenderSelected = () => {
    if (petSex.value === PetGender.None.value) {
      return {
        femaleGender: {
          className: '',
          label: `${PetGender.Female.name} Gender UnSelected`,
          selected: false,
        },
        maleGender: {
          className: '',
          label: `${PetGender.Male.name} Gender UnSelected`,
          selected: false,
        },
      };
    }

    return (
      petSex.value === PetGender.Male.value
        ? {
          femaleGender: {
            className: '',
            label: `${PetGender.Female.name} Gender UnSelected`,
            selected: false,
          },
          maleGender: {
            className: ' Modal-add-pet-gender-selector-item-selected',
            label: `${PetGender.Male.name} Gender Selected`,
            selected: true,
          },
        } : {
          femaleGender: {
            className: ' Modal-add-pet-gender-selector-item-selected',
            label: `${PetGender.Female.name} Gender Selected`,
            selected: true,
          },
          maleGender: {
            className: '',
            label: `${PetGender.Male.name} Gender UnSelected`,
            selected: false,
          },
        });
  };

  const onCancelClick = useCallback((event) => {
    event.preventDefault();

    handleOnCancelClick();
  }, [handleOnCancelClick]);

  const onChangeAcceptTerms = useCallback(() => {
    dispatch(changeTermsAndPolicyCheckbox(!acceptedTermsAndPolicy));
    setTermsPolicyError(true);
  }, [dispatch, acceptedTermsAndPolicy, setTermsPolicyError]);

  useEffect(() => {
    if (store.message === '' && !getQuote && show) {
      petNameRef.current.focus();
    }

    return () => {
      if (!getQuote) {
        // Minimal wait to focus the pet dropdown correctly on cancel.
        // I don't know, it works, if you have a better idea, change it please
        setTimeout(goToSavedElementAndForgot, 0.01);
      }
    };
  }, [
    petNameRef,
    store.message,
    getQuote,
    show,
    goToSavedElementAndForgot,
  ]);

  function zipCodeEditable() {
    if (sessionInformation.nopCommerceUser.LoginSuccess) {
      return false;
    }

    return getQuote || (isEditing
      && store && store.data.petQuoteResponseList.length === 1);
  }

  const getZipCodeValue = useCallback(() => {
    if (getQuote || isEditing) {
      return zipcode;
    }

    return parameters && parameters.petZipCode;
  }, [getQuote, isEditing, parameters, zipcode]);

  const progressIdCount = useMemo(() => {
    const valuesArray = [{
      input: 'petName',
      value: petName,
    },
    {
      input: 'petType',
      value: petType.name,
    },
    {
      input: 'petGender',
      value: petSex.name,
    },
    {
      input: 'zipCode',
      value: getZipCodeValue(),
    },
    {
      input: 'user-email',
      value: email,
    },
    {
      input: 'AgeSearchInput',
      value: petAge || '',
    },
    {
      input: 'BreedSearchInput',
      value: petBreed || '',
    }];

    return valuesArray.filter((item) => item.value === '').length;
  }, [
    email,
    getZipCodeValue,
    petAge,
    petBreed,
    petName,
    petSex.name,
    petType.name,
  ]);

  const progressQueue = useMemo(
    () => t('progressQueueText', {
      count: progressIdCount,
      numberOfFields: progressIdCount,
    }),
    [progressIdCount, t],
  );

  const promoCodeMemo = useMemo(() => {
    if (groupCodeDscr) {
      return groupCodeDscr;
    }

    return promoCode || '';
  }, [groupCodeDscr, promoCode]);

  function renderButtons() {
    if (getQuote) {
      return (
        <>
          <Checkbox
            checked={acceptedTermsAndPolicy}
            className={'Modal-add-pet-terms-and-privacy'
              + `${termsPolicyError
                ? ' Modal-add-pet-terms-and-privacy-error' : ''}`}
            containerClassName="Modal-add-pet-terms-and-policy-wrapper"
            id="terms-and-privacy"
            onClick={onChangeAcceptTerms}
          >
            <Trans
              components={{
                policy: (
                  <a
                    aria-label={t('privacyLink')}
                    className="Modal-add-pet-links Modal-add-pet-link-margin"
                    href={PRIVACY_POLICY_LINK}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                ),
                term: (
                  <a
                    aria-label={t('termsLink')}
                    className="Modal-add-pet-links"
                    href={TERMS_OF_USE_LINK_GENERAL}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                ),
              }}
              i18nKey="termsAndPrivacy"
              ns="quoting"
            />
          </Checkbox>

          <button
            aria-describedby="progressQueueId"
            className={'Modal-add-pet-get-quote-button'
              + `${showFF ? ' Test-feature-flag' : ''}`}
            onClick={handleSubmitPetForm}
            type="button"
          >
            {t('getQuote')}
          </button>

          <p
            className="Modal-add-pet-log"
            id="progressQueueId"
          >
            {progressQueue}
          </p>
        </>
      );
    }

    let ariaLabel = isEditing ? 'Continue' : 'Save';
    let buttonContent = isEditing ? 'Continue' : 'Save';

    if (sessionInformation.validateZipCodeLoading) {
      ariaLabel = 'Loading';

      buttonContent = (
        <FigoLottie
          height={30}
          width={30}
        />
      );
    }

    return (
      <>
        <button
          className="Modal-add-pet-footer-cancel-button"
          onClick={onCancelClick}
          type="button"
        >
          {t('cancel')}
        </button>

        <button
          aria-describedby="progressQueueId"
          aria-label={ariaLabel}
          className="Modal-add-pet-footer-save-button"
          onClick={handleSubmitPetForm}
          type="button"
        >
          {buttonContent}
        </button>

        <p
          className="Modal-add-pet-log"
          id="progressQueueId"
        >
          {progressQueue}
        </p>
      </>
    );
  }

  const handleDogSelected = useCallback((event) => {
    event.preventDefault();

    if (petType !== PetType.Dog) {
      dispatch(saveNewPetType(PetType.Dog));
    }

    setPetTypeError('');
  }, [dispatch, petType, setPetTypeError]);

  const handleCatSelected = useCallback((event) => {
    event.preventDefault();

    if (petType !== PetType.Cat) {
      dispatch(saveNewPetType(PetType.Cat));
    }

    setPetTypeError('');
  }, [dispatch, petType, setPetTypeError]);

  const handleFemaleGenderSelected = useCallback((event) => {
    event.preventDefault();

    dispatch(saveNewPetSex(PetGender.Female));
    setPetGenderError('');
  }, [dispatch, setPetGenderError]);

  const handleMaleGenderSelected = useCallback((event) => {
    event.preventDefault();

    dispatch(saveNewPetSex(PetGender.Male));
    setPetGenderError('');
  }, [dispatch, setPetGenderError]);

  function renderPetNameToolTips() {
    if (!isGoodDog) {
      return null;
    }

    return (
      <TooltipContainer
        arrowPaddingLeft={20}
        arrowPosition="start"
        backgroundColor="var(--citron-shade-3)"
        description={t('goodDog.petNameToolTip')}
      >
        <img alt="" className="Modal-tooltip-info" src={GoodDogInfoIcon} />
      </TooltipContainer>

    );
  }

  function renderPickupDateToolTips() {
    if (!isGoodDog) {
      return null;
    }

    return (
      <TooltipContainer
        arrowPaddingLeft={20}
        arrowPosition="start"
        backgroundColor="var(--citron-shade-3)"
        description={t('goodDog.pickupDateToolTip')}
      >
        <img alt="" className="Modal-tooltip-info" src={GoodDogInfoIcon} />
      </TooltipContainer>

    );
  }

  return (
    <form className="Modal-add-pet-form">
      <Shimmer
        className={`Modal-add-pet-input-wrapper
        ${getQuote ? 'Modal-add-pet-input-wrapper-landing' : ''}`}
        visible={showShimmer}
        {...shimmerWidth}
      >
        <label
          className="Add-pet-form-label"
          htmlFor="petName"
          id="petNamelabel"
        >
          {t('petName')}

          {renderPetNameToolTips()}

          <input
            ref={petNameRef}
            aria-describedby="progressQueueId"
            aria-invalid={petNameError}
            aria-required={!!petName}
            className={'Modal-add-pet-input-container '
              + `${petNameError && 'Modal-add-pet-input-container-error'}`}
            id="petName"
            name="petName"
            onBlur={onBlurPetName}
            onChange={handlePetNameChange}
            onFocus={onFocusPetName}
            placeholder="Required"
            type="text"
            value={petName}
          />
        </label>

        <InputError error={petNameError} />
      </Shimmer>

      <div
        className={`Modal-add-pet-input-wrapper
        ${getQuote ? 'Modal-add-pet-input-wrapper-landing' : ''}`}
      >
        <AddPetSpecieSelector
          catSelected={catSelected}
          dogSelected={dogSelected}
          dogSpeciesRef={petTypeRef}
          error={petTypeError}
          handleCatSelected={handleCatSelected}
          handleDogSelected={handleDogSelected}
          InputError={InputError}
        />
      </div>

      <div
        className={`Modal-add-pet-input-wrapper
        ${getQuote ? 'Modal-add-pet-input-wrapper-landing' : ''}`}
      >
        <AddPetGenderSelector
          error={petGenderError}
          handleFemaleGenderSelected={handleFemaleGenderSelected}
          handleMaleGenderSelected={handleMaleGenderSelected}
          InputError={InputError}
          maleGenderRef={petGenderRef}
          petGenderSelected={petGenderSelected()}
        />
      </div>

      {getQuote && isGoodDog && (
        <Shimmer
          className={`Modal-add-pet-input-wrapper
        ${getQuote ? 'Modal-add-pet-input-wrapper-landing' : ''}`}
          visible={showShimmer}
          {...shimmerWidth}
        >
          <label
            className="Add-pet-form-label"
            htmlFor="petPickupDate"
            id="petPickuplabel"
          >
            {t('petPickupDate')}

            {renderPickupDateToolTips()}

            <input
              ref={petPickupDateRef}
              aria-describedby="progressQueueId"
              aria-invalid={!isPickupDateValid}
              className={'Modal-add-pet-input-container '
                + `${!isPickupDateValid
                && 'Modal-add-pet-input-container-error'}`}
              id="petPickupDate"
              min={PICKUP_DATE_MIN}
              name="petPickupDate"
              onChange={onPetPickupDateChange}
              type="date"
              value={petPickupDate || null}
            />
          </label>

          <InputError
            error={!isPickupDateValid && t('goodDog.invalidDate')}
          />
        </Shimmer>

      )}
      <div
        className={`Modal-add-pet-input-wrapper
        ${getQuote ? 'Modal-add-pet-input-wrapper-landing' : ''}`}
      >
        <DropdownAddPet
          isLanding={getQuote}
          items={ageData}
          petAgeRef={petAgeRef}
          placeholder="Required"
          requiredError={ageError}
          showShimmer={showShimmer}
          title={t(isGoodDog ? 'petAgeGoodDog' : 'petAge')}
          type="age"
          withInput
        />
      </div>

      <div
        className={`Modal-add-pet-input-wrapper
        ${getQuote ? 'Modal-add-pet-input-wrapper-landing' : ''}`}
      >
        <DropdownAddPet
          isLanding={getQuote}
          items={breedList}
          petBreedRef={petBreedRef}
          placeholder="Start typing to search..."
          requiredError={breedError}
          showShimmer={showShimmer}
          title={t('petBreed')}
          type="breed"
          withInput
        />
      </div>

      <Shimmer
        className={`Modal-add-pet-input-wrapper
      ${getQuote ? 'Modal-add-pet-input-wrapper-landing' : ''}`}
        visible={showShimmer}
        {...shimmerWidth}
      >
        <label
          className="Add-pet-form-label"
          htmlFor="zipCode"
          id="zipCodelabel"
        >
          {t('zipCode')}

          <input
            ref={petZipcodeRef}
            aria-describedby="progressQueueId"
            aria-invalid={zipCodeError}
            aria-required={!!getZipCodeValue()}
            className={'Modal-add-pet-input-container '
              + `${!zipCodeEditable()
              && ' Modal-add-pet-input-container-disabled'} `
              + `${zipCodeError && 'Modal-add-pet-input-container-error'}`}
            disabled={!zipCodeEditable()}
            id="zipCode"
            maxLength={5}
            name="zipCode"
            onBlur={onBlurZipcode}
            onChange={handleZipcodeChange}
            onFocus={onFocusZipcode}
            placeholder="Required"
            type="text"
            value={getZipCodeValue()}
          />

        </label>

        <InputError error={zipCodeError} />
      </Shimmer>

      {getQuote
        && (
          <Shimmer
            className={'Modal-add-pet-input-wrapper'
              + ' Modal-add-pet-input-wrapper-landing'}
            visible={showShimmer}
            {...shimmerWidth}
          >
            <label
              className="Add-pet-form-label"
              htmlFor="user-email"
              id="userEmailLabel"
            >
              {t('email')}

              <input
                ref={petEmailRef}
                aria-describedby="progressQueueId"
                aria-invalid={emailError}
                aria-required={!!email}
                className={'Modal-add-pet-input-container '
                  + `${emailError && 'Modal-add-pet-input-container-error'}`}
                id="user-email"
                name="email"
                onBlur={onBlurEmail}
                onChange={handleEmail}
                onFocus={onFocusEmail}
                onKeyPress={onKeyPress}
                placeholder="Required"
                type="email"
                value={email}
              />
            </label>

            <InputError error={emailError} />
          </Shimmer>
        )}

      {!isPartner && !hasCodeGroupDscr && (
        <Shimmer
          className={`Modal-add-pet-input-wrapper ${getQuote
            ? 'Modal-add-pet-input-wrapper-landing' : ''}`}
          visible={showShimmer}
          {...shimmerWidth}
        >
          <label
            className="Add-pet-form-label"
            htmlFor="promoCode"
            id="promoCodeLabel"
          >
            {t('promoCode')}

            <input
              aria-labelledby="promoCodeLabel"
              className={'Modal-add-pet-input-container '
                + `${promoDesc
                && ' Modal-add-pet-input-container-disabled'}`}
              disabled={promoDesc}
              id="promoCode"
              name="promoCode"
              onBlur={onBlurPromoCode}
              onChange={handlePromoCodeChange}
              type="text"
              value={promoCodeMemo}
            />
          </label>

          <InputError error="" />
        </Shimmer>
      )}

      <Shimmer
        className={'Modal-add-pet-footer-buttons'
          + `${getQuote ? ' Modal-add-pet-footer-buttons-col' : ''}`}
        visible={showShimmer}
      >
        {renderButtons()}
      </Shimmer>
    </form>
  );
};

export { AddPetForm };
