import React from 'react';
import './PartnerDiscountCallout.css';

const PartnerDiscountCallout = ({
  membershipImage,
  messageTitle,
  messageContent,
}) => (
  <div className="Partner-discount-callout-container">
    <div className="Partner-discount-callout-membership">
      <img
        alt=""
        aria-hidden
        role="presentation"
        src={membershipImage}
      />
    </div>

    <div className="Partner-discount-callout-content-container">
      <span className="Partner-discount-callout-title">
        {messageTitle}
      </span>

      <span className="Partner-discount-callout-content">
        {messageContent}
      </span>
    </div>
  </div>
);

export { PartnerDiscountCallout };
