import React from 'react';
import { useDispatch } from 'react-redux';

import { recalculateTotalPrices } from '../../../actions';

import infoIcon from '../../../assets/plans/info.svg';
import arrowsIcon from '../../../assets/plans/select-arrows.svg';
import { Dropdown } from '../../common/Dropdown';
import IconTooltip, { TOOL_TIP_POSITION } from '../../common/IconTooltip';

import './SelectorCombo.css';
import { useCustomPlanPills } from '../../../util';
import { SelectorPills } from './SelectorPills';

const SelectorCombobox = ({
  items = [{ disabled: false, item: {}, value: '' }],
  onChange = () => { },
  position = TOOL_TIP_POSITION.start,
  title,
  tooltipDescription,
  value,
}) => {
  const dispatch = useDispatch();

  const onOptionClick = (item) => {
    if (item.disabled) {
      return;
    }

    onChange(item);
    dispatch(recalculateTotalPrices());
  };

  const titleForId = title.replace(/\s/g, '-');

  return (
    <div className="Selector-compo-container">
      <div>
        <span className="Selector-compo-title" id={`${titleForId}-label`}>
          {title}

          {tooltipDescription && (
            <IconTooltip
              backgroundColor="var(--citron-shade-3)"
              description={tooltipDescription}
              icon={infoIcon}
              name={title}
              position={position}
            />
          )}
        </span>
      </div>

      <Dropdown
        ariaLabelledBy={`${titleForId}-label`}
        containerClassName="Selector-custom-container"
        disableArrowAnimation
        dropdownIcon={arrowsIcon}
        idPostfix={titleForId}
        items={items.map((item) => ({
          ...item,
          label: item.value,
          value: item.id,
        }))}
        listClassName="Selector-custom-container-list"
        onItemSelected={onOptionClick}
        title={title}
        valueSelected={value}
      />
    </div>
  );
};

const SelectorCombo = (props) => (useCustomPlanPills
  ? <SelectorPills {...props} />
  : <SelectorCombobox {...props} />);

export { SelectorCombo };
