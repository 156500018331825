/* eslint-disable sort-keys */
/**
 * @Route
 * URL_ROOT_QUOTE = '/quoting/getquote'
 *
 * @Component
 * src/components/quoting/purchase/PriceStickyBar.js
 *
 * @Description
 * A add_to_cart event represents when the user submit the selection of a plan
 * and power ups for all added pets and continues to next step.
 * This event is fired when the user press the button "Continue: Your info".
 * The information comes from the Session Storage store.quoting key.
 * Be careful: It is a nested object that is difficult to process and prone to
 * errors.
 *
 * @Reference
 * https://developers.google.com/tag-manager/ecommerce-ga4?hl=es
 * #add_a_product_to_a_shopping_cart
 */

import { dataLayerEvent, pickupItemsFromSessionStorage } from './dataLayer';
import { useGoogleTagManager } from '../util';
import { DATALAYER_EVENT_TYPE } from './constants';
import { getCoupon, waitForQuotingData } from './utils';

export const dataLayerAddToCart = async () => {
  if (!useGoogleTagManager) {
    return;
  }

  // clear the previous ecommerce data object
  dataLayerEvent({ ecommerce: null });

  const items = await pickupItemsFromSessionStorage();

  const quoting = await waitForQuotingData();

  items.forEach((item) => {
    dataLayerEvent({
      ecommerce: {
        coupon: getCoupon(quoting),
        currency: 'USD',
        items: [item],
        language: 'en',
        value: item.price,
      },
      event: DATALAYER_EVENT_TYPE.ADD_TO_CART,
      language: 'en',
    });
  });
};
