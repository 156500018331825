import { getAgeInYears } from '../util';
import { PetType, PowerUpsRelation } from '../constants';
import {
  baseRequest,
  customTrackEvent,
  EVENT_TYPE,
  getPlanLimit,
} from './baseRequest';

function getPetPowerups({ currentPet, modifierList }) {
  if (!currentPet.modifiers) {
    return {
      extraCarePack: 'none',
      vetFees: 'none',
      wellness: 'none',
    };
  }

  const currentPetMods = currentPet.modifiers.filter((mod) => mod.isSelected);

  const modList = modifierList.filter((mod) => currentPetMods
    .find((petMod) => petMod.id === mod.Id));
  const extraCarePack = modList.find((mod) => mod.PMSModifierId
    === PowerUpsRelation.EXTRA_CARE_PACK_PSM_ID);

  const vetFees = modList.find((mod) => mod.PMSModifierId
    === PowerUpsRelation.VETERINARY_EXAM_FEES_PSM_ID);

  const wellness = modList.find((mod) => mod.PMSModifierId
    === PowerUpsRelation.WELLNESS_PSM_ID);

  let wellnessTag = 'none';

  if (wellness) {
    wellnessTag = wellness.BundleInsuranceModifiers
      .find((wChild) => currentPetMods.find((pm) => pm.id === wChild.Id))
      ?.TitleText.toLowerCase() === 'basic' ? 'tier-3' : 'tier-5';
  }

  return {
    extraCarePack: extraCarePack ? 'selected' : 'none',
    vetFees: vetFees ? 'selected' : 'none',
    wellness: wellnessTag,
  };
}

function getQuotePlanInfo({ petId, quoteSelectedValues }) {
  const currentQuote = quoteSelectedValues
    .find((quote) => quote.quoteId === petId) || {};

  return {
    annualPremium: currentQuote.totalAnnual,
    deductible: currentQuote.deductibleQuantity,
    monthlyLimit: currentQuote.selectedPlanAmount,
    monthlyPremium: currentQuote.totalMontly,
    reimbursement: currentQuote.reimbursementPercentage,
  };
}

export function trackingLoadQuote({
  codeMask,
  email,
  modifierList,
  petQuoteList,
  promoCode,
  quoteSelectedValues,
  subId,
  zipCode,
}) {
  const pets = petQuoteList.map((pet) => {
    const petQuoteInf = getQuotePlanInfo({
      petId: pet.petQuoteId,
      quoteSelectedValues,
    });

    const petQuotePowerups = getPetPowerups({ currentPet: pet, modifierList });

    return {
      age: getAgeInYears(pet.petAgeName),
      breed: pet.breedName,
      gender: pet.genderName.toLowerCase(),
      name: pet.petName,
      quote: {
        annual_premium: petQuoteInf.annualPremium,
        deductible: petQuoteInf.deductible,
        extra_care: petQuotePowerups.extraCarePack,
        limit: getPlanLimit(petQuoteInf.monthlyLimit),
        monthly_premium: petQuoteInf.monthlyPremium,
        reimbursement: petQuoteInf.reimbursement,
        vet_fees: petQuotePowerups.vetFees,
        wellness: petQuotePowerups.wellness,
      },
      species: pet.petType === PetType.Dog.value ? 'dog' : 'cat',
      zip: zipCode,
    };
  });

  customTrackEvent({
    ...baseRequest({ codeMask, email, promoCode, subId }),
    event: EVENT_TYPE.LOAD_QUOTE,
    pets,
  });
}
