import { store } from '../store';
import { generateUUID } from '../util';
import { NO_PARTNER } from './constants';

// eslint-disable-next-line arrow-body-style
const waitForQuotingData = () => {
  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      const { quoting } = store.getState();
      if (quoting && quoting.data) {
        clearInterval(intervalId);

        const quotingState = {
          ...quoting,
          data: {
            ...quoting.data,
            petQuoteResponseList: quoting.data.petQuoteResponseList
              .map((pet) => ({ ...pet })),
          },
        };

        quotingState.data.petQuoteResponseList.forEach((pet) => {
          if (!pet?.temporal_item_id) {
            // eslint-disable-next-line no-param-reassign
            pet.temporal_item_id = generateUUID();
          }
        });

        resolve(quotingState);
      }
    }, 100);
  });
};

const getCoupon = (quoting) => {
  if (quoting.data.groupCode?.trim().length > 0) {
    return quoting.data.groupCode;
  }

  return NO_PARTNER;
};

const getPetAge = (pet) => {
  if (pet.petAgeName === '8 weeks to 12 months') {
    return 'Under 1 year';
  }

  return pet.petAgeName;
};

const getBillingCycle = (quoting) => {
  if (quoting.isMonthlyPlan) {
    return 'Monthly';
  }
  return 'Annual';
};

export { waitForQuotingData, getCoupon, getPetAge, getBillingCycle };
