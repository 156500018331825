import React, { useRef } from 'react';
import { useHtmlInjection } from '../../hooks/useHtmlInjection';
import './LandingLeftContainer.css';

const PartnerInformationRow = ({
  partnerImage,
  subtitle,
  title,
}) => {
  const subtitleRef = useRef();
  useHtmlInjection({
    classNameContainer: 'Landing-discount-value',
    content: subtitle,
    ref: subtitleRef,
  });

  return (
    <div className="Landing-partner-information-row">
      {partnerImage ? (
        <>
          <img
            alt=""
            className="Landing-discount-tag-image"
            src={partnerImage}
          />

          <div className="Landing-discount-text-container">
            <span className="Landing-discount-text">{title}</span>

            <span ref={subtitleRef} />
          </div>
        </>
      ) : (
        <span className="Landing-coverage-information-text">{subtitle}</span>
      )}
    </div>
  );
};

const LandingLeftContainer = ({
  partnerName,
  partnerRowInformation = {
    detailMessage: { Content: '' },
    membersInfo: [],
  },
  partnerWelcomeText = '',
}) => (
  <>
    <div className="Landing-left-container" role="contentinfo">
      <h1 className="Landing-welcome-title">
        Welcome,
        <br aria-hidden="true" />
        {`${partnerName || '(Your Company)!'}`}
      </h1>

      <p className="Landing-pet-insurance-sub-text">
        {partnerWelcomeText}
      </p>

      <p className="Landing-pet-insurance-detail-text No-mobile">
        {partnerRowInformation?.detailMessage?.Content}
      </p>

      <div className="Landing-partner-row-container">
        {partnerRowInformation?.membersInfo?.map((row) => (
          <PartnerInformationRow
            key={row.Order}
            partnerImage={row.Image}
            subtitle={row.Content}
            title={row.Title}
          />
        ))}
      </div>
    </div>
  </>
);

export { LandingLeftContainer };
