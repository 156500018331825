import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import cloudsIcon from '../../assets/clouds-icon-citron.svg';
import { CUSTOMER_SERVICE_PHONE_NUMBER } from '../../constants';
import { useTranslations } from '../../hooks';
import './ModalError.css';

const ModalError = ({
  buttonText = '',
  message = '',
  onButtonClick = () => { },
}) => {
  const store = useSelector(({ quoting }) => quoting);
  const { partners: { data, isCostco } } = store;
  const buttonRef = useRef(null);
  const { t } = useTranslations('quoting');

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, []);

  const errorMessage = `Sorry, we're unable to provide a quote at this time.
  Please contact Customer Service at ${isCostco
    ? data.SupportPhoneNumber.replace('.', '')
    : CUSTOMER_SERVICE_PHONE_NUMBER} to purchase a policy.`;

  return (
    <div className="Modal-error-container" role="alert">
      <img
        alt=""
        className="Modal-error-clouds-image"
        src={cloudsIcon}
      />

      <h1 className="Modal-error-title" id="genericError">
        {t('genericModal.errorOccured')}
      </h1>

      <h2 className="Modal-error-description">
        {message || errorMessage}
      </h2>

      <button
        ref={buttonRef}
        aria-label={buttonText}
        className="Modal-error-button"
        onClick={onButtonClick}
        type="button"
      >
        <span className="Modal-error-button-text">
          {buttonText}
        </span>
      </button>
    </div>
  );
};

export default ModalError;
