import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import './WhatFigoCovers.css';
import plusIcon from '../../assets/plus-icon.svg';
import lessIcon from '../../assets/less-icon.svg';

const CoveredElements = ({ ariaLabel }) => {
  const [activeIndexes, setActiveIndexes] = useState([]);
  const buttonRefs = useRef([]);

  const store = useSelector((coverage) => coverage.quoting);
  const whatsCovered = store.data?.CoverageInformation?.WhatsCovered || [];

  const toggleAccordion = (index) => {
    setActiveIndexes((prevActiveIndexes) => {
      if (prevActiveIndexes.includes(index)) {
        return prevActiveIndexes.filter((i) => i !== index);
      }
      return [...prevActiveIndexes, index];
    });
  };

  const handleKeyPress = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      buttonRefs.current[index].focus();
    }
  };

  const setButtonRef = (element, index) => {
    buttonRefs.current[index] = element;
  };

  return (
    <div aria-label={ariaLabel} className="what-figo-covers-box">
      <ul>
        {whatsCovered.map((coverage, index) => (
          <li key={String(coverage.Id)} className="what-figo-covers-item">
            <button
              ref={(element) => setButtonRef(element, index)}
              aria-expanded={activeIndexes.includes(coverage.Id)}
              className={`${activeIndexes.includes(coverage.Id)
                ? 'Like-span what-figo-covers-item-order-opened'
                : 'Like-span what-figo-covers-item-order'}`}
              onClick={() => toggleAccordion(coverage.Id)}
              type="button"
            >
              <span
                aria-label={coverage.Title}
                className="what-figo-covers-boxitem"
              >
                <img
                  alt=""
                  className="what-figo-covers-icon"
                  src={coverage.Icon}
                />

                <p className="what-figo-covers-item-title">{coverage.Title}</p>
              </span>

              <img
                alt=""
                className="what-figo-covers-plus-less-icon"
                src={activeIndexes.includes(coverage.Id) ? lessIcon : plusIcon}
              />
            </button>

            {activeIndexes.includes(coverage.Id) && (
            <div className="what-figo-covers-accordion-content">
              <div
                className="what-figo-covers-text-button"
                onKeyPress={(event) => handleKeyPress(event, index)}
                role="button"
                tabIndex="0"
              >
                <p className="what-figo-covers-text">{coverage.Text}</p>
              </div>
            </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CoveredElements;
