import {
  PowerUpsRelation,
  POWERUP_TYPE,
  BENEFIT_FEE_TYPE,
} from '../../../constants';
import { isPaycheck, isProductFPI } from '../../../util';

const { WELLNESS_PSM_ID } = PowerUpsRelation;

export function toTruncate(value) {
  return Math.trunc((value * 100)) / 100;
}

export function shouldDisplayInfoImage(powerUp) {
  return powerUp.PMSModifierId === WELLNESS_PSM_ID;
}

// FIXME: i think that we can use ProductFamily === "FPI" instead isProductFPI
export function isWellnessFPI({ insuranceProductId, powerUp }) {
  return powerUp?.PMSModifierId === WELLNESS_PSM_ID
    // FPA-12007: Wellness plus to be offered as single option of some states
    && powerUp?.BundleInsuranceModifiers?.length >= 2
    && isProductFPI(insuranceProductId);
}

// FIXME: i think that we can use ProductFamily === "FPI" instead isProductFPI
export function isWellnessFPIWithoutChild({ insuranceProductId, powerUp }) {
  return powerUp?.PMSModifierId === WELLNESS_PSM_ID
    // FPA-12007: Wellness plus to be offered as single option of some states
    && powerUp?.BundleInsuranceModifiers?.length === 1
    && isProductFPI(insuranceProductId);
}

export function findSelectedModifier({ id, modifiers = [] }) {
  const contentModifierSelected = modifiers
    ?.find((element) => element.id === id);

  const isChecked = contentModifierSelected
    ? contentModifierSelected.isSelected : null;

  return isChecked;
}

function isModifierSelected({ id, selectedModifiers }) {
  const modifiersSelected =
    selectedModifiers.filter((modifier) => modifier.id === id
      && modifier.isSelected);

  return !!modifiersSelected.length;
}

export function getFeeAmountChildren({
  benefitType,
  modifiers,
  selectedModifiers,
}) {
  return modifiers
    .filter((item) => item.InsuranceModifierTypeId === POWERUP_TYPE
      && item.BenefitFeeType === benefitType)
    .map((element) => {
      if (element.BundleInsuranceModifiers) {
        return element.BundleInsuranceModifiers
          .filter((filter) => filter.IsSelected
            || isModifierSelected({ id: filter.Id, selectedModifiers }))
          .reduce((prev, next) => prev + next.FeeAmount, 0);
      }

      return 0;
    }).reduce((prev, next) => prev + next, 0);
}

function getCoverageFee({ data, petQuoteSelected }) {
  const { petQuoteResponseList } = data;

  const quote = petQuoteResponseList
    .find((item) => item.petQuoteId === petQuoteSelected)
    || { modifier: [] };
  const insuranceModifiers = quote.InsuranceModifiers;

  const modifiers = quote.modifiers
    ? quote.modifiers.filter((modifier) => modifier.isSelected)
    : [];
  const modifierIds = modifiers.map((modifier) => modifier.id);

  const powerUpsHidden = insuranceModifiers
    .filter((modifier) => modifier.InsuranceModifierTypeId === POWERUP_TYPE
      && modifier.IsSelected && !modifier.IsVisible);
  const powerUpsSelected = insuranceModifiers
    .filter((modifier) => modifierIds.includes(modifier.Id));
  const powerUps = [...powerUpsHidden, ...powerUpsSelected];

  const feeFactor = powerUps
    .filter((item) => item.BenefitFeeType === BENEFIT_FEE_TYPE.ftpFactor)
    .reduce((a, b) => a + b.FeeAmount, 0);
  const feeFixed = powerUps
    .filter((item) => item.BenefitFeeType === BENEFIT_FEE_TYPE.fixedFee
      || item.BenefitFeeType === BENEFIT_FEE_TYPE.dynamic)
    .reduce((a, b) => a + b.FeeAmount, 0);

  const feeFactorChildren = getFeeAmountChildren({
    benefitType: BENEFIT_FEE_TYPE.ftpFactor,
    modifiers: insuranceModifiers,
    selectedModifiers: quote.modifiers || [],
  });

  const feeFixedChildren = getFeeAmountChildren({
    benefitType: BENEFIT_FEE_TYPE.fixedFee,
    modifiers: insuranceModifiers,
    selectedModifiers: quote.modifiers || [],
  });

  return {
    feeFactor: feeFactor + feeFactorChildren,
    feeFixed: feeFixed + feeFixedChildren,
  };
}

function getPriceWithoutFees({ data, petQuoteSelected, quoteSelected }) {
  const { annualAmountPlan, monthlyAmountPlan } = quoteSelected;
  const coverageFeeValue = getCoverageFee({ data, petQuoteSelected });
  const pricePaycheck = (annualAmountPlan * 0.9) / 24;
  const price = isPaycheck ? pricePaycheck : monthlyAmountPlan;
  const priceWithoutFees = (price - coverageFeeValue.feeFixed)
    / (1 + coverageFeeValue.feeFactor);

  return priceWithoutFees;
}

function getCoverageByBenefitFeeType({
  data,
  petQuoteSelected,
  powerUp,
  quoteSelected,
}) {
  const { BenefitFeeType, FeeAmount } = powerUp;
  const priceWithoutFees = getPriceWithoutFees({
    data,
    petQuoteSelected,
    quoteSelected,
  });

  if (BenefitFeeType === BENEFIT_FEE_TYPE.ftpFactor) {
    const coverageFee = FeeAmount * priceWithoutFees;

    return coverageFee;
  }

  return FeeAmount;
}

export function calculateCoverageFee({
  data,
  petQuote,
  petQuoteSelected,
  powerUp,
  quoteSelected,
}) {
  if (isWellnessFPI({
    insuranceProductId: data.insuranceProduct.Id,
    powerUp,
  })) {
    const wellnessArrayValues =
      powerUp.BundleInsuranceModifiers
        .map((item) => {
          const contentModifierSelected = (petQuote.modifiers || [])
            .find((element) => element.id === item.Id);
          const isSelected = contentModifierSelected
            ? contentModifierSelected.isSelected : false;

          return isSelected && item.FeeAmount
            ? item.FeeAmount : 0;
        });

    const wellnessPrice = wellnessArrayValues
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      || powerUp.BundleInsuranceModifiers[0].FeeAmount;

    return toTruncate(wellnessPrice).toFixed(2);
  }

  if (isWellnessFPIWithoutChild({
    insuranceProductId: data.insuranceProduct.Id,
    powerUp,
  })) {
    const firthChild = powerUp.BundleInsuranceModifiers[0]?.FeeAmount || 0;

    return toTruncate(firthChild).toFixed(2);
  }

  const coverageFee = getCoverageByBenefitFeeType({
    data,
    petQuoteSelected,
    powerUp,
    quoteSelected,
  });
  return toTruncate(coverageFee).toFixed(2);
}
