import { useSelector } from 'react-redux';
import { useTracking } from 'react-tracking';
import {
  baseRequest,
  customTrackEvent,
  EVENT_TYPE,
  getCurrentPrice,
  getPetPowerups,
  getPlanLimit,
  getQuotePlanInfo,
} from './baseRequest';
import { PetType } from '../constants';
import { getAgeInYears } from '../util';

const useTrackingReimbursementSelection = () => {
  const { trackEvent } = useTracking({ }, { dispatch: customTrackEvent });
  const store = useSelector(({ quoting }) => quoting);
  const {
    data,
    parameters: { groupCodeDscr, email },
    petQuoteSelected,
    quoteSelectedValues,
    subId,
  } = store;

  function getDeductibleUpdated({
    currentDeductibleId,
    currentPet,
    planId,
    reimbursementId,
  }) {
    const ONE_HUNDRED_PERCENT_ID = 1;
    const response = { description: '', id: 0 };

    if (reimbursementId === ONE_HUNDRED_PERCENT_ID) {
      const currentCombination = currentPet.Plans[planId].RatingOptions
        .filter((rating) => rating.DeductibleId === currentDeductibleId
      && rating.ReimbursementId === reimbursementId);

      if (currentCombination.length && currentCombination[0].Invalid) {
        const ratingOptions = currentPet.Plans[planId].RatingOptions
          .filter((rating) => rating.ReimbursementId === reimbursementId);

        const deductiblesFilter = ratingOptions
          .filter((item) => item.ReimbursementId === reimbursementId)
          .map((item) => ({
            available: !item.Invalid,
            description: item.DeductibleName,
            id: item.DeductibleId,
          }));

        const deductibleAvailable = deductiblesFilter
          .reverse().find((item) => item.available);

        response.id = deductibleAvailable.id;
        response.description = deductibleAvailable.description;
      }
    }

    return response;
  }

  function trackReimbursementSelection({
    reimbursement,
    reimbursementId,
  }) {
    const currentPet = data.petQuoteResponseList
      .find((element) => element.petQuoteId === petQuoteSelected);

    const petQuoteInf = getQuotePlanInfo({
      petId: currentPet.petQuoteId,
      quoteSelectedValues,
    });

    const modifierList = data.insuranceProduct.InsuranceModifiers;
    const petQuotePowerups = getPetPowerups({ currentPet, modifierList });

    const { selectedPlanIndex } =
      quoteSelectedValues.find((pt) => pt.quoteId === petQuoteSelected);

    const price = getCurrentPrice({
      currentPet,
      petIdSelected: petQuoteSelected,
      planIndex: selectedPlanIndex,
      quoteSelectedValues,
      reimbursementIdParam: reimbursementId,
    });

    const newDeductible = getDeductibleUpdated({
      currentDeductibleId: petQuoteInf.deductibleId,
      currentPet,
      planId: petQuoteInf.selectedPlanIndex,
      reimbursementId,
    });

    const petInfo = {
      age: getAgeInYears(currentPet.petAgeName),
      breed: currentPet.breedName,
      gender: currentPet.genderName.toLowerCase(),
      name: currentPet.petName,
      quote: {
        annual_premium: price.AnnualPremium,
        deductible: newDeductible.description || petQuoteInf.deductible,
        extra_care: petQuotePowerups.extraCarePack,
        limit: getPlanLimit(petQuoteInf.monthlyLimit),
        monthly_premium: price.MonthlyPremium,
        reimbursement,
        vet_fees: petQuotePowerups.vetFees,
        wellness: petQuotePowerups.wellness,
      },
      species: currentPet.petType === PetType.Dog.value ? 'dog' : 'cat',
      zip: data.zipCode,
    };

    trackEvent({
      ...baseRequest({
        codeMask: groupCodeDscr,
        email,
        promoCode: data.groupCode || '',
        subId: subId.Value,
      }),
      event: EVENT_TYPE.REIMBURSEMENT_SELECTION,
      pet: petInfo,
    });
  }

  return { trackReimbursementSelection };
};

export { useTrackingReimbursementSelection };
