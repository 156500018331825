import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTemporaryMessage } from '../../actions';
import closeIcon from '../../assets/close-arrow.svg';
import './TemporaryMessage.css';

const TemporaryMessage = ({ sticky = false, positionBottom = 0 }) => {
  const [isVisible, setIsVisible] = useState(true);
  const { t } = useTranslation('quoting');
  const dispatch = useDispatch();
  const store = useSelector(({ quoting }) => quoting);
  const {
    temporaryMessageData,
    partners: { isCostco },
  } = store;

  useEffect(() => {
    dispatch(fetchTemporaryMessage(isCostco));
  }, [dispatch, isCostco]);

  const handleCloseClick = () => {
    setIsVisible(false);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  };

  if (!isVisible || !temporaryMessageData?.Message) {
    return null;
  }

  return (
    <div
      className={'Temporary-message-bar'
        + ` ${sticky ? 'Temporary-message-sticky' : ''}`}
      style={{ bottom: positionBottom }}
    >
      <button onClick={handleCloseClick} type="button">
        <img
          alt={t('closeTemporaryMessage')}
          className="Temporary-message-bar-close-icon"
          src={closeIcon}
        />
      </button>

      <div
        dangerouslySetInnerHTML={{ __html: temporaryMessageData?.Message }}
      />
    </div>
  );
};

export { TemporaryMessage };
