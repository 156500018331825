// @ts-nocheck
/**
 * @Route
 * URL_ROOT_QUOTE = '/quoting/getquote'
 *
 * @Component
 * src/components/quoting/header/QuotingHeader.js
 *
 * @Description
 * A view_item event represents when the user completes the form on step
 * one and reveals a quote for one pet. If the user adds another pet, a
 * new view_item event is fired to identify a new quote revealed, Each
 * time the user toggles between pets a new view_item event is fired.
 * Equals the Load Quote event previously configured.
 *
 * @Reference
 * https://developers.google.com/tag-manager/enhanced-ecommerce#view-item
 */
import { useGoogleTagManager } from '../util';
import {
  dataLayerEvent,
  pickupPartnerCodeFromSessionStorage,
} from './dataLayer';
import { DATALAYER_EVENT_TYPE, FULL_POLICY, PATH_ALLOWED } from './constants';
import { getCoupon, getPetAge, waitForQuotingData } from './utils';
import { PetType } from '../constants';

export const dataLayerViewItem = async ({ pathname = '' }) => {
  const pathLowerCase = pathname?.toLocaleLowerCase() || '';

  if (!useGoogleTagManager || !PATH_ALLOWED.VIEW_ITEM.includes(pathLowerCase)) {
    return;
  }

  const quoting = await waitForQuotingData();

  const { partnerCode } = pickupPartnerCodeFromSessionStorage();
  if (!partnerCode) {
    throw new Error();
  }

  const items = quoting.data.petQuoteResponseList.map((pet) => ({
    affiliation: quoting.partners.isCostco ? 'Costco' : 'No Partner',
    item_brand: 'Figo',
    item_category2:
      pet.petType === PetType.Dog.value ? PetType.Dog.name : PetType.Cat.name,
    item_category3: pet.breedName,
    item_category4: getPetAge(pet),
    item_id: pet.temporal_item_id,
    item_name: FULL_POLICY,
    price: pet.monthlyPremium,
    quantity: 1,
  }));

  const currentPetSelected = items[quoting.petQuoteSelected - 1];

  const ecommerce = {
    coupon: getCoupon(quoting),
    currency: 'USD',
    items: [currentPetSelected],
    language: 'en',
    value: currentPetSelected.price,
  };

  dataLayerEvent({
    ecommerce,
    email_address: quoting.parameters.email,
    event: DATALAYER_EVENT_TYPE.VIEW_ITEM,
    language: 'en',
  });
};
