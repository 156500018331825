import React, { useRef, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { FOOTER_TEXT_BASE } from '../../constants';
import {
  useHtmlInjection,
  useSessionFooter,
  useTranslations,
  usePartnerNotes,
} from '../../hooks';
import { usePartnersData } from '../../partners/usePartnersData';

import './LandingFooter.css';
import { FooterLinks } from '../common/FooterLinks';

const LandingFooter = ({ refer = '' }) => {
  const store = useSelector(({ quoting }) => ({ quoting }), shallowEqual);
  const { t } = useTranslations('landing');
  const notesRef = useRef();
  const footerRef = useRef();
  const sessionFooter = useSessionFooter();
  const disclaimers = usePartnerNotes();
  const { isGoodDog, isCostco } = usePartnersData();

  const disclaimerReduced = useMemo(() => {
    if (isCostco && disclaimers.length > 1) {
      return disclaimers[1].Content;
    }
    return disclaimers
      .reduce((accumulator, current) => `${accumulator}${current.Content}`, '');
  }, [disclaimers, isCostco]);

  useHtmlInjection({
    classNameContainer: 'Landing-footer-app-logo-text',
    content: disclaimerReduced,
    ref: notesRef,
  });

  const footerClassName = isGoodDog
    ? 'Landing-footer-good-dog' : 'Landing-footer';

  const footerContent = useMemo(() => {
    // partner
    if (refer) {
      // then waits for the custom session partner response
      if (store.quoting.partners.loaded) {
        return sessionFooter || FOOTER_TEXT_BASE;
      }

      return '';
    }

    // no partner, so default footer content
    return FOOTER_TEXT_BASE;
  }, [sessionFooter, refer, store.quoting.partners]);

  useHtmlInjection({
    classNameContainer: 'Landing-footer-app-logo-text',
    content: footerContent,
    elementType: 'p',
    ref: footerRef,
  });

  return (
    <footer className="Landing-footer-container">
      {isGoodDog && (
        <h4>{t('goodDog.footer', { date: new Date().getFullYear() })}</h4>
      )}

      <FooterLinks />

      <div className="Landing-footer-app-logo-container">
        <div ref={notesRef} />

        <div ref={footerRef} className={footerClassName} />
      </div>
    </footer>
  );
};

export { LandingFooter };
