import { useEffect } from 'react';
import { getSessionStorage, useTrackingEvents } from '../../util';
import { trackingLoadQuote } from '../../trackingEvents';

const TrackingLoadQuoteOnRefresh = () => {
  useEffect(() => {
    const storageData = getSessionStorage('store.quoting');

    if (useTrackingEvents && storageData && storageData.data) {
      const { data } = storageData;

      trackingLoadQuote({
        codeMask: storageData.parameters.groupCodeDscr,
        email: storageData.parameters.email,
        guidData: storageData.guidData,
        modifierList: data.insuranceProduct.InsuranceModifiers,
        petQuoteList: data.petQuoteResponseList,
        promoCode: data.groupCode || '',
        quoteSelectedValues: storageData.quoteSelectedValues,
        subId: storageData.subId.Value,
        zipCode: data.zipCode,
      });
    }
  }, []);

  return null;
};

export { TrackingLoadQuoteOnRefresh };
