import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import { PetParentInfo } from './PetParentInfo';
import PlanSummary from './PaymentSummary';
import { PaymentMethod } from './PaymentMethod';
import { YourInfoFooter } from './YourInfoFooter';
import TermsAndConditions from './TermsAndConditions';
import PaymentBreakdown from './PaymentBreakdown';
import DiscountApplied from './DiscountApplied';
import { YourFirstPayment } from './YourFirstPayment';
import {
  CostcoMembershipValidator,
} from './PartnersCustomFields/CostcoMembershipValidator';
import {
  ImportantDatesNewDesign,
} from './important-dates/ImportantDatesNewDesign';

import {
  PAYMENT_TYPE,
  YOUR_INFO_STEPS,
  TEN_DIGIT_NUMBER,
  STICKY_BAR_HEIGHT_SECOND_STEP,
  DEFAULT_STICKY_BAR_HEIGHT,
} from '../../../constants';
import {
  createDiamondClient,
  sendFSCCustomerLead,
  sendFSCQuoteLead,
  validateDiamondUser,
  yourInfoStepChanged,
} from '../../../actions';

import {
  loadAmericanStates,
  validateCustomerExistsByEmail,
} from '../../../actions/quoting';

import { costcoMembershipSetErrorMessage } from '../../../actions/partners';

import {
  useB2CInfo,
  useOneIncScript,
  useRateSummary,
  useTranslations,
} from '../../../hooks';
import {
  getElementOffsetTop,
  isRoutingNumberValid,
  isValidCardCVV,
  isValidCardExpirationDate,
  isValidCardNumber,
  isValidDiamondStreet,
  useOneInc,
  forceFocusElement,
  useFSCLeads,
  useROPC,
} from '../../../util';
import { usePartnersData } from '../../../partners/usePartnersData';

import { YourInfoFloatingImages } from './YourInfoFloatingImages';
import { Prop103DisclaimerText } from './Prop103DisclaimerText';
import { pickupDateMin } from '../../../partners/partners.util';

import './YourInfoContent.css';
import { TemporaryMessage } from '../TemporaryMessage';
import { PartnerDiscountLabel } from '../PartnerDiscountLabel';
import { GenericPartnerDiscountLabel } from '../GenericPartnerDiscountLabel';
import { QuestionsSection } from '../QuestionsSection';

const PICKUP_DATE_MIN = pickupDateMin();

const YourInfoContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const { getSelectedRate } = useRateSummary();
  const { t } = useTranslations('quoting');
  const {
    americanStates,
    newCustomer,
    paymentMethod,
    sessionInformation,
    termsAndConditions,
    yourInfoStep,
    oneInc: { tokenId },
    partners: { costcoMembership },
    searchParameters,
  } = store;
  const { isCustomer, nopCommerceUser } = sessionInformation;
  const [
    validateTermsAndConditions,
    setValidateTermsAndConditions,
  ] = useState(0);
  const [validatePetParentInfo, setValidatePetParentInfo] = useState(0);
  const [validatePetCloudLogin, setValidatePetCloudLogin] = useState(0);
  const isSecondStep = yourInfoStep === YOUR_INFO_STEPS.secondStep;
  const [yourVeterinarianRef, setYourVeterinarianRef] = useState(null);
  const isAuthenticated = useIsAuthenticated();

  const {
    data,
    isPartner,
    isCostco,
    isGoodDog,
    goodDogData: {
      isPickupDateValid,
      isPickupDateValid7Days,
      onPetPickupDateChange,
      petPickupDate,
      updateRateByPickupDate,
    },
  } = usePartnersData();

  const { loginB2C } = useB2CInfo();
  const location = useLocation();

  const renderCostcoMembershipValidator = isCostco
    && store.partners.data.MembershipValidation;

  useOneIncScript();

  useEffect(() => {
    if (isSecondStep) {
      forceFocusElement('newCustomerPasswordConfirm');
    }
  }, [isSecondStep]);

  useEffect(() => {
    async function validateCustomerB2C() {
      if (newCustomer.email
        && !sessionInformation.nopCommerceUser.LoginSuccess
        && !isAuthenticated) {
        const payload = await validateCustomerExistsByEmail(newCustomer.email);
        const { IsValid, Data } = payload;
        const userAlreadyExists = IsValid && !!Data;

        dispatch(validateDiamondUser({
          customerExistsData: Data,
          email: newCustomer.email,
          userAlreadyExists,
          validateCustomer: false,
        }));

        if (userAlreadyExists && !useROPC) {
          const currentUrl = `${location.pathname}${location.search}`;
          loginB2C({
            existingEmail: newCustomer.email,
            urlState: currentUrl,
          });
        }
      }
    }

    validateCustomerB2C();
    getSelectedRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { bankAccount, creditDebit } = paymentMethod;

  const [scrollTermCondition, setScrollTermCondition] = useState(false);
  const termConditionsRef = useRef();

  const [scrollPaymentMethod, setScrollPaymentMethod] = useState(false);
  const paymentMethodRef = useRef();
  const [showPaymentMethodError, setShowPaymentMethodError] = useState(false);

  const [scrollPetParentInfo, setScrollPetParentInfo] = useState(false);
  const petParentInfoRef = useRef();
  const [redirectToCustomize, setRedirectToCustomize] = useState(false);

  const [
    scrollPetParentInfoSecond,
    setScrollPetParentInfoSecond,
  ] = useState(false);
  const petParentInfoSecondRef = useRef();

  const useProp103 = useMemo(() => {
    if (store.isLoading || store.petRemovedLoading) {
      return false;
    }

    if (!store.data) {
      return false;
    }

    return store.data.SiteMessages
      .find((item) => item.Name === 'PROP103') || false;
  }, [store.data, store.isLoading, store.petRemovedLoading]);

  const bankValidations = useOneInc
    || (bankAccount.accountNumber.trim().length
      && isRoutingNumberValid(paymentMethod.bankAccount.routingNumber.trim())
      && paymentMethod.bankAccount.accountNumberConfirmation.trim().length
      && (paymentMethod.bankAccount.accountNumber
        === paymentMethod.bankAccount.accountNumberConfirmation));
  const creditCardValidations = useOneInc
    || (isValidCardCVV(creditDebit.cardCvv.trim())
      && isValidCardNumber(creditDebit.cardNumber.trim().replace(/-/g, ''))
      && isValidCardExpirationDate(creditDebit.expirationDate
        .trim()
        .replace(/\//g, ''))
      && creditDebit.nameOnCard.trim().length);
  const isPaymentMethodValid = useOneInc
    || (paymentMethod.paymentMethodType.value === PAYMENT_TYPE.bank.value
      ? bankValidations : creditCardValidations);

  const pickupDateValid = isGoodDog
    ? (isPickupDateValid && isPickupDateValid7Days)
    : true;

  const isTermsAndConditionsValid =
    termsAndConditions.agreeTermsAndConditionCheck
    && termsAndConditions.understandConditionCheck;
  const isPetParentInfoValid = newCustomer.name.trim().length
    && newCustomer.lastName.trim().length
    && newCustomer.phoneNumber.trim().length
    && TEN_DIGIT_NUMBER.test(newCustomer.phoneNumber.trim())
    && (!isCostco || (isCostco && costcoMembership.isValid))
    && newCustomer.address.city.trim().length
    && newCustomer.address.street.trim().length
    && isValidDiamondStreet(newCustomer.address.street.trim())
    && pickupDateValid;
  const isSecondParentInfoValid =
    !store.secondParentFormVisible
    || (store.secondParentFormVisible
      && newCustomer.secondParent.name.trim().length
      && newCustomer.secondParent.lastName.trim().length
      && newCustomer.secondParent.phoneNumber.trim().length
      && TEN_DIGIT_NUMBER.test(newCustomer.secondParent.phoneNumber.trim()));
  const isNewCustomerValid = newCustomer.email.trim().length
    && !newCustomer.emailError.trim().length;

  const isSecondBillingAddressValid = useOneInc
    || paymentMethod.mailAddressSameAsPet
    || (!paymentMethod.mailAddressSameAsPet && (
      !paymentMethod.billingAddress.cityError.length
      && !paymentMethod.billingAddress.stateError.length
      && !paymentMethod.billingAddress.streetError.length
      && !paymentMethod.billingAddress.zipError.length
      && paymentMethod.billingAddress.city.trim().length
      && paymentMethod.billingAddress.state.trim().length
      && paymentMethod.billingAddress.street.trim().length
      && paymentMethod.billingAddress.zip.trim().length
    ));

  const petCloudLoginSuccess = isNewCustomerValid || isCustomer;

  const isPurchaseValid = isTermsAndConditionsValid
    && isPaymentMethodValid
    && isSecondBillingAddressValid
    && isPetParentInfoValid
    && isSecondParentInfoValid
    && petCloudLoginSuccess
    && (!isCostco || (isCostco && costcoMembership.message))
    && tokenId;

  useEffect(() => {
    if (americanStates.length <= 0) {
      dispatch(loadAmericanStates());
    }
  }, [americanStates, dispatch]);

  useEffect(() => {
    if (isSecondStep && yourVeterinarianRef) {
      window.scrollTo({
        behavior: 'smooth',
        top: yourVeterinarianRef.offsetTop - 100,
      });
    } else if (!yourVeterinarianRef) {
      const element = document.getElementById('yourVeterinarianRef');
      setYourVeterinarianRef(element);
    }
  }, [yourVeterinarianRef, setYourVeterinarianRef, isSecondStep]);

  useEffect(() => {
    if (scrollTermCondition) {
      window.scrollTo({
        behavior: 'smooth',
        top: termConditionsRef.current.offsetTop,
      });
      setScrollTermCondition(false);
    }
  }, [scrollTermCondition]);

  useEffect(() => {
    if (scrollPaymentMethod) {
      window.scrollTo({
        behavior: 'smooth',
        top: paymentMethodRef.current.offsetTop,
      });
      setScrollPaymentMethod(false);
    }
  }, [scrollPaymentMethod]);

  useEffect(() => {
    if (scrollPetParentInfo) {
      window.scrollTo({
        behavior: 'smooth',
        top: petParentInfoRef.current.offsetTop,
      });
      setScrollPetParentInfo(false);
    }
  }, [scrollPetParentInfo]);

  useEffect(() => {
    if (scrollPetParentInfoSecond) {
      window.scrollTo({
        behavior: 'smooth',
        top: petParentInfoSecondRef.current.offsetTop,
      });
      setScrollPetParentInfoSecond(false);
    }
  }, [scrollPetParentInfoSecond]);

  useEffect(() => {
    if (redirectToCustomize) {
      const url = `/quoting/getQuote${searchParameters}`;
      history.push(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectToCustomize]);

  const goToCustomize = useCallback(() => {
    setRedirectToCustomize(true);
  }, []);

  const sendCustomerLead = useCallback(() => {
    if (useFSCLeads) {
      dispatch(sendFSCCustomerLead({
        address: {
          city: newCustomer.address.city,
          line1: newCustomer.address.street,
          line2: newCustomer.address.street2,
          state: newCustomer.address.state,
          zip: newCustomer.address.zipCode,
        },
        email: newCustomer.email,
        firstName: newCustomer.name,
        lastName: newCustomer.lastName,
        phoneNumber: newCustomer.phoneNumber,
        secondaryPetParent: {
          firstName: newCustomer.secondParent.name,
          lastName: newCustomer.secondParent.lastName,
          phoneNumber: newCustomer.secondParent.phoneNumber,
        },
      }));
    }
  }, [dispatch, newCustomer]);

  function createCustomer() {
    if (isCustomer && nopCommerceUser.DiamonClientdId) {
      return;
    }

    const {
      newCustomer: {
        address,
        email,
        name,
        lastName,
        phoneNumber,
      },
    } = store;

    dispatch(createDiamondClient({
      address: address.street,
      city: address.city,
      clientId: nopCommerceUser.DiamonClientdId,
      email,
      firstName: name,
      lastName,
      phoneNumber,
      stateAbbreviation: address.stateAbbr,
      stateName: address.state,
      zipCode: address.zipCode,
    }));
  }

  useEffect(() => {
    if (tokenId) {
      setShowPaymentMethodError(false);
    }
  }, [tokenId]);

  const handleContinuePurchaseClick = () => {
    if (isSecondStep) {
      setValidateTermsAndConditions(validateTermsAndConditions + 1);
    }
    setValidatePetParentInfo(validatePetParentInfo + 1);
    setValidatePetCloudLogin(validatePetCloudLogin + 1);

    if (!isPetParentInfoValid) {
      setScrollPetParentInfo(true);
    } else if (!isSecondParentInfoValid) {
      setScrollPetParentInfoSecond(true);
    } else if (!tokenId && isSecondStep) {
      setScrollPaymentMethod(true);
      setShowPaymentMethodError(true);
    } else if (!isTermsAndConditionsValid && isSecondStep) {
      setScrollTermCondition(true);
    }

    if (isPetParentInfoValid && isSecondParentInfoValid
      && petCloudLoginSuccess) {
      createCustomer();

      if (!isSecondStep) {
        sendCustomerLead();
        if (useFSCLeads && isCostco) {
          dispatch(sendFSCQuoteLead());
        }
      }
      dispatch(yourInfoStepChanged(YOUR_INFO_STEPS.secondStep));
    }

    if (!isPurchaseValid) {
      let offsetTop;

      if (isCostco
        && !costcoMembership.message) {
        offsetTop = getElementOffsetTop('costcoMembershipValidator', 300);
        const errorMessage = 'Please validate member id to proceed';
        dispatch(costcoMembershipSetErrorMessage(errorMessage));
      } else if (!petCloudLoginSuccess) {
        offsetTop = getElementOffsetTop('newCustomerLogin', 300);
      } else if (!isPaymentMethodValid) {
        offsetTop = getElementOffsetTop('paymentMethod', 300);
      } else if (!isSecondBillingAddressValid) {
        offsetTop = getElementOffsetTop('billingAddressSwitch', 300);
      }

      if (offsetTop) {
        window.scrollTo({ behavior: 'smooth', top: offsetTop });
      }
    }
  };

  const handleOnPetPickupDate = async ({ target }) => {
    await onPetPickupDateChange({ target });
  };

  const onBurPetPickupDate = () => {
    if (pickupDateValid) {
      updateRateByPickupDate();
    }
  };

  const renderPartnerHeader = () => {
    if (isCostco) {
      return <PartnerDiscountLabel discountLabel={data?.DiscountLabel} />;
    }

    if (data?.Logo || data?.DiscountLabel) {
      return (
        <GenericPartnerDiscountLabel
          label={data?.DiscountLabel}
          logo={data?.Logo}
        />
      );
    }

    return null;
  };

  return (
    <div className="Your-info-content">
      <div
        className="Your-info-main-container"
        id="yourinfocontainer"
      >
        {isPartner ? renderPartnerHeader() : null}

        <div className="Your-info-step-container">
          <PetParentInfo
            containerRef={petParentInfoRef}
            containerSecondaryRef={petParentInfoSecondRef}
            sendCustomerLead={sendCustomerLead}
            validatePetParentInfo={validatePetParentInfo}
          />

          {renderCostcoMembershipValidator && (
            <CostcoMembershipValidator goToCustomize={goToCustomize} />
          )}

          {isGoodDog && (
            <div className="Your-info-pickup-content">
              <h3>{t('goodDog.title')}</h3>

              <p>{t('goodDog.subtitle')}</p>

              <p>{t('goodDog.subtitle2')}</p>

              <div>
                <label
                  className="Your-info-pickup-date-labe"
                  htmlFor="petPickupDate"
                  id="petPickuplabel"
                >
                  {t('petPickupDate')}

                  <input
                    aria-describedby="progressQueueId"
                    className={'Your-info-pickup-date-input '
                      + `${!pickupDateValid
                        ? 'Your-info-pickup-date-input-error' : ''}`}
                    id="petPickupDate"
                    min={PICKUP_DATE_MIN}
                    name="petPickupDate"
                    onBlur={onBurPetPickupDate}
                    onChange={handleOnPetPickupDate}
                    placeholder="Optional"
                    type="date"
                    value={petPickupDate}
                  />
                </label>

                <span
                  aria-live="assertive"
                  className="Modal-add-pet-input-error-label"
                >
                  {!isPickupDateValid7Days && t('goodDog.pickupDateError')}
                  {!isPickupDateValid && t('goodDog.invalidDate')}
                </span>

                <p>
                  {t('goodDog.questions')}
                </p>
              </div>
            </div>
          )}
        </div>

        <YourInfoFloatingImages
          isLoggedIn={nopCommerceUser.LoginSuccess}
          isSecondStep={isSecondStep}
        />

        {isSecondStep && (

          <div className="Your-info-step-container">
            <PlanSummary />

            <DiscountApplied isSecondStep={isSecondStep} />

            <PaymentBreakdown />

            {useProp103 && <Prop103DisclaimerText />}

            <YourFirstPayment />

            <PaymentMethod
              containerRef={paymentMethodRef}
              showError={showPaymentMethodError}
            />

            <ImportantDatesNewDesign />

            <TermsAndConditions
              containerRef={termConditionsRef}
              triggerValidation={validateTermsAndConditions}
            />
          </div>
        )}

        <div className="Your-info-step-container">
          <YourInfoFooter
            handleContinuePurchaseClick={handleContinuePurchaseClick}
            isPurchaseValid={isPurchaseValid}
            isSecondStep={isSecondStep}
            petCloudLoginSuccess={petCloudLoginSuccess}
          />
        </div>
      </div>

      <TemporaryMessage
        positionBottom={isSecondStep
          ? STICKY_BAR_HEIGHT_SECOND_STEP
          : DEFAULT_STICKY_BAR_HEIGHT}
        sticky
      />

      <QuestionsSection isQuote />
    </div>
  );
};

export { YourInfoContent };
