import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './CardPlanMainHeaderCompany.css';
import { useTranslation } from 'react-i18next';

const CardPlanMainHeaderCompany = ({ isPartner, isHeader }) => {
  const store = useSelector(({ quoting }) => quoting);
  const { petQuoteSelected, quoteSelectedValues } = store;
  const location = useLocation();
  const isYourInfo = location.pathname.includes('/yourinfo');
  const { t } = useTranslation('quoting');

  const quoteSelected = quoteSelectedValues
    .find((item) => item.quoteId === petQuoteSelected)
    || { petName: 'Pet Name' };

  function getPetName() {
    if (quoteSelected) {
      const petNameFactor = 15;

      if (quoteSelected.petName.length < petNameFactor) {
        return `${quoteSelected.petName}'s Plan`;
      }

      return `${quoteSelected.petName.slice(0, petNameFactor)}...'s Plan`;
    }

    return 'N/A';
  }

  const PetName = () => (
    <h1
      aria-hidden={isHeader}
      className={'Card-plan-header-title '
        + `${(!isPartner && !isHeader)
          ? 'Pet-parent-title-header-partner' : ''}`
        + ' Pet-parent-title-header-partner-less-margin'}
    >
      {getPetName()}
    </h1>
  );

  const YourInfoComponent = () => (
    <h1 aria-hidden="true" className="Pet-parent-title-header">
      {t('petParent.title')}
    </h1>
  );

  return isYourInfo ? <YourInfoComponent /> : <PetName />;
};

export default CardPlanMainHeaderCompany;
