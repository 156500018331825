import React from 'react';
import './Footer.css';

const Footer = () => (
  <div className="Footer-Copyright">
    <span>
      Copyright © 2015–2020 Figo Pet insurance LLC. All rights reserved.
    </span>
  </div>
);

export default Footer;
