import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setSessionStorage } from '../util';

const StorageProvider = () => {
  const store = useSelector((stores) => stores.quoting);

  useEffect(() => {
    if (store.data || store.partners || store.partners.loaded) {
      setSessionStorage({ data: store, key: 'store.quoting' });
      setSessionStorage({ data: { isMounted: true }, key: 'store.mounted' });
    }
  }, [store]);

  return <></>;
};

export default StorageProvider;
