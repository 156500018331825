import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PetPurchaseFailureCard } from './PetPurchaseFailureCard';
import { PetPurchaseSuccessCard } from './PetPurchaseSuccessCard';

import catIcon from '../../../assets/purchase/cat-placeholder.svg';
import dogIcon from '../../../assets/purchase/dog-placeholder.svg';

import './PetPurchaseResult.css';

const PetPurchaseResult = ({
  errorMessage = '',
  errorType = '',
  isCat = false,
  petName = '',
  policyNumber = '',
  success = false,
}) => {
  const { t } = useTranslation('purchaseResult');

  const petIcon = useMemo(() => {
    if (isCat) {
      return catIcon;
    }

    return dogIcon;
  }, [isCat]);

  if (success) {
    return (
      <PetPurchaseSuccessCard
        petIcon={petIcon}
        petName={petName}
        policyNumber={policyNumber}
        t={t}
      />
    );
  }

  return (
    <PetPurchaseFailureCard
      error={errorMessage}
      errorType={errorType}
      petIcon={petIcon}
      petName={petName}
      t={t}
    />
  );
};

export { PetPurchaseResult };
