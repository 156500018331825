import { URL_COSTCO, URL_COSTCO_CX, URL_GOOD_DOG, URL_USAA } from '../routes';

// #region GUIDS
export const COSTCO_PARTNER_IDS = [
  '4BC8B4C4-E147-41F8-8E96-B2E693CC2AD9',
  'E22B67D6-6228-45DE-90D7-26D4470E7CDE',
];

export const COSTCO_PARTNER_ID = COSTCO_PARTNER_IDS[0];

export const COSTCO_CX_PARTNER_ID = COSTCO_PARTNER_IDS[1];

export const GOOD_DOG_PARTNER_ID = '21229794-9CAA-4930-ABA6-B543A2C0CCDB';

export const USAA_PARTNER_ID = 'F85EA715-03FE-4149-9F11-5A8233C2504E';
// #endregion

// #region URL_PARAMS
export const COSTCO_URL_PARAMS = { refer: COSTCO_PARTNER_ID };
export const COSTCO_CX_URL_PARAMS = { refer: COSTCO_CX_PARTNER_ID };
export const GOOD_DOG_URL_PARAMS = { refer: GOOD_DOG_PARTNER_ID };
export const USAA_URL_PARAMS = { refer: USAA_PARTNER_ID };
// #endregion

export const QUOTE_CLIENT_TYPES = {
  costco: 'Costco',
  d2c: 'Direct to Customer',
  goodDog: 'Good Dog',
  usaa: 'USAA',
};

export const RECAPTCHA_PARTNER_ORIGIN = {
  costco: 3,
  d2c: 1,
  goodDog: 4,
  usaa: 5,
};

// #region info per partner
export const PARTNERS = {
  costco: {
    fastRoute: URL_COSTCO,
    name: QUOTE_CLIENT_TYPES.costco,
    partnerId: COSTCO_PARTNER_ID,
    recaptchaOrigin: RECAPTCHA_PARTNER_ORIGIN.costco,
    urlParams: COSTCO_URL_PARAMS,
  },
  costcoCx: {
    fastRoute: URL_COSTCO_CX,
    name: QUOTE_CLIENT_TYPES.costco,
    partnerId: COSTCO_CX_PARTNER_ID,
    recaptchaOrigin: RECAPTCHA_PARTNER_ORIGIN.costco,
    urlParams: COSTCO_CX_URL_PARAMS,
  },
  goodDog: {
    fastRoute: URL_GOOD_DOG,
    name: QUOTE_CLIENT_TYPES.goodDog,
    partnerId: GOOD_DOG_PARTNER_ID,
    recaptchaOrigin: RECAPTCHA_PARTNER_ORIGIN.goodDog,
    urlParams: GOOD_DOG_URL_PARAMS,
  },
  usaa: {
    fastRoute: URL_USAA,
    name: QUOTE_CLIENT_TYPES.usaa,
    partnerId: USAA_PARTNER_ID,
    recaptchaOrigin: RECAPTCHA_PARTNER_ORIGIN.usaa,
    urlParams: USAA_URL_PARAMS,
  },
};
// #endregion
