import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalContainer from '../common/ModalContainer';
import InputWithError from '../common/InputWithError';
import FigoLottie from '../common/FigoLottie';

import InboxIcon from '../../assets/inbox-and-docs.svg';

import { createEffectiveDate, validateEmail } from '../../util';

import { saveQuote, saveQuoteMobileVisibleChanged } from '../../actions';

import './SaveYourQuoteModal.css';
import { usePartnersData } from '../../partners/usePartnersData';

const SaveYourQuoteModal = () => {
  const store = useSelector((stores) => stores.quoting);
  const { saveQuote: { isSavingQuote, saveQuoteMobileModalVisible } } = store;
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailNameError] = useState('');
  const { goodDogData: { petPickupDate } } = usePartnersData();

  useEffect(() => {
    if (store.parameters.email) {
      setEmail(store.parameters.email);
    }
  }, [store.parameters.email]);

  const anyError = firstNameError || lastNameError || emailError
    || !firstName || !lastName || !email;

  function validateFirstName(value) {
    setFirstName(value);
    setFirstNameError(value ? null : 'Required field');
  }

  function handleFirstNameChanged(event) {
    const { target: { value } } = event;
    validateFirstName(value);
  }

  function handleFirstNameBlur() {
    const newValue = firstName.split(' ').filter((item) => !!item).join(' ');
    validateFirstName(newValue);
  }

  function validateLastName(value) {
    setLastName(value);
    setLastNameError(value ? null : 'Required field');
  }

  function handleLastNameChanged(event) {
    const { target: { value } } = event;
    validateLastName(value);
  }

  function handleLastNameBlur() {
    const newValue = lastName.split(' ').filter((item) => !!item).join(' ');
    validateLastName(newValue);
  }

  function validateEmailAddress(value) {
    setEmail(value);
    let error = value ? '' : 'Required field';

    if (!error) {
      error = !validateEmail(value) ? 'Invalid email.' : '';
    }
    setEmailNameError(error);
  }

  function handleEmailChanged(event) {
    const { target: { value } } = event;
    validateEmailAddress(value);
  }

  function handleCloseModal() {
    if (isSavingQuote) {
      return;
    }

    setFirstNameError(null);
    setLastNameError(null);
    setEmailNameError(null);
    dispatch(saveQuoteMobileVisibleChanged(false));
  }

  function handleSaveClick() {
    if (isSavingQuote) {
      return;
    }

    validateFirstName(firstName);
    validateLastName(lastName);
    validateEmailAddress(email);

    if (anyError) {
      return;
    }

    const {
      customerValidationResponse,
      data: {
        groupCode,
        groupCodeDscr,
        petQuoteResponseList,
        zipCode,
      },
    } = store;
    const { quoteSelectedValues, partners: { partnerId } } = store;
    // TODO: this allways 0
    const cloudClientId = customerValidationResponse?.id || 0;
    const { origin } = window.location;

    dispatch(saveQuote({
      cloudClientId,
      EffectiveDate: createEffectiveDate(),
      email,
      firstName,
      groupCode,
      groupCodeDscr,
      lastName,
      origin,
      partnerId,
      petQuoteResponseList,
      pickupDate: petPickupDate,
      promoCode: store.newPet.promoCode,
      quoteSelectedValues,
      QuoteSubId: store.subId,
      zipCode,
    }));
  }

  function renderContentButton() {
    if (isSavingQuote) {
      return (
        <FigoLottie
          height={30}
          width={30}
        />
      );
    }

    return 'Save';
  }

  return (
    <ModalContainer
      cancelable={false}
      handleOutClick={handleCloseModal}
      show={saveQuoteMobileModalVisible}
    >
      <div className="Save-your-quote-modal-main-container">
        <img
          alt="inbox"
          className="Save-your-quote-modal-icon"
          src={InboxIcon}
        />

        <h3 className="Save-your-quote-modal-title">
          Save your quote
        </h3>

        <span className="Save-your-quote-modal-subtitle">
          Not ready? No problem. We’ll send the
          quote to your email for easy access later.
        </span>

        <InputWithError
          containerClass="Save-your-quote-input-container"
          labelError={firstNameError}
          onBlur={handleFirstNameBlur}
          onChange={handleFirstNameChanged}
          placeholder="First Name"
          showError={firstNameError}
          value={firstName}
        />

        <InputWithError
          containerClass="Save-your-quote-input-container"
          labelError={lastNameError}
          onBlur={handleLastNameBlur}
          onChange={handleLastNameChanged}
          placeholder="Last Name"
          showError={lastNameError}
          value={lastName}
        />

        <InputWithError
          containerClass="Save-your-quote-input-container"
          labelError={emailError}
          onBlur={handleEmailChanged}
          onChange={handleEmailChanged}
          placeholder="Email Address"
          showError={emailError}
          value={email}
        />

        <div className="Email-your-quote-buttons-container">
          <button
            className={'Save-your-quote-save-button '
              + 'Save-your-quote-save-button-cancel'}
            disabled={isSavingQuote}
            onClick={handleCloseModal}
            type="button"
          >
            Cancel
          </button>

          <button
            aria-busy={isSavingQuote}
            aria-disabled={anyError}
            aria-label={isSavingQuote ? 'Saving quote' : 'Save'}
            aria-live={isSavingQuote ? 'polite' : 'off'}
            className="Save-your-quote-save-button"
            onClick={handleSaveClick}
            type="button"
            value="Save"
          >
            {renderContentButton()}
          </button>
        </div>
      </div>
    </ModalContainer>
  );
};

export { SaveYourQuoteModal };
