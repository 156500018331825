import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslations } from '../../../hooks';
import { WhatsCoveredLink } from '../WhatsCoveredLink';
import Shimmer from '../../common/Shimmer';
import Switch from '../../common/Switch';

import ActiveCopayImg
from '../../../assets/powerUps/pet-protect-copay-active.svg';
import CopayImg from '../../../assets/powerUps/pet-protect-copay.svg';
import InfoImage from '../../../assets/info-deep-blue-citron.svg';

import {
  petCoPayModalVisibleChanged,
  togglePetCoPayModalVisibleChanged,
} from '../../../actions';
import {
  MODAL_DEFAULT_CATEGORY,
  MODAL_RELATION_LIST,
} from '../../../constants';

import './PowerUpCoPay.css';
import { PowerUpCoPayModal } from './PowerUpCoPayModal';

const SPACE_KEY = 32;

const PowerUpCoPay = ({
  handleAddPowerup,
  handleRemovePowerup,
  multiModalData,
  powerUp,
  selected,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslations('quoting', 'powerUpsSection');
  const [localSelected, setLocalSelected] = useState(selected);
  const switchRef = useRef(null);
  const {
    InformationText,
    TitleText,
  } = powerUp;

  const { PET_CO_PAY_MODAL_ID } = MODAL_RELATION_LIST;
  const { PET_CO_PAY_DEF_CATEGORY_ID } = MODAL_DEFAULT_CATEGORY;

  useEffect(() => {
    setLocalSelected(selected);
  }, [selected]);

  const handleAddPowerupCallback = useCallback((event) => {
    if (!localSelected && handleAddPowerup) {
      handleAddPowerup(event);
    }
  }, [handleAddPowerup, localSelected]);

  const handleRemovePowerupCallback = useCallback((event) => {
    if (localSelected && handleRemovePowerup) {
      handleRemovePowerup(event);
      switchRef.current.focus();
    }
  }, [handleRemovePowerup, localSelected]);

  const onCancelCallback = useCallback(() => {
    dispatch(togglePetCoPayModalVisibleChanged({ visible: false }));
  }, [dispatch]);

  const onClick = useCallback((event) => {
    if (localSelected) {
      handleRemovePowerupCallback(event);
    } else {
      dispatch(togglePetCoPayModalVisibleChanged({ visible: true }));
    }
  }, [
    dispatch,
    handleRemovePowerupCallback,
    localSelected,
  ]);

  const onClickHowItWorks = useCallback(() => {
    let categoryId = 1;

    if (multiModalData.InsuranceMultiModalSectionDtoList) {
      const categoryList = multiModalData
        .InsuranceMultiModalSectionDtoList
        .find((element) => element.Id === PET_CO_PAY_MODAL_ID);

      if (categoryList) {
        categoryId = categoryList
          .InsuranceMultiModalCategoryDtoList
          .find((sub) => sub.Id === PET_CO_PAY_DEF_CATEGORY_ID).Id;
      }
    }

    dispatch(petCoPayModalVisibleChanged({ categoryId, visible: true }));
  }, [
    dispatch,
    multiModalData.InsuranceMultiModalSectionDtoList,
    PET_CO_PAY_DEF_CATEGORY_ID,
    PET_CO_PAY_MODAL_ID,
  ]);

  function handleEnterPress(event) {
    if (event.key === 'Enter' || event.keyCode === SPACE_KEY) {
      onClickHowItWorks();
    }
  }

  const HowItWorks = () => (
    <button
      aria-label={t('howItWorks')}
      className="Copay-how-it-works-container"
      onClick={onClickHowItWorks}
      onKeyDown={handleEnterPress}
      title="info"
      type="button"
    >
      <img
        alt="info"
        className="Copay-info-icon"
        src={InfoImage}
      />

      <WhatsCoveredLink
        className="Copay-how-it-works-text"
        id="HowItWorksLink"
        text={t('howItWorks')}
      />
    </button>
  );

  return (
    <div
      className={`Copay-card-container${localSelected ? '-selected' : ''}`}
    >
      <img
        alt=""
        className="Copay-icon"
        src={localSelected ? ActiveCopayImg : CopayImg}
      />

      <Shimmer className="Copay-top-row" visible={!TitleText}>
        <span className="Copay-title">{TitleText}</span>

        <Switch
          active={localSelected}
          ariaProps={{
            'aria-label': localSelected ? t('removeCopay') : t('addCopay'),
          }}
          customClassname={`Copay-switch
        ${localSelected ? ' Copay-added' : ''}`}
          onClick={onClick}
          switchRef={switchRef}
        />
      </Shimmer>

      <Shimmer className="Copay-bottom-row" visible={!InformationText}>
        <span className="Copay-description">{InformationText}</span>

        <HowItWorks />
      </Shimmer>

      <PowerUpCoPayModal
        handleContinue={handleAddPowerupCallback}
        handleNeverMind={onCancelCallback}
      />
    </div>
  );
};

export { PowerUpCoPay };
