import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  saveSelectedAddPetModal,
  setPowerUpErrors,
} from '../../../actions';
import ill10 from '../../../assets/ills/ill-10.svg';
import { useTranslations } from '../../../hooks';

import plusIcon from '../../../assets/plus-circle-icon.svg';

import './MyPetsContainer.css';
import { useEnrollmentflag, validatePowerupsSelected } from '../../../util';

const ADD_PET_ID = 'btn-add-anohter-pet';

const MyPetsContainer = () => {
  const { t } = useTranslations('quoting', 'myPets');
  const dispatch = useDispatch();
  const buttonRef = React.useRef(null);
  const {
    data,
    petQuoteSelected,
  } = useSelector(({ quoting }) => quoting, shallowEqual);

  function handleAddPetClick() {
    buttonRef.current?.blur();

    const petQuoteRateItem = data.petQuoteResponseList
      .find((pqRateItem) => pqRateItem.petQuoteId === petQuoteSelected);
    const powerUpValidation = validatePowerupsSelected(petQuoteRateItem);
    if (!powerUpValidation.isValid) {
      dispatch(setPowerUpErrors(powerUpValidation.modifierIdNotSelectedList));
      return;
    }

    dispatch(saveSelectedAddPetModal(true));
  }

  function renderTitle() {
    return (
      <>
        <button
          ref={buttonRef}
          aria-label={useEnrollmentflag ? t('addAnotherPet') : t('addPet')}
          className={useEnrollmentflag
            ? 'My-pets-add-pet-button-enrollment'
            : 'My-pets-add-pet-button'}
          id={ADD_PET_ID}
          onClick={handleAddPetClick}
          onKeyPress={handleAddPetClick}
          type="button"
        >
          <img
            alt=""
            className="My-pets-add-icon"
            src={plusIcon}
          />

          <span className="My-pets-add-pet-button-text">
            {useEnrollmentflag ? t('addAnotherPet') : t('addPet')}
          </span>
        </button>

        <section aria-label={t('addPetDiscountDetails')}>
          <span className="My-pets-discount">
            {t('discount')}
          </span>
        </section>
      </>
    );
  }

  return (
    <div className="My-pets-container">
      <div className="My-pets-title-container">
        {renderTitle()}
      </div>

      {useEnrollmentflag && (
        <img
          alt=""
          aria-hidden
          className="Quote-content-image-10 No-mobile No-tablet"
          src={ill10}
        />
      )}
    </div>
  );
};

export { MyPetsContainer };
