/**
 * @Route
 * URL_YOUR_INFO = '/quoting/getquote/yourinfo/'
 *
 * @Component
 * src/components/quoting/yourInfo/YourInfoFooter.js
 *
 * @Description
 * A begin_checkout event represents when the user completes the process of
 * adding all pets, personal information, add a payment method and move
 * forward with processing the quote into a paid policy.
 * This event is fired when the user press the button "Purchase" before
 * Confirmation Page Loads.
 * The information comes from the Session Storage store.quoting key.
 * Be careful: It is a nested object that is difficult to process and prone to
 * errors.
 *
 * @Reference
 * https://developers.google.com/tag-manager/ecommerce-ga4?hl=es
 * #measure_a_checkout
 */
import { dataLayerEvent, pickupItemsFromSessionStorage } from './dataLayer';
import { currencyFormat, useGoogleTagManager } from '../util';
import { DATALAYER_EVENT_TYPE } from './constants';
import { BILLING_CYCLE_TYPE } from '../constants';
import { getBillingCycle, getCoupon, waitForQuotingData } from './utils';

export const dataLayerBeginCheckout = async () => {
  if (!useGoogleTagManager) {
    return;
  }

  // clear the previous ecommerce data object
  dataLayerEvent({ ecommerce: null });

  const items = await pickupItemsFromSessionStorage();

  const quoting = await waitForQuotingData();

  const billingCycle = quoting.isMonthlyPlan
    ? BILLING_CYCLE_TYPE.monthly
    : BILLING_CYCLE_TYPE.annualy;

  const summary = quoting.rateSummary.QuoteSummaries.find(
    (item) => item.BillingCycle === billingCycle
      && item.PaymentType === quoting.paymentMethod.paymentMethodType.value,
  ) || { MonthlyPremiumTotal: 0, TodaysTotal: 0 };

  const petsList = await items;

  const petListWithoutPetQuoteId = petsList.map((item) => {
    const itemCopy = { ...item };
    delete itemCopy.petQuoteId;
    return itemCopy;
  });

  const ecommerce = {
    billing_cycle: getBillingCycle(quoting),
    coupon: getCoupon(quoting),
    currency: 'USD',
    items: petListWithoutPetQuoteId,
    language: 'en',
    value: Number(currencyFormat({ value: summary.TodaysTotal })),
  };

  dataLayerEvent({
    ecommerce,
    event: DATALAYER_EVENT_TYPE.BEGIN_CHECKOUT,
    language: 'en',
  });
};
