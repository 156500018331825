import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import TagPetCover from '../../common/TagPetCover';
import {
  BILLING_CYCLE_TYPE,
  EXECUTIVE_MEMBERSHIP_TYPE_NAME,
  POWERUP_TYPE,
} from '../../../constants';
import { currencyFormat } from '../../../util';
import { PartnerDiscountCallout } from './PartnerDiscountCallout';
import { useTranslations } from '../../../hooks';
import expandIcon from '../../../assets/yourInfo/plus.svg';
import collapseIcon from '../../../assets/yourInfo/substract.svg';
import './PaymentBreakdown.css';

const BreakdownItem = ({ label, value, bold = false, toBottom = false }) => (
  <li
    className="Payment-breakdown-list-item"
    data-bold={bold}
    data-toBottom={toBottom}
  >
    <span>{label}</span>

    <span>{value}</span>
  </li>
);

const PetBreakdown = ({ isMonthlyPlan = false, quoteRate }) => {
  const { t } = useTranslation('enrollment');
  const [isExpanded, setIsExpanded] = useState(true);
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const {
    data,
    partners: {
      costcoMembership: {
        isFeeWaived,
        executeMemberBenefitDisplay,
        executeMemberBenefitWaivedAmount,
        feeWaivedMessageContent,
        feeWaivedMessageTitle,
      },
      data: {
        MembershipValidation,
      },
      isCostco,
    },
    paymentMethod: { paymentMethodType },
    petTags,
    quoteSelectedValues,
    rateSummary,
  } = store;
  const quote = quoteSelectedValues
    .find((pet) => pet.quoteId === quoteRate.petQuoteId);
  const petTag =
    petTags.find((item) => item.petQuoteId === quote.quoteId) || {};

  const handleExpanded = useCallback(() => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  }, []);

  const modifiersMapped = useMemo(() => {
    const modifiers = quoteRate.InsuranceModifiers || [];
    const powerUpsFilter = modifiers
      .filter((modifier) => modifier.InsuranceModifierTypeId === POWERUP_TYPE
        && modifier.IsVisible)
      .map((powerUp) => ({
        id: powerUp.Id,
        label: powerUp.TitleText,
        value: powerUp.IsSelected
          ? t('paymentBreakdown.included')
          : t('paymentBreakdown.notIncluded'),
      }));

    return powerUpsFilter;
  }, [quoteRate, t]);

  const { InsuranceProductFee } = data.insuranceProduct;

  const oneTimeFee = useMemo(() => {
    const { oneTime } = BILLING_CYCLE_TYPE;

    const fee = InsuranceProductFee
      .find((item) => item.InsuranceFee.BillingCycleType === oneTime);

    return fee;
  }, [InsuranceProductFee]);

  const installmentFee = useMemo(() => {
    const { annualy, monthly } = BILLING_CYCLE_TYPE;
    const billingCycle = isMonthlyPlan ? monthly : annualy;
    const fee = InsuranceProductFee
      .find((item) => item.InsuranceFee.BillingCycleType === billingCycle
        && item.InsuranceFee.PaymentType === paymentMethodType.value);

    return fee;
  }, [InsuranceProductFee, isMonthlyPlan, paymentMethodType.value]);

  const applyDiscount = useMemo(() => isCostco
    && oneTimeFee && isFeeWaived, [isCostco, isFeeWaived, oneTimeFee]);

  const taxes = useMemo(() => {
    const quoteSummary = rateSummary.PetQuotes
      .find((item) => item.PetQuoteId === quoteRate.petQuoteId) || {};
    return isMonthlyPlan
      ? quoteSummary.MonthlyTaxes
      : quoteSummary.AnnualTaxes;
  }, [isMonthlyPlan, quoteRate, rateSummary]);

  const taxesItems = useMemo(() => {
    if (!taxes) {
      return null;
    }

    return (
      taxes.map((item) => (
        <BreakdownItem
          key={String(`${item.Description}${item.Amount}`)}
          bold
          label={item.Description}
          value={`$${currencyFormat({ value: item.Amount })}`}
        />
      ))
    );
  }, [taxes]);

  const taxesReduced = useMemo(() => {
    if (!taxes) {
      return 0;
    }

    return taxes.reduce((prev, next) => (prev + next.Amount), 0);
  }, [taxes]);

  const total = useMemo(() => {
    const { annualAmountPlan, monthlyAmountPlan } = quote;
    const taxesTotal = taxesReduced;
    let oneTimeAmount = 0;
    let installmentAmount = 0;

    if (oneTimeFee && !isFeeWaived) {
      oneTimeAmount = oneTimeFee.InsuranceFee.Amount;
    }

    if (installmentFee) {
      installmentAmount = installmentFee.InsuranceFee.Amount;
    }

    const baseTotal = isMonthlyPlan ? monthlyAmountPlan : annualAmountPlan;

    return baseTotal + installmentAmount + oneTimeAmount + taxesTotal;
  }, [
    installmentFee,
    isFeeWaived,
    isMonthlyPlan,
    oneTimeFee,
    quote,
    taxesReduced,
  ]);

  const executiveMembership = useMemo(() => (MembershipValidation
    ? MembershipValidation.MembershipTypes?.find(
      (membership) => membership.Name === EXECUTIVE_MEMBERSHIP_TYPE_NAME,
    ) : null), [MembershipValidation]);

  const petContainerId = `petbreakdown-${quoteRate.petQuoteId}`;

  return (
    <div
      key={String(quoteRate.petQuoteId)}
      className="Column Payment-breakdown-pet-row-container"
    >
      <button
        aria-controls={petContainerId}
        aria-expanded={isExpanded}
        className="Row Full-width-mobile Payment-breakdown-toggle"
        onClick={handleExpanded}
        type="button"
      >
        <span className="Payment-breakdown-pet-tag-container">
          <TagPetCover
            className="Payment-breakdown-pet-tag"
            petType={petTag.petType}
          />
        </span>

        <span className="Payment-breakdown-pet-info">
          <span className="Payment-breakdown-pet-info-left">
            <span
              className="Payment-breakdown-pet-name"
            >
              {petTag.petName}
            </span>

            <span className="Payment-breakdown-breed">
              {quoteRate.breedName} | {quoteRate.petAgeName}
              &nbsp;| {quoteRate.genderName}
            </span>
          </span>

          <img
            alt=""
            aria-hidden
            className="Payment-breakdown-action"
            src={isExpanded ? collapseIcon : expandIcon}
          />
        </span>
      </button>

      <div
        className={'Payment-breakdown-expandable-container'
          + ` ${isExpanded ? '' : 'Payment-breakdown-hidden'}`}
        id={petContainerId}
      >
        <div className="Payment-breakdown-expandable">
          <div className="Payment-breakdown-column">
            <h2
              className="Payment-breakdown-list-title"
              id={`${petContainerId}-summary`}
            >
              {t('paymentBreakdown.planSummary')}
            </h2>

            <ul
              aria-labelledby={`${petContainerId}-summary`}
              className="Payment-breakdown-list"
            >
              <BreakdownItem
                label={t('paymentBreakdown.annualLimit')}
                value={quote.selectedPlanAmount.toUpperCase()}
              />

              <BreakdownItem
                label={t('paymentBreakdown.annualDeductible')}
                value={quote.deductibleQuantity}
              />

              <BreakdownItem
                label={t('paymentBreakdown.reimbursement')}
                value={quote.reimbursementPercentage}
              />

              {modifiersMapped.map((modifier) => (
                <BreakdownItem
                  label={modifier.label}
                  value={modifier.value}
                />
              ))}
            </ul>
          </div>

          <div className="Payment-breakdown-column">
            <h2
              className="Payment-breakdown-list-title"
              id={`${petContainerId}-cost`}
            >
              {isMonthlyPlan
                ? t('paymentBreakdown.monthlyCostBreakdown')
                : t('paymentBreakdown.annualCostBreakdown')}
            </h2>

            <ul
              aria-labelledby={`${petContainerId}-cost`}
              className="Payment-breakdown-list"
            >
              <BreakdownItem
                label={t(`paymentBreakdown.${isMonthlyPlan
                  ? 'monthlyPremium' : 'annualPremium'}`)}
                value={isMonthlyPlan
                  ? `$${currencyFormat({ value: quote.monthlyAmountPlan })}`
                  : `$${currencyFormat({ value: quote.annualAmountPlan })}`}
              />

              {installmentFee && (
                <BreakdownItem
                  label={installmentFee.InsuranceFee.DisplayName}
                  value={`$${currencyFormat({
                    value: installmentFee.InsuranceFee.Amount,
                  })}`}
                />
              )}

              {oneTimeFee && (
                <BreakdownItem
                  label={oneTimeFee.InsuranceFee.DisplayName}
                  value={`$${currencyFormat({
                    value: oneTimeFee.InsuranceFee.Amount,
                  })}`}
                />
              )}

              {applyDiscount && (
                <BreakdownItem
                  label={executeMemberBenefitDisplay}
                  value={`-$${currencyFormat({
                    value: executeMemberBenefitWaivedAmount,
                  })}`}
                />
              )}

              {taxesItems}

              <BreakdownItem
                bold
                label={`${petTag.petName}’s Total`}
                toBottom
                value={`$${currencyFormat({ value: total })}`}
              />
            </ul>
          </div>
        </div>

        {feeWaivedMessageContent && (
          <div className="Row Payment-breakdown-discount-callout-container">
            <PartnerDiscountCallout
              membershipImage={executiveMembership?.Image}
              messageContent={feeWaivedMessageContent}
              messageTitle={feeWaivedMessageTitle}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const PaymentBreakdown = () => {
  const { t } = useTranslations('quoting');
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const {
    data,
    isMonthlyPlan,
  } = store;

  return (
    <div
      className="Payment-breakdown-container"
      id="payment-breakdown"
    >
      <h1 className="Payment-breakdown-title">{t('petPolicies')}</h1>

      {data.petQuoteResponseList.map((quoteRate) => (
        <PetBreakdown isMonthlyPlan={isMonthlyPlan} quoteRate={quoteRate} />
      ))}
    </div>
  );
};

export default PaymentBreakdown;
