import React, { forwardRef, useMemo } from 'react';
import './FigoInput.css';
import { FigoInputMessage } from './FigoInputMessage';
import lockIcon from '../../assets/lock-square-closed.svg';

const SIZES = {
  large: 'large',
  small: 'small',
  xl: 'xl',
};

const FigoInput = forwardRef(({
  containerClassName = '',
  disabled = false,
  error = false,
  hint = '',
  id = '',
  label = '',
  message = '',
  messageType = '',
  name = '',
  onBlur = () => { },
  onChange = () => { },
  onFocus = () => { },
  placeholder = '',
  readOnly = false,
  rightIcon = null,
  size = SIZES.large,
  type = 'text',
  value = '',
  ...inputProps
}, ref) => {
  const sizeClassName = useMemo(() => `Figo-input-${size}`, [size]);

  const rightIconDisplay = useMemo(() => {
    if (rightIcon) {
      return (
        <div className="Figo-input-right-display">
          {rightIcon}
        </div>
      );
    }
    if (readOnly) {
      return <img alt="" className="Figo-input-right-display" src={lockIcon} />;
    }
    return null;
  }, [readOnly, rightIcon]);

  return (
    <div className={`Figo-input-container ${containerClassName}`}>
      <label className="Figo-input-label" htmlFor={id}>
        {label}

        <input
          ref={ref}
          className={`Figo-input-input ${sizeClassName}`}
          data-error={error}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          readOnly={readOnly}
          type={type}
          value={value}
          {...inputProps}
        />

        {rightIconDisplay}
      </label>

      {hint ? (
        <p className="Figo-input-hint">
          {hint}
        </p>
      ) : null}

      <FigoInputMessage message={message} type={messageType} />
    </div>
  );
});

export { FigoInput };
