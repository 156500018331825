import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomModal } from '../common/CustomModal';
import { useTranslations } from '../../hooks';

import { changeGoodDogComingSoonModal } from '../../actions';
import comingSoonIcon from '../../assets/coming-soon-icon.svg';
import './ComingSoonModal.css';

export const GoodDogComingSoonModal = () => {
  const { t } = useTranslations('landing');
  const store = useSelector(({ quoting }) => ({ quoting }));
  const dispatch = useDispatch();

  const { goodDogComingSonModalVisible } = store.quoting;

  const onClose = () => {
    dispatch(changeGoodDogComingSoonModal(false));
  };

  return (
    <CustomModal
      description={t('goodDog.comingSoon.description')}
      displayButton={false}
      handleOutClick={onClose}
      id="GoodDogComingSoonModal"
      imageSrc={comingSoonIcon}
      imgAlt=""
      title={t('goodDog.comingSoon.title')}
      visible={goodDogComingSonModalVisible}
    >
      <a
        className="Coming-soon-button-check-price"
        href="https://figopetinsurance.com/get-started"
        rel="noreferrer"
        target="_blank"
        title={t('goodDog.comingSoon.checkPrices')}
        type="button"
      >
        <span
          className="Coming-soon-notify-user-primary-button-text"
        >
          {t('goodDog.comingSoon.checkPrices')}
        </span>
      </a>
    </CustomModal>
  );
};
