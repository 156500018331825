import React from 'react';

import FigoLottie from '../../common/FigoLottie';

import addIcon from '../../../assets/ddPetSelector/add.svg';
import arrowDown from '../../../assets/ddPetSelector/arrow-down.svg';
import catPlaceHolder from '../../../assets/ddPetSelector/cat-placeholder.svg';
import dogPlaceHolder from '../../../assets/ddPetSelector/dog-placeholder.svg';
import { ReactComponent as RemoveIcon }
from '../../../assets/ddPetSelector/remove.svg';
import { ReactComponent as PencilIcon }
from '../../../assets/edit-pencil-citron.svg';
import { useTranslations } from '../../../hooks/useTranslations';

import { currencyFormat } from '../../../util';
import { PetType, DROPDOWN_ITEM_TYPE } from '../../../constants';

export const DropDownIcon = ({ isOpen, dropDownItemType, petType }) => {
  let alt = 'pet placeholder';
  let className = 'Pet-quote-drop-down-pet-placeholder';
  let src = petType === PetType.Dog.value
    ? dogPlaceHolder : catPlaceHolder;

  if (dropDownItemType === DROPDOWN_ITEM_TYPE.header) {
    alt = 'arrow drop down';
    className = 'Pet-quote-drop-down-arrow '
      + `${isOpen ? 'Pet-quote-drop-down-arrow-open' : ''}`;
    src = arrowDown;
  }

  if (dropDownItemType === DROPDOWN_ITEM_TYPE.addPet) {
    alt = 'add drop down element';
    className = 'Pet-quote-drop-down-plus-icon';
    src = addIcon;
  }

  return (
    <div className="Pet-quote-drop-down-arrow-container">
      <img
        alt={alt}
        aria-hidden="true"
        className={className}
        src={src}
      />
    </div>
  );
};

export const PetQuoteInfo = ({
  totalMontly,
  dropDownItemType,
  handleCancelRemovePet,
}) => {
  const { t } = useTranslations('quoting');
  function getQuoteTotal() {
    const quoteTotal = totalMontly || 0;
    return `$${currencyFormat({ value: quoteTotal })}`;
  }

  if (dropDownItemType === DROPDOWN_ITEM_TYPE.pet
    || dropDownItemType === DROPDOWN_ITEM_TYPE.header) {
    return (
      <section aria-label={t('petQuote')}>
        <span
          className="Pet-quote-drop-down-pet-price"
          style={dropDownItemType === DROPDOWN_ITEM_TYPE.pet
            ? { marginRight: '40px' } : {}}
        >
          {getQuoteTotal()}
        </span>
      </section>
    );
  }

  if (dropDownItemType === DROPDOWN_ITEM_TYPE.remove) {
    return (
      <section aria-label={t('cancelPetRemoval')}>
        <button
          aria-label="Cancel remove pet"
          className="Pet-quote-drop-down-cancel-action"
          id="cancelRemovePet"
          onClick={handleCancelRemovePet}
          title="Cancel remove pet"
          type="button"
        >
          <span className="Pet-quote-drop-down-no-events">Cancel</span>
        </button>
      </section>

    );
  }

  return '';
};

export const PetQuoteConfirmButton = ({
  onEditPet = () => { },
  onRemovePet = () => { },
  petRemovedLoading,
}) => {
  const renderConfirmButtonContent = () => {
    if (petRemovedLoading) {
      return <FigoLottie />;
    }

    return (
      <>
        <RemoveIcon /> Remove Pet
      </>
    );
  };

  return (
    <div className="Pet-quote-dropdown-buttons">
      <button
        className="Pet-quote-dropdown-button"
        onClick={onEditPet}
        type="button"
      >
        <span
          className="Pet-quote-drop-down-no-events Pet-quote-drop-down-icon"
        >
          <PencilIcon /> Edit Pet
        </span>
      </button>

      <button
        className="Pet-quote-dropdown-button"
        onClick={onRemovePet}
        type="button"
      >
        <span
          className="Pet-quote-drop-down-no-events Pet-quote-drop-down-icon"
        >
          {renderConfirmButtonContent()}
        </span>
      </button>
    </div>
  );
};
