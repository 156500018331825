// import track from 'react-tracking';
import moment from 'moment';

import { PLAN_LIMIT, PowerUpsRelation } from '../constants';

export const PAYMENT_TYPES_TRACKING = {
  BankAccount: 'Bank Account',
  CreditCard: 'Credit Card',
};

export const EVENT_TYPE = {
  DEDUCTIBLE_SELECTION: 'DEDUCTIBLE_SELECTION',
  LOAD_QUOTE: 'LOAD_QUOTE',
  PAYMENT_METHOD_ADDITION: 'PAYMENT_METHOD_ADDITION',
  PAYMENT_METHOD_SELECTION: 'PAYMENT_METHOD_SELECTION',
  PLAN_SELECTION: 'PLAN_SELECTION',
  PURCHASE_COMPLETE: 'PURCHASE_COMPLETE',
  REIMBURSEMENT_SELECTION: 'REIMBURSEMENT_SELECTION',
};

export const baseRequest = ({
  codeMask = '',
  email = '',
  promoCode = '',
  subId = '',
}) => ({
  code_mask: codeMask,
  datetime_utc: moment(new Date()).format('MM-DD-YYYY hh:mm:ss'),
  email,
  origin: 'D2C',
  partner_account_code: promoCode,
  subid: subId,
});

export function getPetPowerups({ currentPet, modifierList }) {
  if (!currentPet.modifiers) {
    return {
      extraCarePack: 'none',
      vetFees: 'none',
      wellness: 'none',
    };
  }

  const currentPetMods = currentPet.modifiers.filter((mod) => mod.isSelected);

  const modList = modifierList.filter((mod) => currentPetMods
    .find((petMod) => petMod.id === mod.Id));
  const extraCarePack = modList.find((mod) => mod.PMSModifierId
    === PowerUpsRelation.EXTRA_CARE_PACK_PSM_ID);

  const vetFees = modList.find((mod) => mod.PMSModifierId
    === PowerUpsRelation.VETERINARY_EXAM_FEES_PSM_ID);

  const wellness = modList.find((mod) => mod.PMSModifierId
    === PowerUpsRelation.WELLNESS_PSM_ID);

  let wellnessTag = 'none';

  if (wellness) {
    wellnessTag = wellness.BundleInsuranceModifiers
      .find((wChild) => currentPetMods.find((pm) => pm.id === wChild.Id))
      ?.TitleText.toLowerCase() === 'basic' ? 'tier-3' : 'tier-5';
  }

  return {
    extraCarePack: extraCarePack ? 'selected' : 'none',
    vetFees: vetFees ? 'selected' : 'none',
    wellness: wellnessTag,
  };
}

export function getCurrentPrice({
  currentPet,
  deductibleIdParam = 0,
  petIdSelected,
  planIndex,
  quoteSelectedValues,
  reimbursementIdParam = 0,
}) {
  const plans = currentPet.Plans[planIndex];
  const { deductibleId, reimbursementId } =
    quoteSelectedValues.find((pt) => pt.quoteId === petIdSelected);
  const price =
      plans.RatingOptions.find((rating) => rating.DeductibleId
      === (deductibleIdParam || deductibleId)
      && rating.ReimbursementId === (reimbursementIdParam || reimbursementId));

  return price;
}

export function getQuotePlanInfo({ petId, quoteSelectedValues }) {
  const currentQuote = quoteSelectedValues
    .find((quote) => quote.quoteId === petId);

  return {
    annualPremium: currentQuote.totalAnnual,
    deductible: currentQuote.deductibleQuantity,
    deductibleId: currentQuote.deductibleId,
    monthlyLimit: currentQuote.selectedPlanAmount,
    monthlyPremium: currentQuote.totalMontly,
    reimbursement: currentQuote.reimbursementPercentage,
    selectedPlanIndex: currentQuote.selectedPlanIndex,
  };
}

export function getPlanLimit(planInfoLimit) {
  let planLimit = PLAN_LIMIT.ESSENTIAL.value;

  switch (planInfoLimit) {
    case PLAN_LIMIT.ESSENTIAL.title:
      planLimit = PLAN_LIMIT.ESSENTIAL.value;
      break;
    case PLAN_LIMIT.PREFERRED.title:
      planLimit = PLAN_LIMIT.PREFERRED.value;
      break;
    case PLAN_LIMIT.UNLIMITED.title:
      planLimit = PLAN_LIMIT.UNLIMITED.value;
      break;
    default:
      planLimit = PLAN_LIMIT.ESSENTIAL.value;
      break;
  }

  return planLimit;
}

export const customTrackEvent = (data) => {
  (window.dataLayer || []).push(data);

  // eslint-disable-next-line no-console
  console.log('trackEvent', data);
};
