import React from 'react';
import './Switch.css';

const Switch = ({
  active,
  ariaProps,
  customClassname,
  label,
  onClick,
  switchBallColor = '#fff',
  switchRef,
}) => {
  function handleButtonClick() {
    onClick();
  }

  function renderClassName() {
    if (active) {
      return 'Switch-active-container';
    }

    return 'Switch-inactive-container';
  }

  function renderBallPositionClassName() {
    if (active) {
      return 'Switch-position-active';
    }

    return 'Switch-position-inactive';
  }

  return (
    <div className="Switch-container">
      <button
        ref={switchRef}
        className={`Switch-button-container ${renderClassName()}
        ${customClassname}`}
        onClick={handleButtonClick}
        type="button"
        {...ariaProps}
      >
        <div
          className={`Switch-ball ${renderBallPositionClassName()}`}
          style={{ backgroundColor: switchBallColor }}
        />
      </button>

      <span className="Switch-label">{label}</span>
    </div>
  );
};

export default Switch;
