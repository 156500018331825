import React from 'react';
import dogTag from '../../assets/dog-selected.svg';
import catTag from '../../assets/cat-selected.svg';
import { PetType } from '../../constants';

const TagPetCover = ({ petType }) => {
  function getTagImagen() {
    if (petType === PetType.Dog.value) {
      return dogTag;
    }

    return catTag;
  }

  return (
    <img
      alt=""
      className="Tag-pet-cover"
      src={getTagImagen()}
    />
  );
};

export default TagPetCover;
