import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import {
  changeGoodDogComingSoonModal,
  saveQuote,
  sendFSCQuoteLead,
  setPowerUpErrors,
  toggleYourInfoScrollToTop,
} from '../../../actions';

import { getStateAbbreviation } from '../../../utils/zipcode';
import { usePartnersData } from '../../../partners/usePartnersData';

import arrowRightIcon from '../../../assets/next-citron.svg';

import { URL_ROOT_QUOTE, URL_YOUR_INFO } from '../../../routes';
import {
  createEffectiveDate,
  currencyFormat,
  removeDuplicates,
  useFSCLeads,
  useGoodDogTN,
  validatePowerupsSelected,
} from '../../../util';
import { dataLayerAddToCart } from '../../../gtmDataLayer';
import './PriceStickyBar.css';
import { TemporaryMessage } from '../TemporaryMessage';
import { useStickyBarHeight } from '../../landing/StickyBarHeightProvider';
import { QuotingSteps, SAVE_QUOTE_TYPE } from '../../../constants';

const PriceStickyBar = () => {
  const store = useSelector(({ quoting }) => quoting);
  const {
    currentQuoteStep,
    customerZipCode,
    data,
    isMonthlyPlan,
    quoteSelectedValues,
    petQuoteSelected,
    utmParams,
  } = store;

  const location = useLocation();
  const { t } = useTranslation('quoting');
  const dispatch = useDispatch();
  const history = useHistory();
  const { setStickyBarHeight } = useStickyBarHeight();
  const ref = useRef(null);
  const isSecondStep = currentQuoteStep === QuotingSteps.YourInfo.value;

  const stickyBarRef = useRef(null);
  const { isGoodDog } = usePartnersData();

  const adjustMainContentPadding = () => {
    const mainContent = document.querySelector('.App');

    if (!mainContent || !stickyBarRef.current) return;

    const barHeight = stickyBarRef.current.offsetHeight;

    mainContent.style.paddingBottom = `${barHeight}px`;
  };

  useEffect(() => {
    if (isSecondStep) {
      const mainContent = document.querySelector('.App');
      mainContent.style.paddingBottom = '0px';
    }

    adjustMainContentPadding();

    const resizeObserver = new ResizeObserver(() => {
      if (isSecondStep) {
        const mainContent = document.querySelector('.App');
        mainContent.style.paddingBottom = '0px';
      }

      adjustMainContentPadding();
    });

    if (stickyBarRef.current) {
      resizeObserver.observe(stickyBarRef.current);
    }

    return () => {
      if (stickyBarRef.current) {
        resizeObserver.unobserve(stickyBarRef.current);
      }
    };
  }, [isSecondStep, stickyBarRef]);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setStickyBarHeight(ref.current.offsetHeight);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setStickyBarHeight]);

  const nextStepLabel = useMemo(() => {
    if (location.pathname.toLocaleLowerCase() === URL_ROOT_QUOTE) {
      return t('priceStickyBar.stepsDescription.enrollment');
    }

    if (location.pathname.toLocaleLowerCase() === URL_YOUR_INFO) {
      return t('priceStickyBar.stepsDescription.continue');
    }

    return t('priceStickyBar.stepsDescription.purchase');
  }, [location, t]);

  const quoteTotal = useMemo(() => {
    const allQuotes = removeDuplicates(quoteSelectedValues, 'quoteId').reduce(
      (previous, current) => {
        if (!isMonthlyPlan) {
          return previous + current.totalAnnual;
        }

        return previous + current.totalMontly;
      },
      0,
    );

    if (!allQuotes) {
      return '0.00';
    }

    return currencyFormat({ value: allQuotes });
  }, [quoteSelectedValues, isMonthlyPlan]);

  const numberOfPets = useMemo(() => {
    if (data && data.petQuoteResponseList) {
      return data.petQuoteResponseList.length;
    }

    return 0;
  }, [data]);

  const onContinueClick = () => {
    const petQuoteRateItem = data.petQuoteResponseList
      .find((pqRateItem) => pqRateItem.petQuoteId === petQuoteSelected);
    const powerUpValidation = validatePowerupsSelected(petQuoteRateItem);
    if (!powerUpValidation.isValid) {
      dispatch(setPowerUpErrors(powerUpValidation.modifierIdNotSelectedList));
      return;
    }
    if (isGoodDog && useGoodDogTN) {
      const stateAbbr = getStateAbbreviation(customerZipCode);

      if (stateAbbr === 'TN') {
        dispatch(changeGoodDogComingSoonModal(true));
        return;
      }
    }

    dispatch(toggleYourInfoScrollToTop(true));

    // GTM
    dataLayerAddToCart();

    if (useFSCLeads) {
      dispatch(sendFSCQuoteLead());

      const {
        customerValidationResponse,
        newCustomer,
        parameters,
        partners: { partnerId, petPickupDate },
        saveQuote: { quoteId },
      } = store;
      const { groupCode, groupCodeDscr, petQuoteResponseList, zipCode } = data;
      const cloudClientId = customerValidationResponse?.id || 0;
      dispatch(
        saveQuote({
          cloudClientId,
          EffectiveDate: createEffectiveDate(),
          email: newCustomer.email || parameters.email,
          firstName: newCustomer.name || '',
          groupCode,
          groupCodeDscr,
          lastName: newCustomer.lastName || '',
          origin: window.location.origin,
          partnerId,
          petQuoteResponseList,
          pickupDate: petPickupDate,
          promoCode: store.newPet.promoCode,
          quoteId,
          quoteSelectedValues,
          QuoteSubId: store.subId,
          quoteType: SAVE_QUOTE_TYPE.auto,
          zipCode,
        }),
      );
    }
    history.push({
      pathname: URL_YOUR_INFO,
      search: queryString.stringify(utmParams),
    });
  };

  if (location.pathname.toLocaleLowerCase() === URL_YOUR_INFO) {
    return null;
  }

  return (
    <section
      ref={stickyBarRef}
      aria-label={t('priceStickyBar.priceBanner')}
      className="Price-sticky-bar-container"
    >
      <TemporaryMessage />

      <div className="Price-sticky-bar-price-inner-container">
        <div className="Price-sticky-bar-price-container">
          <span className="Price-sticky-bar-price">${quoteTotal}/mo</span>

          <span className="Price-sticky-bar-price-details">
            {t('priceStickyBar.petsIncluded', {
              count: numberOfPets,
              numberOfPets,
            })}
          </span>
        </div>

        <button
          className="Price-sticky-bar-continue-button"
          onClick={onContinueClick}
          type="button"
        >
          {t('priceStickyBar.continueButton', { nextStepLabel })}

          <img
            alt=""
            className="Price-sticky-bar-button-icon"
            src={arrowRightIcon}
          />
        </button>
      </div>
    </section>
  );
};

export { PriceStickyBar };
