import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { QuotingSteps } from '../../../constants';
import TouchableDiv from '../../common/TouchableDiv';
import { isPaycheck } from '../../../util';
import './css/QuotingHeader.css';

const QuotingStepsComponent = ({
  currentQuoteStep = 1,
  figoSmallLogoVisible = false,
}) => {
  const searchParameters =
    useSelector(({ quoting }) => quoting.searchParameters);
  const match = useRouteMatch();
  const history = useHistory();

  const handleStepClick = (stepClicked) => () => {
    if (isPaycheck
      || currentQuoteStep > QuotingSteps.YourInfo.value
    ) {
      return;
    }

    const isValidNavigation = stepClicked < currentQuoteStep;
    if (!isValidNavigation) {
      return;
    }

    let module = '';

    switch (stepClicked) {
      case QuotingSteps.SelectPlan.value:
        module = searchParameters;
        break;

      case QuotingSteps.YourInfo.value:
        module = '/yourinfo/';
        break;

      default: {
        module = '';
        break;
      }
    }

    history.push(`${match.url}${module}`);
  };

  function getAriaLabel(step) {
    const { name, value } = step;
    const selectedText = (currentQuoteStep === value)
      ? 'Selected' : ' Not Selected';

    return `Step ${value} ${name} ${selectedText}`;
  }

  const secondLineClassName = useMemo(() => {
    if (currentQuoteStep < QuotingSteps.YourInfo.value) {
      return '';
    }
    if (currentQuoteStep >= QuotingSteps.Welcome.value) {
      return 'step-progress-line-full';
    }
    return 'step-progress-line-half';
  }, [currentQuoteStep]);

  return (
    <div className={`Quoting-header-container-steps-container
    ${figoSmallLogoVisible ? 'Steps-container-fixed' : ''}`}
    >
      <TouchableDiv
        ariaProps={{
          'aria-disabled': currentQuoteStep < QuotingSteps.SelectPlan.value
            || currentQuoteStep === QuotingSteps.Welcome.value,
          'aria-label': getAriaLabel(QuotingSteps.SelectPlan),
        }}
        className="Quoting-header-step-container"
        id="stepHeaderSelectPlan"
        onClick={handleStepClick(QuotingSteps.SelectPlan.value)}
      >
        <div
          className="Quoting-header-step-circle
        Quoting-header-step-circle-completed"
        >
          <span className="Quoting-header-step-number">
            {QuotingSteps.SelectPlan.value}
          </span>
        </div>

        <span className="Quoting-header-step-text">
          {QuotingSteps.SelectPlan.name}
        </span>
      </TouchableDiv>

      <div
        className={'step-progress-line '
          + `${currentQuoteStep > QuotingSteps.SelectPlan.value
            ? 'step-progress-line-full'
            : 'step-progress-line-half'}`}
      />

      <TouchableDiv
        ariaProps={{
          'aria-disabled': currentQuoteStep < QuotingSteps.YourInfo.value
            || currentQuoteStep === QuotingSteps.Welcome.value,
          'aria-label': getAriaLabel(QuotingSteps.YourInfo),
        }}
        className="Quoting-header-step-container"
        id="stepHeaderYourInfo"
        onClick={handleStepClick(QuotingSteps.YourInfo.value)}
      >
        <div className={'Quoting-header-step-circle '
          + ` ${currentQuoteStep >= QuotingSteps.YourInfo.value
            ? 'Quoting-header-step-circle-completed'
            : ''}`}
        >
          <span className="Quoting-header-step-number">
            {QuotingSteps.YourInfo.value}
          </span>
        </div>

        <span className="Quoting-header-step-text">
          {QuotingSteps.YourInfo.name}
        </span>
      </TouchableDiv>

      <div
        className={'step-progress-line '
          + `${secondLineClassName}`}
      />

      <TouchableDiv
        ariaProps={{
          'aria-disabled': currentQuoteStep < QuotingSteps.Welcome.value,
          'aria-label': getAriaLabel(QuotingSteps.Welcome),
        }}
        className="Quoting-header-step-container"
        id="stepHeaderWelcome"
        onClick={handleStepClick(QuotingSteps.Welcome.value)}
      >
        <div className={'Quoting-header-step-circle '
          + ` ${currentQuoteStep >= QuotingSteps.Welcome.value
            ? 'Quoting-header-step-circle-completed'
            : ''}`}
        >
          <span className="Quoting-header-step-number">
            {QuotingSteps.Welcome.value}
          </span>
        </div>

        <span className="Quoting-header-step-text">
          {QuotingSteps.Welcome.name}
        </span>
      </TouchableDiv>
    </div>
  );
};

export { QuotingStepsComponent };
