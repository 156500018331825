import { useSelector } from 'react-redux';
import { useTracking } from 'react-tracking';
import { PetType } from '../constants';
import { getAgeInYears } from '../util';
import {
  baseRequest,
  customTrackEvent,
  EVENT_TYPE,
  getPetPowerups,
  getPlanLimit,
  getQuotePlanInfo,
} from './baseRequest';

const useTrackingPurchaseComplete = () => {
  const { trackEvent } = useTracking({ }, { dispatch: customTrackEvent });
  const store = useSelector(({ quoting }) => quoting);
  const {
    data,
    parameters: { groupCodeDscr, email },
    quoteSelectedValues,
    subId,
  } = store;

  function trackPurchaseComplete({ policies, effectiveDate }) {
    const pets = data.petQuoteResponseList.map((pet) => {
      const petQuoteInf = getQuotePlanInfo({
        petId: pet.petQuoteId,
        quoteSelectedValues,
      });

      const modifierList = data.insuranceProduct.InsuranceModifiers;
      const petQuotePowerups =
        getPetPowerups({ currentPet: pet, modifierList });

      const petPolicy = policies.find((policy) => policy.PetName === pet.petName
          && (policy.IsCat ? pet.petType === PetType.Cat.value
            : pet.petType === PetType.Dog.value));

      return {
        age: getAgeInYears(pet.petAgeName),
        breed: pet.breedName,
        gender: pet.genderName.toLowerCase(),
        name: pet.petName,
        policy: {
          annual_premium: petQuoteInf.annualPremium,
          deductible: petQuoteInf.deductible,
          effective_date: effectiveDate,
          extra_care: petQuotePowerups.extraCarePack,
          limit: getPlanLimit(petQuoteInf.monthlyLimit),
          monthly_premium: petQuoteInf.monthlyPremium,
          policy_number: petPolicy?.PolicyNumber,
          reimbursement: petQuoteInf.reimbursement,
          vet_fees: petQuotePowerups.vetFees,
          wellness: petQuotePowerups.wellness,
        },
        species: pet.petType === PetType.Dog.value ? 'dog' : 'cat',
        zip: data.zipCode,
      };
    });

    trackEvent({
      ...baseRequest({
        codeMask: groupCodeDscr,
        email,
        promoCode: data.groupCode || '',
        subId: subId.Value,
      }),
      event: EVENT_TYPE.PURCHASE_COMPLETE,
      pets,
    });
  }

  return { trackPurchaseComplete };
};

export { useTrackingPurchaseComplete };
