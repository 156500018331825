import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArrowDown from '../../assets/arrow-down-deep-blue-main.svg';
import TouchableDiv from './TouchableDiv';
import Shimmer from './Shimmer';
import ScrollableDiv from './ScrollableDiv';
import {
  saveNewPetBreed,
  saveNewPetAge,
} from '../../actions/quoting';
import { removeEmojis } from '../../util';

import './DropdownAddPet.css';

const SPACE_KEY = 32;
const DOWN_KEY = 40;
const LEFT_KEY = 37;
const RIGHT_KEY = 39;
const UP_KEY = 38;
const INPUT_NODE = 'INPUT';

function handleKeyDown(event) {
  const ignoredKeys = [DOWN_KEY, LEFT_KEY, RIGHT_KEY,
    UP_KEY];
  if (event.target.nodeName !== INPUT_NODE) {
    ignoredKeys.push(SPACE_KEY);
  }
  if (ignoredKeys.indexOf(event.keyCode) > -1) {
    event.preventDefault();
  }
}

const DropdownAddPet = ({
  isLanding,
  items = [],
  petAgeRef = null,
  petBreedRef = null,
  placeholder = '',
  requiredError,
  showShimmer,
  title,
  type = '',
  withInput = false,
}) => {
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [error, setError] = useState(false);
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const [itemSelectedId, setItemSelectedId] = useState(0);
  const store = useSelector((stores) => stores.quoting);
  const dispatch = useDispatch();
  const searchRef = useRef(null);
  const dropdownHeaderRef = petAgeRef || petBreedRef;
  const selectedItemRef = useRef(null);
  const containerItemRef = useRef(null);
  const showError = error || requiredError;
  const id = title.replace(/ /g, '-');

  const currentSelection = useMemo(() => {
    if (type === 'breed') {
      return store.newPet.petBreed || 'None';
    }
    return store.newPet.petAge || 'None';
  }, [store.newPet.petBreed, store.newPet.petAge, type]);

  useEffect(() => {
    setItemsFiltered(items);
  }, [items]);

  function setActiveDescendant(currentRef) {
    if (currentRef && selectedItemRef.current) {
      const selectedItemId = selectedItemRef.current.getAttribute('id');
      currentRef.setAttribute('aria-activedescendant', selectedItemId);
    }
  }

  useEffect(() => {
    const { current: dropdownCurrent } = dropdownHeaderRef;
    const { current: inputCurrent } = searchRef;
    if (open) {
      if (inputCurrent) {
        inputCurrent.focus();
        setActiveDescendant(inputCurrent);
      }

      window.addEventListener('keydown', handleKeyDown, true);
    }

    return () => {
      if (open) {
        window.removeEventListener('keydown', handleKeyDown, true);
        dropdownCurrent?.focus();
      }
    };
  }, [dropdownHeaderRef, searchRef, open]);

  useEffect(() => {
    if (withInput) {
      setSearchInput('');
    }
  }, [withInput, store.newPet.petType]);

  function scrollItemListToView() {
    if (selectedItemRef.current) {
      containerItemRef.current.scrollTo(
        0, selectedItemRef.current.offsetTop - 5,
      );
    }
  }

  useEffect(() => {
    scrollItemListToView();
    setActiveDescendant(!withInput
      ? dropdownHeaderRef.current
      : searchRef.current);
  }, [dropdownHeaderRef, withInput, itemSelectedId]);

  async function filterItems(inputSearch) {
    if (type === 'age') {
      const ageDataFiltered =
        items.filter((element) => element.Description
          .toUpperCase().includes(inputSearch.toUpperCase()));

      setItemsFiltered(ageDataFiltered);

      if (ageDataFiltered[0]) {
        await setItemSelectedId(ageDataFiltered[0].Id);
        containerItemRef.current?.scrollTo(0, 0);
        scrollItemListToView();
      }
    } else {
      const breedDataFiltered =
        items.filter((element) => element.BreedName
          .toUpperCase().includes(inputSearch.toUpperCase()));

      setItemsFiltered(breedDataFiltered);

      if (breedDataFiltered[0]) {
        await setItemSelectedId(breedDataFiltered[0].Id);
        containerItemRef.current?.scrollTo(0, 0);
        scrollItemListToView();
      }
    }
  }

  function toggle() {
    setOpen(!open);
  }

  function handleDropDownContainer(event) {
    if (event.target.id === `addPetDropdownContainer${id}`
      || event.target.id === `${id}SearchInput`) {
      toggle();
    }
  }

  const handleOnClickItem = (item) => (event) => {
    setError(false);

    if (event && event.type === 'click') {
      toggle();
    }

    if (type === 'age') {
      dispatch(saveNewPetAge(item.Description));
      setError(item.Id === 1);
    } else {
      setSearchInput(item.BreedName);

      dispatch(saveNewPetBreed({
        petBreed: item.BreedName,
        petBreedId: item.Id,
      }));
    }
  };

  const handleOnKeyPressItem = (item) => (event) => {
    const { key } = event;
    if (key === 'Enter' || key === ' ') {
      handleOnClickItem(item)();
    }
  };

  function handleSearchInputChanged(event) {
    const cleanValue = removeEmojis(event.target.value);
    setSearchInput(cleanValue);
    if (withInput) {
      filterItems(cleanValue.trim());
      scrollItemListToView();
    }
  }

  const handleArrowKeyPressed = () => (event) => {
    const { keyCode } = event;
    const ENTER_KEY = 13;
    const ESC_KEY = 27;
    const TAB_KEY = 9;

    switch (keyCode) {
      case UP_KEY:
      case DOWN_KEY: {
        const factor = keyCode === UP_KEY ? -1 : 1;
        const temp = itemsFiltered
          .findIndex((element) => element.Id === itemSelectedId);
        if (itemsFiltered[temp + factor]) {
          const newId = itemsFiltered[temp + factor].Id;
          setItemSelectedId(newId);
        }

        break;
      }

      case ENTER_KEY: {
        const item = itemsFiltered
          .find((element) => element.Id === itemSelectedId);

        if (item && open) {
          handleOnClickItem(item)();
          if (withInput) {
            toggle();
          }
        }

        break;
      }

      case ESC_KEY: {
        setOpen(false);
        if (dropdownHeaderRef.current) {
          dropdownHeaderRef.current.focus();
        }

        break;
      }

      case TAB_KEY: {
        setOpen(false);

        break;
      }

      default: {
        break;
      }
    }
  };

  function handleOutsideClick() {
    setOpen(false);
  }

  function handleInputFocus() {
    setOpen(true);
  }

  function renderSearchInput({ className = '', valueToShow }) {
    const PLACEHOLDERS = ['Required', 'Start typing to search...'];
    const isPlaceHolder = PLACEHOLDERS.includes(valueToShow);

    return (
      <input
        ref={searchRef}
        aria-activedescendant=""
        aria-autocomplete="list"
        aria-controls="dd-options"
        aria-describedby="progressQueueId"
        aria-expanded={open}
        aria-haspopup="listbox"
        aria-invalid={requiredError}
        aria-labelledby={`label${id}`}
        autoComplete="off"
        className={`${className} Dropdown-search-input`}
        id={`${id}SearchInput`}
        onChange={handleSearchInputChanged}
        onFocus={handleInputFocus}
        onKeyDown={handleArrowKeyPressed()}
        placeholder={`${open ? 'Search' : placeholder}`}
        role="combobox"
        style={{ color: isPlaceHolder ? '#6b7078' : '#01313d' }}
        value={valueToShow || searchInput}
      />
    );
  }

  function renderHeaderDropdown() {
    if (!open) {
      let className = '';
      let valueToShow = title;
      if (type === 'age') {
        className = store.newPet.petAge
          ? 'Dropdown-selected-item-text'
          : 'Dropdown-selected-item-placeholder';
        valueToShow = store.newPet.petAge || placeholder;
      } else {
        className = store.newPet.petBreed
          ? 'Dropdown-selected-item-text'
          : 'Dropdown-selected-item-placeholder';
        valueToShow = store.newPet.petBreed || placeholder;
      }

      if (withInput) {
        return renderSearchInput({ className, valueToShow });
      }

      return <p className={className}>{valueToShow}</p>;
    }

    if (withInput) {
      return renderSearchInput({ className: '' });
    }

    return (
      <p>{store.newPet.petAge || title}</p>
    );
  }

  const isSelected = (item) => {
    if (withInput) {
      return item.BreedName === store.newPet.petBreed;
    }
    return item.Description === store.newPet.petAge;
  };

  const ItemList = ({ item }) => (
    <li
      ref={item.Id === itemSelectedId ? selectedItemRef : null}
      aria-selected={isSelected(item)}
      aria-setsize={itemsFiltered.length}
      className="Dropdown-list-item-container"
      id={`dropdownItem${item.Id}`}
      onClick={handleOnClickItem(item)}
      onKeyDown={handleArrowKeyPressed()}
      onKeyPress={handleOnKeyPressItem(item)}
      role="option"
      tabIndex={-1}
    >
      <span
        className={`Dropdown-list-item ${item.Id === itemSelectedId
          && 'Dropdown-list-item-selected'}`}
        tabIndex={-1}
      >
        {item.BreedName || item.Description}
      </span>
    </li>
  );

  const ItemListNotFoundText = () => {
    let notFoundText = 'Getting breed list';

    if (!showShimmer) {
      notFoundText = store.newPet.petType.value >= 0 ? 'No matches found'
        : 'Please select Pet Type to view breeds';
    }

    return (
      <Shimmer element="span" visible={showShimmer}>
        {notFoundText}
      </Shimmer>
    );
  };

  const ItemListNotFound = () => (
    <li key={0} className="Dropdown-list-item-container">
      <span className="Dropdown-list-item">
        <ItemListNotFoundText />
      </span>
    </li>
  );

  function renderItems() {
    if (!open) {
      return null;
    }

    const resultsNotFound = !itemsFiltered || itemsFiltered.length === 0;

    return (
      <ScrollableDiv
        ref={containerItemRef}
        className={`Dropdown-list ${!open ? 'Dropdown-list-hidden' : ''}`}
      >
        <ul
          aria-labelledby={`addPetDropdownContainer${id}`}
          aria-live={resultsNotFound ? 'polite' : 'off'}
          id="dd-options"
          role="listbox"
        >
          {resultsNotFound ? <ItemListNotFound />
            : itemsFiltered
              .map((item) => (
                <ItemList key={`${item.BreedName}${item.Id}`} item={item} />
              ))}
        </ul>
      </ScrollableDiv>
    );
  }

  function renderErrorMessage() {
    let textError = '';
    if (showError) {
      textError = requiredError
        ? `${title} is required`
        : 'Sorry, Figo does not insure pets'
        + ' under 8 weeks old. Check back in with us soon!';
    }

    return (
      <span aria-live="polite" className="Dropdown-header-error-message">
        {textError}
      </span>
    );
  }

  function getAriaPropsForDiv() {
    const ariaProps = {
      'aria-labelledby': `label${id}`,
      title: `${currentSelection} selected`,
    };
    if (!withInput) {
      return {
        ...ariaProps,
        'aria-activedescendant': '',
        'aria-controls': 'dd-options',
        'aria-haspopup': 'listbox',
        role: 'combobox',
      };
    }
    return ariaProps;
  }

  function renderOverlayComponent() {
    if (open) {
      return (
        <TouchableDiv
          ariaProps={{ 'aria-hidden': true }}
          className="Dropdown-list-overlayed-container"
          id="dropdownAddPet"
          onClick={handleOutsideClick}
          tabIndex={0}
        />
      );
    }

    return null;
  }

  function renderContainer({ children }) {
    const classNameShimmer = `Dropdown-header
    ${showError && 'Dropdown-header-error'}`;

    if (withInput) {
      return (
        <Shimmer
          ariaProps={getAriaPropsForDiv()}
          className={`${classNameShimmer} Input-container`}
          compRef={dropdownHeaderRef}
          id={`addPetDropdownContainer${id}`}
          visible={showShimmer}
        >
          {children}
        </Shimmer>
      );
    }

    return (
      <Shimmer
        ariaProps={getAriaPropsForDiv()}
        className={`${classNameShimmer}`}
        compRef={dropdownHeaderRef}
        element="touchableDiv"
        id={`addPetDropdownContainer${id}`}
        onClick={handleDropDownContainer}
        onKeyDown={handleArrowKeyPressed()}
        tabIndex={0}
        visible={showShimmer}
      >
        {children}
      </Shimmer>
    );
  }

  return (
    <div className={`Dropdown-container ${(showError || requiredError)
      && 'Dropdown-container-error Dropdown-container-error-safari'}`
      + ` ${isLanding ? ' Dropdown-container-landing' : ''} Add-pet-form-label`}
    >
      <div>
        <span>{title}</span>
      </div>

      {renderOverlayComponent()}

      <label
        aria-label={`${title} selector`}
        className="Add-pet-form-label-text Add-pet-form-label-hide"
        htmlFor={`addPetDropdownContainer${id}`}
        id={`label${id}`}
      >
        {placeholder}
      </label>

      {renderContainer({
        children: (
          <>
            <div className="Dropdown-selected-item">
              {renderHeaderDropdown()}
            </div>

            <div
              className="Dropdown-arrow-container No-clicked"
            >
              <img
                alt=""
                aria-hidden="true"
                className={'Dropdown-arrow-closed '
                  + `${open ? 'Dropdown-arrow-open' : ''}`}
                src={ArrowDown}
                style={{ bottom: showError ? '' : '20%' }}
              />
            </div>
          </>
        ),
      })}

      {renderItems()}

      {renderErrorMessage()}
    </div>
  );
};

export { DropdownAddPet };
