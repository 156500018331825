import React from 'react';
import { createRoot } from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';

import { msalConfig } from './authConfigB2C';

import './index.css';
import './common.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import { SavedElementProvider } from './hooks/useSavedElement';
import { store } from './store';
import StorageProvider from './components/StorageProvider';

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <SavedElementProvider>
          <StorageProvider />

          <Router basename="/">
            <App />
          </Router>
        </SavedElementProvider>
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
