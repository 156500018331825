import { doNothing } from '../util';
import {
  COSTCO_CX_PARTNER_ID,
  COSTCO_PARTNER_ID,
  COSTCO_PARTNER_IDS,
  GOOD_DOG_PARTNER_ID,
  PARTNERS,
  QUOTE_CLIENT_TYPES,
  USAA_PARTNER_ID,
} from './partners.constants';

function dateAddDays(addDay = 1) {
  const date = new Date();
  date.setDate(date.getDate() + addDay);
  date.setHours(23);
  date.setMinutes(59);

  return date;
}

export const isPartnersCostco = (
  partnerId,
) => COSTCO_PARTNER_IDS.includes(partnerId);

export const isPartnersGoodDog = (
  partnerId,
) => GOOD_DOG_PARTNER_ID === partnerId;

export const isPartnersUSAA = (
  partnerId,
) => USAA_PARTNER_ID === partnerId;

export const getPartnerData = (partnerId) => {
  switch (partnerId) {
    case USAA_PARTNER_ID:
      return PARTNERS.usaa;
    case GOOD_DOG_PARTNER_ID:
      return PARTNERS.goodDog;
    case COSTCO_CX_PARTNER_ID:
      return PARTNERS.costcoCx;
    case COSTCO_PARTNER_ID:
      return PARTNERS.costco;
    default:
      return null;
  }
};

/**
 * @param {string} param.partnerId
 * @returns {string} Client Name
 */
export const getQuoteClientType = ({ partnerId }) => {
  const partnerData = getPartnerData(partnerId);
  if (partnerData) {
    return partnerData.name;
  }
  return QUOTE_CLIENT_TYPES.d2c;
};

export function pickupDateMin() {
  const date = new Date();

  return date.toISOString().split('T')[0];
}

export function validatePickupDate(pickupDate) {
  if (!pickupDate) {
    return true;
  }

  let isValid = false;

  try {
    const minEffectiveDateTime = new Date().getTime();
    const pickupDateTime = new Date(`${pickupDate} 23:59`).getTime();

    isValid = pickupDateTime >= minEffectiveDateTime;
  } catch (_) {
    doNothing();
  }

  return isValid;
}

export function validatePickupDate7Days(pickupDate) {
  if (!pickupDate) {
    return true;
  }

  let isValid = false;

  try {
    const minEffectiveDateTime = dateAddDays(7).getTime();
    const pickupDateTime = new Date(`${pickupDate} 01:00`).getTime();

    isValid = pickupDateTime <= minEffectiveDateTime;
  } catch (_) {
    doNothing();
  }

  return isValid;
}
