import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import discountTag from '../../assets/landing/discount-tag.svg';
import { TooltipDiscount } from './TooltipDiscount';

import './PartnerDiscountLabel.css';
import Shimmer from '../common/Shimmer';
import { HtmlContent } from '../common';

const Label = ({ discountLabel }) => (
  <HtmlContent
    classNameContainer="Flex"
    content={discountLabel}
    elementId="CostcoLabel"
    elementType="span"
  />
);

const PartnerDiscountLabel = ({ discountLabel }) => {
  const store = useSelector((stores) => stores.quoting);
  const { t } = useTranslation('quoting');

  const { data, quoteSelectedValues } = store;
  const { stateAbrv } = data || {};

  const partnerAnnualSavings = quoteSelectedValues
    .map((item) => item.partnerAnnualSaving).filter((item) => !!item);

  const discountTotal = partnerAnnualSavings
    .reduce((prev, next) => prev + next, 0);

  const showDecimals = discountTotal % 1 > 0;

  return (
    <div className="Partner-discount-label">
      <div className="Partner-discount-label-container">
        <div className="Partner-discount-label-message">
          {stateAbrv && stateAbrv !== 'TN' && (
            <>
              <img
                alt=""
                className="Partner-discount-label-tag-icon"
                src={discountTag}
              />

              <Label discountLabel={discountLabel} />
            </>
          )}

          {stateAbrv && stateAbrv === 'TN' && (
            <span>{t('costcoTNDiscount')}</span>
          )}
        </div>

        <Shimmer
          className="Partner-discount-label-estimated-div"
          visible={!stateAbrv}
        >
          <div className="Partner-discount-label-estimated">
            <TooltipDiscount />

            <span>Estimated Savings:</span>
          </div>

          <div className="Partner-discount-label-badge">
            <span>
              ${showDecimals ? discountTotal.toFixed(2) : discountTotal}/yr.
            </span>
          </div>
        </Shimmer>
      </div>
    </div>
  );
};

export { PartnerDiscountLabel };
