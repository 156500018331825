import React from 'react';
import ModalContainer from './ModalContainer';
import closeIconCustomModal from '../../assets/close-custom-modal.svg';
import './CustomModal.css';

const CustomModal = ({
  buttonText,
  children,
  description,
  disableClickOutside,
  displayButton,
  handleOutClick,
  id,
  imageSrc,
  imgAlt,
  onButtonClick,
  title,
  visible,
}) => (
  <ModalContainer
    closeIconButton={closeIconCustomModal}
    disableClickOutside={disableClickOutside}
    handleOutClick={handleOutClick}
    show={visible}
  >
    <div className="Flex Column Custom-modal-container" id={id}>
      <img
        alt={imgAlt}
        className="Custom-modal-image-container"
        src={imageSrc}
      />

      <span className="Custom-modal-title">{title}</span>

      <p className="Custom-modal-description-text">
        {description}
      </p>

      {children}

      {displayButton && (
        <button
          aria-label="Close modal"
          className="Custom-modal-button"
          onClick={onButtonClick}
          type="button"
        >
          <span className="Custom-modal-button-text">
            {buttonText}
          </span>
        </button>
      )}
    </div>
  </ModalContainer>
);

export { CustomModal };
