import React, { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import {
  DEFAULT_STICKY_BAR_HEIGHT,
  STICKY_BAR_HEIGHT_SECOND_STEP,
  YOUR_INFO_STEPS,
} from '../../constants';
import { URL_ROOT_QUOTE, URL_YOUR_INFO } from '../../routes';
import {
  useSessionFooter,
  usePartnerNotes,
  useHtmlInjection,
} from '../../hooks';
import { usePartnersData } from '../../partners/usePartnersData';
import { useStickyBarHeight } from '../landing/StickyBarHeightProvider';

import './QuotingFooter.css';
import { FooterLinks } from '../common/FooterLinks';

const QuotingFooter = ({
  classContainer = '',
  customFooterText = '',
}) => {
  const store = useSelector(({ quoting }) => ({ quoting }), shallowEqual);
  const { quoting: { yourInfoStep } } = store;
  const notesRef = useRef();
  const footerRef = useRef();
  const sessionFooter = useSessionFooter();
  const [sup1] = usePartnerNotes();
  const { stickyBarHeight } = useStickyBarHeight();
  const { isGoodDog } = usePartnersData();

  useHtmlInjection({
    classNameContainer: 'Quoting-footer-text',
    content: sup1?.Content || '',
    ref: notesRef,
  });

  useHtmlInjection({
    classNameContainer: 'Quoting-footer-text',
    content: customFooterText || sessionFooter,
    elementType: 'p',
    ref: footerRef,
  });

  const location = useLocation();
  const currentLocation = location.pathname.toLocaleLowerCase();
  const marginList = [URL_ROOT_QUOTE];
  const containerStyle = marginList.indexOf(currentLocation) >= 0
    ? 'Quoting-footer-extra-margin' : '';

  const footerClassName = isGoodDog
    ? 'Landing-footer-good-dog' : 'Landing-footer';

  const purchaseStickyBarHeight = useMemo(() => {
    const isYourInfo = currentLocation === URL_YOUR_INFO;
    const isSecondStep = yourInfoStep === YOUR_INFO_STEPS.secondStep;

    if (isYourInfo && isSecondStep) {
      return STICKY_BAR_HEIGHT_SECOND_STEP;
    }

    return DEFAULT_STICKY_BAR_HEIGHT;
  }, [currentLocation, yourInfoStep]);

  return (
    <footer
      className={`Quoting-footer-container ${containerStyle} ${classContainer}`}
      style={{ marginBottom: stickyBarHeight + purchaseStickyBarHeight }}
    >
      <div className="Quoting-footer-content">
        <p className="Quoting-footer-text-header">
          {`Copyright © 2015 – ${new Date().getFullYear()}
           Figo Pet Insurance LLC. All rights reserved.`}
        </p>

        <FooterLinks className="Quoting-footer-text-spacing" />

        <div ref={notesRef} />

        <div ref={footerRef} className={footerClassName} />
      </div>
    </footer>
  );
};

export default QuotingFooter;
