import React, { useEffect } from 'react';
import ModalContainer from './ModalContainer';
import loadingBadge from '../../assets/badge-loading.json';
import { useSavedElement } from '../../hooks/useSavedElement';
import FigoLottie from './FigoLottie';
import './FigoLoadingOverlay.css';

const FigoLoadingOverlay = ({ visible }) => {
  const { goToSavedElementAndForgot } = useSavedElement();

  useEffect(() => () => {
    if (visible) {
      goToSavedElementAndForgot();
    }
  }, [visible, goToSavedElementAndForgot]);

  return (
    <ModalContainer cancelable={false} idPrefix="loading" show={visible}>
      <div className="Loading-overlay-container">
        <FigoLottie
          animationData={loadingBadge}
          ariaLabel="Loading"
          ariaRole="alert"
          height={190}
          width={250}
        />
      </div>
    </ModalContainer>
  );
};

export default FigoLoadingOverlay;
