import React from 'react';

const SpecieSelectorItem = ({
  handleSpecieSelected = () => { },
  isSelected = false,
  label = '',
  petIcon = null,
  petInactiveIcon = null,
  petSpeciesRef = null,
}) => {
  function specieIcon() {
    if (isSelected) {
      return petIcon;
    }

    return petInactiveIcon;
  }

  return (
    <button
      ref={petSpeciesRef}
      aria-describedby="progressQueueId"
      aria-label={`Pet type ${label} ${isSelected ? 'selected' : 'unselected'}`}
      aria-live={isSelected ? 'assertive' : 'off'}
      className={`Modal-add-pet-specie-item-container${
        isSelected ? ' Modal-add-pet-specie-item-container-selected' : ''}`}
      onClick={handleSpecieSelected}
      type="button"
    >
      <img
        alt=""
        className="Modal-add-pet-specie-icon"
        src={specieIcon()}
      />

      {label}
    </button>
  );
};

export { SpecieSelectorItem };
