import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { recalculateTotalPrices } from '../../../actions';

import infoIcon from '../../../assets/plans/info.svg';
import IconTooltip, { TOOL_TIP_POSITION } from '../../common/IconTooltip';

import { SelectorPill } from './SelectorPill';
import './SelectorPills.css';

// WIP: FPA-12516 this POC needs PO/client approval
export const SelectorPills = ({
  items = [{ disabled: false, item: {}, value: '' }],
  onChange = () => { },
  position = TOOL_TIP_POSITION.start,
  title,
  tooltipDescription,
  value,
}) => {
  const dispatch = useDispatch();

  const onOptionClick = useCallback((item) => {
    if (item.disabled) {
      return;
    }

    onChange(item);
    dispatch(recalculateTotalPrices());
  }, [dispatch, onChange]);

  const titleForId = title.replace(/\s/g, '-');

  return (
    <fieldset className="Selector-pills-container">
      <span className="Selector-pills-title" id={`${titleForId}-label`}>
        <legend>{title}</legend>

        {tooltipDescription && (
          <IconTooltip
            backgroundColor="var(--citron-shade-3)"
            description={tooltipDescription}
            icon={infoIcon}
            name={title}
            position={position}
          />
        )}
      </span>

      <div className="Selector-pills-options">
        {items.map((item) => (
          <SelectorPill
            key={item.value}
            checked={value.value === item.id}
            disabled={item.disabled}
            itemExtraData={item}
            label={item.value}
            name={title.replace(' ', '')}
            onChange={onOptionClick}
            value={item.id}
          />
        ))}
      </div>
    </fieldset>
  );
};
