import { setSessionStorage } from '../util';

const KEY_IS_OPEN_QUOTE = 'KEY_IS_OPEN_QUOTE';

export function setIsOpenQuote(value) {
  setSessionStorage({ data: value, key: KEY_IS_OPEN_QUOTE });
}

export function isOpenQuote() {
  return !!sessionStorage.getItem(KEY_IS_OPEN_QUOTE);
}
