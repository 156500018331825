import React from 'react';

import dogIcon from '../../../assets/dog-active.svg';
import dogInactiveIcon from '../../../assets/dog-inactive.svg';
import catIcon from '../../../assets/cat-active.svg';
import catInactiveIcon from '../../../assets/cat-inactive.svg';

import { SpecieSelectorItem } from './SpecieSelectorItem';

const AddPetSpecieSelector = ({
  catSelected = {},
  dogSelected = {},
  dogSpeciesRef = null,
  error = '',
  handleCatSelected = () => { },
  handleDogSelected = () => { },
  InputError = () => { },
}) => (
  <div className="Modal-add-pet-species-wrapper">
    <span className="Modal-add-pet-species-title">Pet Type</span>

    <div className="Modal-add-pet-container-relative">
      <div className="Modal-add-pet-species-container">
        <SpecieSelectorItem
          handleSpecieSelected={handleDogSelected}
          isSelected={dogSelected.selected}
          label="Dog"
          petIcon={dogIcon}
          petInactiveIcon={dogInactiveIcon}
          petSpeciesRef={dogSpeciesRef}
        />

        <SpecieSelectorItem
          handleSpecieSelected={handleCatSelected}
          isSelected={catSelected.selected}
          label="Cat"
          petIcon={catIcon}
          petInactiveIcon={catInactiveIcon}
        />
      </div>

      <InputError
        className="Modal-add-pet-align-self-start"
        error={error}
      />
    </div>
  </div>
);

export { AddPetSpecieSelector };
