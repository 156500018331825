import React, { useMemo } from 'react';

import { currencyFormat, useCustomPlanPills } from '../../../util';
import { CUSTOM_PLAN_ID } from './constants';

import './PrepackagedPlanCard.css';
import { DeductibleReimbursement } from '../customPlanSection';

const PrepackagedPlanCard = ({
  activeIcon,
  billingCycleLabel = '',
  bottomDisclaimer = '',
  description = '',
  inactiveIcon,
  isEssentialSelected = false,
  maxAnnual,
  name = '',
  onPlanChange = () => { },
  planId = CUSTOM_PLAN_ID,
  planPackagedId,
  plans = [],
  price = 0,
  quoteSelected,
  selected = false,
}) => {
  const isCustom = planPackagedId === CUSTOM_PLAN_ID;

  const priceParts = useMemo(() => {
    const [integer, decimal] = currencyFormat({ value: price })
      .split('.');

    return {
      decimal,
      integer: parseInt(integer, 10),
      total: price,
    };
  }, [price]);

  function handleClick() {
    onPlanChange({
      planAmount: maxAnnual || quoteSelected.selectedPlanAmount,
      planId: isCustom ? quoteSelected.selectedPlan : planId,
      planPackagedId: planPackagedId || CUSTOM_PLAN_ID,
    });
  }

  const ariaPlanLabel = useMemo(() => {
    if (!priceParts.integer) {
      return '';
    }

    if (isCustom && !selected) {
      return `${name} plan card not selected`;
    }

    const billingCycleLecture = billingCycleLabel.replace('/', '');

    const label = `${name} plan card `
      + `${selected ? 'selected' : 'not selected'}. `
      + `${description} `
      + `$${priceParts.integer}.${priceParts.decimal} `
      + `per ${billingCycleLecture}`;

    return label;
  }, [billingCycleLabel, description, isCustom, name, priceParts, selected]);

  return (
    <div className="Prepackaged-plan-card" data-checked={selected}>
      <button
        aria-label={ariaPlanLabel}
        className="Like-span"
        id={`plan${planPackagedId || planId}Container`}
        onClick={handleClick}
        type="button"
      >
        <div className="Prepackaged-plan-data">
          <div className="Prepackaged-plan-name">
            <img alt="" src={selected ? activeIcon : inactiveIcon} />

            <span>{name}</span>
          </div>

          {description && (
            <p className="Prepackaged-plan-desc">
              {description}
            </p>
          )}
        </div>

        {(!isCustom || (isCustom && selected)) && (
          <div className="Prepackaged-plan-price">
            <span className="Prepackaged-plan-super">$</span>

            <span>{priceParts.integer}</span>

            <span className="Prepackaged-plan-super">
              .{priceParts.decimal}
            </span>

            <span className="Prepackaged-plan-sub">{billingCycleLabel}</span>
          </div>
        )}
      </button>

      {useCustomPlanPills && isCustom && selected && (
        <DeductibleReimbursement
          containerClass={bottomDisclaimer ? '' : 'Margin-bottom-24'}
          isEssentialSelected={isEssentialSelected}
          plans={plans}
          quoteSelected={quoteSelected}
        />
      )}

      {useCustomPlanPills && isCustom && selected && bottomDisclaimer && (
        <span className="Prepackaged-bottom-disclaimer">
          {bottomDisclaimer}
        </span>
      )}
    </div>
  );
};

export { PrepackagedPlanCard };
