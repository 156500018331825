import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { getStateAbbreviation } from '../utils/zipcode';
import { extoleQuoteEvent, extolePurchaseEvent } from '../util';
import { PetType } from '../constants';

const useExtole = () => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);

  const {
    nopCommerceUser: { FirstName, LastName },
    userEmail,
  } = store.sessionInformation;
  const { email } = store.parameters;
  const { name, lastName } = store.newCustomer;
  const { customerId, policyNumbers } = store.purchase;

  const firstPet = useMemo(() => {
    if (!store.data) {
      return {};
    }
    const { petQuoteResponseList, zipCode } = store.data;
    const {
      petName,
      petType,
      genderName,
      petAgeName,
      breedName,
      planName,
      promoCode,
      QuoteId,
    } = petQuoteResponseList[0];

    return {
      breedName,
      genderName,
      petAgeName,
      petName,
      petType,
      planName,
      promoCode,
      QuoteId,
      zipCode,
    };
  }, [store.data]);

  const sendQuoteSubmittedEvent = () => {
    if (!store.data) {
      return;
    }

    extoleQuoteEvent({
      email: userEmail || email,
      petAge: firstPet.petAgeName,
      petBreed: firstPet.breedName,
      petName: firstPet.petName,
      petSex: firstPet.genderName,
      petType: firstPet.petType === PetType.Dog.value
        ? PetType.Dog.name : PetType.Cat.name,
      promoCode: firstPet.promoCode,
      quoteId: firstPet.QuoteId,
      state: getStateAbbreviation(String(firstPet.zipCode)),
      zipcode: firstPet.zipCode,
    });
  };

  const sendPolicyPurchaseEvent = () => {
    if (!store.data && store.purchase.success) {
      return;
    }

    extolePurchaseEvent({
      cartValue: '',
      email: userEmail || email,
      firstName: name || FirstName,
      lastName: lastName || LastName,
      membershipType: firstPet.planName,
      partnerUserId: customerId,
      policyId: policyNumbers.length > 1 ? policyNumbers : policyNumbers[0],
      state: getStateAbbreviation(String(firstPet.zipCode)),
    });
  };

  return { sendPolicyPurchaseEvent, sendQuoteSubmittedEvent };
};

export { useExtole };
