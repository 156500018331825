import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  useOneIncScript,
  usePurchase,
  useRateSummary,
  useResponsive,
} from '../../../hooks';

import PurchasePage from './PurchasePage';
import { OneIncModal } from '../../common/OneIncModal';
import { QuestionsInfo } from '../QuestionsInfo';
import FigoLoadingOverlay from '../../common/FigoLoadingOverlay';

import dogIcon from '../../../assets/dog-selected.svg';
import catIcon from '../../../assets/cat-selected.svg';
import backIcon from '../../../assets/purchase/back.svg';

import { BILLING_CYCLE_TYPE, PetType } from '../../../constants';
import { currencyFormat, useRetryPurchase } from '../../../util';
import { resetOneInInformation } from '../../../actions/oneInc';
import { URL_PURCHASE, URL_PURCHASE_ERROR } from '../../../routes';

import './PurchaseRetryScreen.css';

const PurchaseButtons = ({
  loading,
  onReturnSummary,
  t,
  doPurchase,
}) => (
  <>
    <button
      className="Purchase-retry-button"
      disabled={loading}
      onClick={doPurchase}
      type="button"
    >
      {t('retryPurchase.retryButton')}
    </button>

    <button
      className="Purchase-retry-button-return"
      disabled={loading}
      onClick={onReturnSummary}
      type="button"
    >
      <img alt="" aria-hidden src={backIcon} />
      {t('retryPurchase.returnButton')}
    </button>
  </>
);

export const PurchaseRetryScreen = () => {
  const { t } = useTranslation('purchaseResult');
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector(({ quoting }) => quoting);
  const { isMobile } = useResponsive();

  const { getSelectedRate } = useRateSummary();
  const { doPurchase } = usePurchase();

  const {
    isMonthlyPlan,
    paymentMethod: { paymentMethodType },
    purchase,
    rateSummary: { loading, PetQuotes, QuoteSummaries },
  } = store;

  useOneIncScript();

  const onReturnSummary = () => {
    history.goBack();
  };

  const totalToday = useMemo(() => {
    if (loading) {
      return '$0.00';
    }

    const billingCycle = isMonthlyPlan
      ? BILLING_CYCLE_TYPE.monthly : BILLING_CYCLE_TYPE.annualy;
    const paymentType = paymentMethodType.value;
    const summary = QuoteSummaries?.find(
      (item) => item.BillingCycle === billingCycle
        && item.PaymentType === paymentType,
    ) || { TodaysTotal: 0 };

    return `$${currencyFormat({ value: summary.TodaysTotal })}`;
  }, [QuoteSummaries, isMonthlyPlan, loading, paymentMethodType.value]);

  useLayoutEffect(() => {
    getSelectedRate();
  }, [getSelectedRate]);

  useEffect(() => {
    if (purchase.success) {
      const goToPurchaseScreen = async () => {
        const url = purchase.incompletePurchases?.length > 0
          ? URL_PURCHASE_ERROR : URL_PURCHASE;

        await dispatch(resetOneInInformation());
        history.push(url);
      };

      goToPurchaseScreen();
    }
  }, [
    dispatch,
    history,
    purchase.incompletePurchases,
    purchase.success,
  ]);

  const handlePurchase = useCallback(() => {
    doPurchase({ isRetryPurchase: true });
  }, [doPurchase]);

  if (!useRetryPurchase) {
    return null;
  }

  return (
    <PurchasePage>
      {/* OneInc modal create here */}
      <div id="portalOneContainer" />

      <FigoLoadingOverlay visible={loading || purchase.loading} />

      <div className="Purchase-retry-cards-container">
        <div className="Purchase-retry-container Purchase-retry-card">
          <h1>{t('retryPurchase.title')}</h1>

          <p className="Purchase-retry-summary-p">
            {t('retryPurchase.summary')}
          </p>

          <OneIncModal styleUpdate />

          {!isMobile && (
            <PurchaseButtons
              doPurchase={handlePurchase}
              loading={loading || purchase.loading}
              onReturnSummary={onReturnSummary}
              t={t}
            />
          )}
        </div>

        <div className="Purchase-retry-container-right Purchase-retry-card">
          <h3>{t('retryPurchase.purchaseSummary')}</h3>

          <div className="Purchase-retry-container-mobile">
            <ul>
              {!loading && PetQuotes.map((pet) => (
                <li key={String(pet.PetQuoteId)}>
                  <img
                    alt=""
                    src={pet.PetType === PetType.Cat.value ? catIcon : dogIcon}
                  />

                  <div className="Column">
                    <span className="Purchase-retry-pet-name">
                      {pet.PetName}
                    </span>

                    <span className="Purchase-retry-pet-price">
                      {t('retryPurchase.petPrice',
                        { monthlyPrice: pet.MonthlyPremium.toFixed(2) })}
                    </span>
                  </div>
                </li>
              ))}
            </ul>

            <div className="Purchase-retry-today-total-div">
              <span>
                {t('retryPurchase.totalToday')}
              </span>

              <span className="Purchase-retry-today-total">
                {totalToday}
              </span>
            </div>

            {isMobile && (
              <div className="Column">
                <PurchaseButtons
                  doPurchase={handlePurchase}
                  loading={loading || purchase.loading}
                  onReturnSummary={onReturnSummary}
                  t={t}
                />
              </div>
            )}
          </div>

          <div className="Purchase-retry-questions">
            <QuestionsInfo />
          </div>
        </div>
      </div>
    </PurchasePage>
  );
};
