import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { resetTagAfterRate } from '../actions';

const useCallbackAfterRate = (callbackMemo = () => { }, tags = []) => {
  const dispatch = useDispatch();
  const { loadingTagFinish } =
    useSelector(({ quoting }) => quoting, shallowEqual);
  const [lastTag, setLastTag] = useState('');

  useEffect(() => {
    if (tags.length === 0 || lastTag === loadingTagFinish) {
      return;
    }

    setLastTag(loadingTagFinish);
    if (tags.includes(loadingTagFinish)) {
      dispatch(resetTagAfterRate());
      setLastTag('');
      callbackMemo();
    }
  }, [dispatch, callbackMemo, loadingTagFinish, lastTag, tags]);
};

export { useCallbackAfterRate };
