import React, { useEffect, useRef, useState } from 'react';
import { doNothing } from '../../util';

const HtmlContent = ({
  classNameContainer = '',
  content = '',
  elementId = '',
  elementType = 'div',
}) => {
  const [contentLoaded, setContentLoaded] = useState();
  const ref = useRef();

  useEffect(() => {
    if (ref.current && !contentLoaded && content) {
      const elementExists = document.getElementById(elementId);

      if (elementExists) {
        doNothing();
        return;
      }

      const htmlContent = content;
      const htmlContainer = document.createElement(elementType);
      htmlContainer.classList.add(classNameContainer);
      htmlContainer.innerHTML = htmlContent;
      htmlContainer.setAttribute('id', elementId);
      ref.current.appendChild(htmlContainer);
      setContentLoaded(true);
    }
  }, [
    classNameContainer,
    content,
    contentLoaded,
    document,
    elementId,
    elementType,
    ref,
  ]);

  return (
    <div ref={ref} />
  );
};

export { HtmlContent };
