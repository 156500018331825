import React, { useMemo } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { needToKnowModalVisibleChanged } from '../../actions/quoting';
import { MODAL_RELATION_LIST } from '../../constants';
import './NeedToKnowNewDesign.css';
import ill12 from '../../assets/ills/ill-12.png';
import { useTranslations } from '../../hooks/useTranslations';

function NeedToKnowNewDesign({
  isEnrollment = false,
  classContainer = '',
}) {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const {
    multiModalData,
  } = store;
  const { t } = useTranslations('quoting');
  const dispatch = useDispatch();
  const { NEED_TO_KNOW_MODAL_ID } = MODAL_RELATION_LIST;

  const enrollmentClass = useMemo(() => (isEnrollment
    ? 'Need-to-know-innered-card-enrollment' : ''), [isEnrollment]);

  const handleOnclick = (categoryId) => () => {
    dispatch(needToKnowModalVisibleChanged({ categoryId, visible: true }));
  };

  function getMenuList() {
    if (multiModalData.InsuranceMultiModalSectionDtoList.length) {
      return multiModalData
        .InsuranceMultiModalSectionDtoList
        .find((element) => element.Id === NEED_TO_KNOW_MODAL_ID)
        .InsuranceMultiModalCategoryDtoList;
    }

    return [];
  }

  function getTitle() {
    if (multiModalData.InsuranceMultiModalSectionDtoList.length) {
      return multiModalData.InsuranceMultiModalSectionDtoList
        .find((element) => element.Id === NEED_TO_KNOW_MODAL_ID)
        .SectionName;
    }

    return '';
  }

  const NeedToKnowRow = ({ id, title }) => (
    <button
      className="Need-To-Know-link-container"
      id={`needToKnowRow${id}`}
      onClick={handleOnclick(id)}
      type="button"
    >
      <span className="Need-To-Know-Help-link-text">
        {title}
      </span>
    </button>
  );

  return (
    <section
      aria-label={t('needToKnow')}
      className="Need-To-Know-container-section"
    >
      <div className={'Need-To-Know-container'
        + ` ${enrollmentClass} ${classContainer}`}
      >
        <div className="Need-To-Know-titleContainer">
          <h1 className="Need-To-Know-title">{getTitle()}</h1>
          <img
            alt="a vet with a dog"
            className="Need-To-Know-Desktop-Image"
            src={ill12}
          />
        </div>

        <div className="Need-To-Know-links-container">
          <ul className="Need-to-know-links-list">
            {getMenuList().map((element) => (
              <li key={String(element.Id)}>
                <NeedToKnowRow
                  id={element.Id}
                  title={element.CategoryHeader}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default NeedToKnowNewDesign;
