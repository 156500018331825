import React from 'react';
import './TouchableDiv.css';

const TouchableDiv = ({
  ariaProps,
  children,
  className = '',
  compRef,
  disabled = false,
  id,
  onClick = () => { },
  onKeyDown = () => { },
  onKeyPress = () => { },
  onFocus = () => { },
  onBlur = () => { },
  style = {},
}) => {
  function handleOnclick(event) {
    if (event.target.id === id) {
      if (event.type === 'click' && onClick) {
        onClick(event);
      } else if (event.type === 'keypress' && onKeyPress) {
        onKeyPress(event);
      } else if (event.type === 'keydown' && onKeyDown) {
        onKeyDown(event);
      }
    }
  }

  return (
    <button
      ref={compRef}
      className={`Touchable-container ${className}`}
      disabled={disabled}
      id={id}
      onBlur={onBlur}
      onClick={handleOnclick}
      onFocus={onFocus}
      onKeyDown={handleOnclick}
      onKeyPress={handleOnclick}
      style={style}
      type="button"
      {...ariaProps}
    >
      {children}
    </button>
  );
};

export default TouchableDiv;
