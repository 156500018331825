import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import './PaymentSummary.css';
import { togglePaymentPlanFrequency } from '../../../actions';
import { currencyFormat } from '../../../util';
import TouchableDiv from '../../common/TouchableDiv';
import { BILLING_CYCLE_TYPE } from '../../../constants';
import { FakeCheckbox } from '../../common/FakeCheckbox';

const PaymentSummary = () => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const dispatch = useDispatch();
  const {
    isMonthlyPlan,
    quoteSelectedValues,
    data,
    paymentMethod,
  } = store;
  const { InsuranceProductFee } = data.insuranceProduct;
  const paymentType = paymentMethod.paymentMethodType.value;

  function getAnnualyFee() {
    const { annualy } = BILLING_CYCLE_TYPE;
    const fee = InsuranceProductFee
      .find((item) => item.InsuranceFee.BillingCycleType === annualy
        && item.InsuranceFee.PaymentType === paymentType);

    return fee;
  }

  function getMonthlyFee() {
    const { monthly } = BILLING_CYCLE_TYPE;
    const fee = InsuranceProductFee
      .find((item) => item.InsuranceFee.BillingCycleType === monthly
        && item.InsuranceFee.PaymentType === paymentType);

    return fee;
  }

  useEffect(() => {
    dispatch(togglePaymentPlanFrequency({ isMonthly: true }));
  }, [dispatch]);

  function getTotalMonthlyAmount() {
    const totalMonthlyAmount = quoteSelectedValues
      .reduce((prev, next) => prev + next.totalMontly, 0);
    const [dollar, cent] = currencyFormat({ value: totalMonthlyAmount })
      .split('.');

    return { cent: `.${cent}`, dollar };
  }

  function getTotalAnnualAmount() {
    const totalAnnualAmount = quoteSelectedValues
      .reduce((prev, next) => prev + next.totalAnnual, 0);
    const [dollar, cent] = currencyFormat({ value: totalAnnualAmount })
      .split('.');

    return { cent: `.${cent}`, dollar };
  }

  function getMonthlyFeeLegend() {
    const fee = getMonthlyFee();

    if (!fee) {
      return '';
    }

    const { InsuranceFee } = fee;

    return InsuranceFee.DisplayText.replace('{0}', InsuranceFee.Amount);
  }

  function getAnnualyFeeLegend() {
    const fee = getAnnualyFee();

    if (!fee) {
      return 'Save on any monthly processing fees.';
    }

    const { InsuranceFee } = fee;

    return InsuranceFee.DisplayText.replace('{0}', InsuranceFee.Amount);
  }

  function renderMonthlyFee() {
    const { cent, dollar } = getTotalMonthlyAmount();

    return (
      <div className="Billing-cycle-row Billing-cycle-price">
        <span className="Billing-cycle-price-currency-cent">$</span>

        <span className="Billing-cycle-price-dollar">
          {dollar}
        </span>

        <span className="Billing-cycle-price-currency-cent">
          {cent}
        </span>

        <span className="Billing-cycle-price-tag">&frasl; month</span>
      </div>
    );
  }

  function renderAnnualFee() {
    const { cent, dollar } = getTotalAnnualAmount();
    return (
      <div className="Billing-cycle-row Billing-cycle-price">
        <span className="Billing-cycle-price-currency-cent">$</span>

        <span className="Billing-cycle-price-dollar">
          {dollar}
        </span>

        <span className="Billing-cycle-price-currency-cent">
          {cent}
        </span>

        <span className="Billing-cycle-price-tag">&frasl; year</span>
      </div>
    );
  }

  const onPlaymentPlanChanged = (isMonthly) => () => {
    dispatch(togglePaymentPlanFrequency({ isMonthly }));
  };

  const getBillingCycleAriaLabel = (isMonthlyCard = false) => {
    let label = 'Billing cycle annual';
    let quantityAmount = getTotalAnnualAmount;
    let feeLabelLegend = getAnnualyFeeLegend;
    let currentLabelSelected = '';
    let bilingPaymentCycle = 'per year.';

    const monthlyLabelSelected =
      isMonthlyPlan && isMonthlyCard ? ' selected.' : ' not selected.';
    const annualLabelSelected =
      !isMonthlyPlan && !isMonthlyCard ? ' selected.' : 'not selected.';

    if (isMonthlyCard) {
      label = 'Billing cycle monthly';
      quantityAmount = getTotalMonthlyAmount;
      feeLabelLegend = getMonthlyFeeLegend;

      currentLabelSelected = monthlyLabelSelected;
      bilingPaymentCycle = 'per month.';
    } else {
      currentLabelSelected = annualLabelSelected;
    }

    const { cent, dollar } = quantityAmount();
    const feeLabel = feeLabelLegend();
    return `${label} ${dollar}${cent} ${bilingPaymentCycle} `
      + `${feeLabel} ${currentLabelSelected}`;
  };

  return (
    <>
      <h1 className="Billing-cycle-title">
        Billing Cycle
      </h1>

      <div
        className="Billing-cycle-section Billing-cycle-row"
      >
        <TouchableDiv
          ariaProps={{
            'aria-label': getBillingCycleAriaLabel(true),
            'aria-live': isMonthlyPlan ? 'polite' : 'off',
          }}
          className={`Billing-cycle-column Billing-cycle-div ${isMonthlyPlan
            ? 'Billing-cycle-selected' : ''}`}
          id="plan-sumary-monthly"
          onClick={onPlaymentPlanChanged(true)}
        >
          <div className="Billing-cycle-colmn Billing-cycle-plan-container">
            <div className="Billing-cycle-row Billing-cycle-plan-title">
              <span>Monthly</span>

              <div className="Billing-cycle-fee-container">
                <div className="Billing-cycle-price-mobile">
                  {renderMonthlyFee()}
                </div>

                <div className={'Personalize-tag-radio-button-container'
                  + ' Personalize-tag-radio-button-position'}
                >
                  <FakeCheckbox isChecked={isMonthlyPlan} />
                </div>
              </div>
            </div>

            {renderMonthlyFee()}

            <div className="Billing-cycle-row Billing-cycle-additional-fee">
              <span>
                {getMonthlyFeeLegend()}
              </span>
            </div>
          </div>
        </TouchableDiv>

        <TouchableDiv
          ariaProps={{
            'aria-label': getBillingCycleAriaLabel(false),
            'aria-live': !isMonthlyPlan ? 'polite' : 'off',
          }}
          className={`Billing-cycle-column Billing-cycle-div ${!isMonthlyPlan
            ? 'Billing-cycle-selected' : ''}`}
          id="plan-sumary-annual"
          onClick={onPlaymentPlanChanged(false)}
        >
          <div className="Billing-cycle-colmn Billing-cycle-plan-container">
            <div className="Billing-cycle-row Billing-cycle-plan-title">
              <span>Annual</span>

              <div className="Billing-cycle-fee-container">
                <div className="Billing-cycle-price-mobile">
                  {renderAnnualFee()}
                </div>

                <div className={'Personalize-tag-radio-button-container'
                  + ' Personalize-tag-radio-button-position'}
                >
                  <FakeCheckbox isChecked={!isMonthlyPlan} />
                </div>
              </div>
            </div>

            {renderAnnualFee()}

            <div className="Billing-cycle-row Billing-cycle-additional-fee">
              <span>
                {getAnnualyFeeLegend()}
              </span>
            </div>
          </div>
        </TouchableDiv>
      </div>
    </>
  );
};

export default PaymentSummary;
