import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { HeaderUnderwriting } from './HeaderUnderwriting';
import { MyQuote } from './MyQuote';
import { QuotingStepsComponent } from './QuotingStepsComponent';
import { PetQuoteDropDown } from '../../mobile/petQuotingDropDown';
import { useOnBodyScrollEvent } from '../../../hooks';
import { QuotingSteps } from '../../../constants';
import figoLogo from '../../../assets/figo-logo.svg';
import { isPaycheck } from '../../../util';
import './css/Header.css';

const Header = ({ isError = false }) => {
  const { t } = useTranslation('common');
  const store = useSelector(({ quoting }) => quoting);
  const {
    data,
    currentQuoteStep,
    isMonthlyPlan,
  } = store;

  const isSecondStep = currentQuoteStep === QuotingSteps.YourInfo.value;
  const isWelcomeStep = currentQuoteStep === QuotingSteps.Welcome.value;

  const { verticalScrollValue } = useOnBodyScrollEvent();
  const showFloating = verticalScrollValue < (isSecondStep ? -5 : -10);

  const petQuoteList = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.petQuoteResponseList || [];
  }, [data]);

  return (
    <header
      aria-label={t('header.siteHeader')}
      className={`Header ${showFloating ? 'Header-fixed' : ''}`}
      role="banner"
    >
      <HeaderUnderwriting />

      <div className="Header-content">
        <div>
          <img
            alt="Figo Logo"
            className="Header-logo-image"
            src={figoLogo}
          />

          <div className="Header-price">
            {isSecondStep ? (
              <MyQuote
                isMonthlyPlan={isMonthlyPlan}
                isPaycheck={isPaycheck}
                quoteSelectedValues={store.quoteSelectedValues}
              />
            ) : null}
          </div>
        </div>

        <div className="Header-actions">
          {isError ? null : (
            <QuotingStepsComponent
              currentQuoteStep={currentQuoteStep}
            />
          )}

          {!isWelcomeStep ? (
            <div
              className={'Header-pet-selector-wrapper'
                + `${isSecondStep
                  ? ' Header-pet-selector-wrapper-hide' : ''}`}
            >
              <PetQuoteDropDown
                hideAddPet={isSecondStep}
                petQuoteList={petQuoteList}
              />
            </div>
          ) : null}
        </div>
      </div>
    </header>
  );
};

export { Header };
