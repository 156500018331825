import React from 'react';

import ill14 from '../../../assets/ills/ill-14.svg';

import './YourInfoFloatingImages.css';

const YourInfoFloatingImages = ({
  isLoggedIn = false,
  isSecondStep = false,
}) => (
  <>
    {isSecondStep && (
    <img
      alt=""
      aria-hidden
      className={`Your-info-image-1 No-mobile No-tablet
      ${!isLoggedIn ? 'Your-info-image-1-lower' : ''}`}
      src={ill14}
    />
    )}
  </>
);

export { YourInfoFloatingImages };
