import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { LANDING_URL } from '../routes';

const useSessionFooter = () => {
  const store = useSelector((stores) => stores.quoting, shallowEqual);
  const {
    data,
    partners: {
      partnerId,
      data: {
        BottomDisclaimer,
        FooterText,
      },
    },
  } = store;
  const { path } = useRouteMatch();

  const sessionFooter = useMemo(() => {
    if (partnerId) {
      // if specific disclaimer for quote steps exists
      if (path !== LANDING_URL && BottomDisclaimer) {
        return BottomDisclaimer;
      }

      return FooterText;
    }

    if (!data || !data.insuranceProduct) {
      return '';
    }

    const footerSection = data
      .insuranceProduct.InsuranceInformationSections
      .find((item) => item.Title === 'Footer Text');

    return footerSection.InsuranceInformationDetails[0].Text;
  }, [data, partnerId, FooterText, BottomDisclaimer, path]);

  return sessionFooter;
};

export { useSessionFooter };
