import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  extolePurchaseCompleted,
  removeQuoteSelectedValueById,
  resetPurchaseData,
} from '../../../actions';
import { resetOneInInformation } from '../../../actions/oneInc';
import { useExtole, useOneIncScript, useResponsive } from '../../../hooks';
import { useDataLayerPurchase } from '../../../gtmDataLayer';

import closeIcon from '../../../assets/close.svg';
import downArrow from '../../../assets/purchase/down-arrow.svg';

import PurchasePage from './PurchasePage';
import { QuestionsInfo } from '../QuestionsInfo';
import { PetPurchaseResult } from './PetPurchaseResult';
import { PetCloudSection } from './PetCloudSection';
import { TextAlertsSection } from './TextAlertsSection';
import { OneIncModal } from '../../common/OneIncModal';

import './PurchaseSuccess.css';
import { URL_PURCHASE_RETRY } from '../../../routes';
import { closeWindow, useRetryPurchase } from '../../../util';
import { PURCHASE_ERROR_TYPES } from '../../../constants';

const { paymentMethodError } = PURCHASE_ERROR_TYPES;

const PurchaseSuccess = () => {
  const { t } = useTranslation('purchaseResult');
  const store = useSelector(({ quoting }) => quoting);
  const { isMobile } = useResponsive();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    inApp,
    inLoginApp,
    purchase: {
      incompletePurchases,
      policyNumbers,
    },
  } = store;

  // inApp: getQuote with temporally token
  // inLoginApp: signUp (no free flags, flow deprecated)
  const fromApp = inApp || inLoginApp;

  useOneIncScript();

  const extoleEvents = useExtole();
  useEffect(() => {
    if (store.purchase.extolePurchaseComplete) {
      return;
    }

    extoleEvents.sendPolicyPurchaseEvent();
    dispatch(extolePurchaseCompleted(true));
  }, [
    dispatch,
    extoleEvents,
    store.purchase.extolePurchaseComplete,
    store.purchase.success,
  ]);

  // GTM
  useDataLayerPurchase();

  const isFullySuccess = !incompletePurchases.length
    && !!policyNumbers.length;

  const title = useMemo(() => {
    if (incompletePurchases.length && policyNumbers.length) {
      return t('titlePartialSuccess');
    }
    if (incompletePurchases.length && !policyNumbers.length) {
      return t('titleFail');
    }

    return t('titleFullySuccess');
  }, [incompletePurchases, policyNumbers, t]);

  const text = useMemo(() => {
    if ((incompletePurchases.length && policyNumbers.length)
      || (incompletePurchases.length && !policyNumbers.length)) {
      return (
        <>
          {t('textFailPart1')}&nbsp;
          <strong>{t('textFailPart2')}</strong>
          &nbsp;{t('textFailPart3')}
        </>
      );
    }

    return t('textSuccess', {
      count: policyNumbers.length,
      pet: policyNumbers[0]?.PetName,
    });
  }, [incompletePurchases, policyNumbers, t]);

  const handleOnComplete = React.useCallback(() => {
    history.push(URL_PURCHASE_RETRY);
  }, [history]);

  const incompletePurchasesByPayment = incompletePurchases
    .filter(((item) => item.ErrorType === paymentMethodError));
  const incompletePurchasesByOther = incompletePurchases
    .filter(((item) => item.ErrorType !== paymentMethodError));

  useLayoutEffect(() => {
    dispatch(resetPurchaseData());
    dispatch(resetOneInInformation());
  }, [dispatch]);

  useEffect(() => {
    if (incompletePurchasesByPayment.length === 0) {
      return;
    }

    [
      ...policyNumbers,
      ...incompletePurchasesByOther,
    ]
      .forEach((policyNumber) => {
        dispatch(removeQuoteSelectedValueById(policyNumber?.PetQuoteId));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (store?.purchase?.loading || store?.searchParameters) {
      return;
    }

    history.push('/');
  }, [history, store]);

  return (
    <>
      {/* OneInc modal create here */}
      <div id="portalOneContainer" />

      <PurchasePage success={isFullySuccess}>
        <div
          className={'Purchase-result-container '
            + `Purchase-result-card ${isFullySuccess
              ? 'Purchase-result-success-container' : ''}`}
        >
          <div className="Purchase-result-content">
            <div className={'Purchase-result Pet-purchase-result-left'
              + ' Purchase-result-card-mobile'}
            >
              <h1>{title}</h1>

              <p className="Purchase-result-text">{text}</p>

              <div
                className={'Purchase-result-list '
                  + `${isFullySuccess ? 'Purchase-result-list-small' : ''}`}
                role="list"
              >
                {useRetryPurchase ? (
                  <>
                    {incompletePurchasesByPayment.length > 0 && (
                      <>
                        <div className="Purchase-result-fail-container-ul">
                          <ul>
                            {incompletePurchasesByPayment.map((pet) => (
                              <li key={pet.PetName}>
                                <PetPurchaseResult
                                  errorMessage={pet.PaymentError}
                                  isCat={pet.IsCat}
                                  petName={pet.PetName}
                                  success={false}
                                />
                              </li>
                            ))}
                          </ul>

                          <OneIncModal
                            onComplete={handleOnComplete}
                            styleUpdate
                          />
                        </div>
                      </>
                    )}

                    {incompletePurchasesByOther.length > 0 && (
                      <>
                        <ul>
                          {incompletePurchasesByOther.map((pet) => (
                            <li key={pet.PetName}>
                              <PetPurchaseResult
                                errorMessage={pet.PaymentError}
                                errorType={pet.ErrorType}
                                isCat={pet.IsCat}
                                petName={pet.PetName}
                                success={false}
                              />
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <ul>
                      {incompletePurchases.map((pet) => (
                        <li key={pet.PetName}>
                          <PetPurchaseResult
                            errorMessage={pet.PaymentError}
                            isCat={pet.IsCat}
                            petName={pet.PetName}
                            success={false}
                          />
                        </li>
                      ))}
                    </ul>
                  </>
                )}

                {policyNumbers.map((pet) => (
                  <li key={pet.PolicyNumber}>
                    <PetPurchaseResult
                      isCat={pet.IsCat}
                      petName={pet.PetName}
                      policyNumber={pet.PolicyNumber}
                      success
                    />
                  </li>
                ))}
              </div>
            </div>

            {!isFullySuccess && !fromApp && (
              <div className="Purchase-result-contact">
                <QuestionsInfo isPurchase />
              </div>
            )}
          </div>

          {inLoginApp && (
            <span className="Pet-cloud-section-complete-close-window">
              <img alt="" src={closeIcon} />
              {t('closeTab')}
            </span>
          )}

          {inApp && (
            <button
              aria-label="Close window"
              className="Purchase-close-button"
              onClick={closeWindow}
              title="Close window"
              type="button"
            >
              Close This Window
            </button>
          )}

          {!isFullySuccess && policyNumbers.length >= 1 && !fromApp && (
            <div className="Pet-cloud-section-no-fully-success">
              <hr aria-hidden />

              <PetCloudSection isCardFooter isFullySuccess={false} />
            </div>
          )}
        </div>

        {isFullySuccess && !fromApp && (
          <>
            <h2 className="Purchase-result-whats-next">{t('whatsNext')}</h2>

            <img
              alt=""
              className="Purchase-result-whats-next-icon"
              src={downArrow}
            />

            <p className="Purchase-result-whats-next-description">
              {t('whatsNextText')}
            </p>

            <div className="Purchase-result-whats-next-cards">
              <div
                className={'Purchase-result-card-flex1'
                  + ' Purchase-result-card-mobile'
                  + ' Purchase-result-whats-next-card'}
              >
                <PetCloudSection />
              </div>

              {isMobile && (
                <div className="Purchase-whats-next-card-separator" />
              )}

              <div
                className={'Purchase-result-card-flex1'
                  + ' Purchase-result-card-mobile'
                  + ' Purchase-result-whats-next-card'}
              >
                <TextAlertsSection />
              </div>
            </div>
          </>
        )}
      </PurchasePage>
    </>
  );
};

export default PurchaseSuccess;
