import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import figoLogo from '../../assets/landing/figo-logo.svg';
import linkExpired from '../../assets/saveQuote/link-expired.svg';

import Footer from '../common/conversion/Footer';

import './SavedQuoteExpired.css';

const SavedQuoteExpired = () => {
  const history = useHistory();
  const quoteLinkExpired = 'Quote Link Expired';
  const description = 'It looks like your quote has expired.';
  const getNewQuote = 'Get a New Quote';

  const handleGetNewQuote = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <div className="Saved-quote-expired-container">
      <img alt="" aria-hidden className="Saved-quote-logo" src={figoLogo} />

      <div className="Saved-quote-content-row">
        <img alt="" aria-hidden src={linkExpired} />

        <h1>{quoteLinkExpired}</h1>

        <span>{description}</span>

        <button onClick={handleGetNewQuote} type="button">
          {getNewQuote}
        </button>
      </div>

      <div className="Saved-quote-footer-container">
        <Footer />
      </div>
    </div>
  );
};

export { SavedQuoteExpired };
