import React from 'react';
import { ModalAddPet } from '../common/modalAddPet/ModalAddPet';
import './LandingRightContainer.css';

const LandingRightContainer = ({
  isPartner = false,
  partnerRowInformation,
  triggerValidation = false,
}) => (
  <div className="Landing-right-container" role="main">
    <div className="Landing-right-card-container">
      <div
        className={`Landing-right-card ${
          isPartner ? 'Landing-right-card-partner' : ''}`}
      >
        <ModalAddPet
          getQuote
          partnerRowInformation={partnerRowInformation}
          show
          validateOnMount={triggerValidation}
        />
      </div>
    </div>
  </div>
);

export { LandingRightContainer };
