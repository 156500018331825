import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import FocusTrap from 'focus-trap-react';
import queryString from 'query-string';

import { AddPetForm } from './AddPetForm';

import {
  addPet,
  changeGoodDogComingSoonModal,
  continueWithoutLogin,
  inAppStarted,
  loadAgeData,
  loadBreedData,
  noDiamondClientIdError,
  saveNewPetEmail,
  saveNewPetName,
  saveNewPetPromoCode,
  saveNewPetZipCode,
  saveSelectedEditPetModal,
  saveUpdatedPet,
  updatePet,
  validateCustomerExistsByEmail,
  validateZipCodeComingSoon,
  validZipCode,
} from '../../../actions/quoting';

import { getStateAbbreviation } from '../../../utils/zipcode';

import {
  useB2CInfo,
  useOnEscEvent,
  useTranslations,
} from '../../../hooks';
import {
  forceFocusElement,
  removeEmojis,
  testOnlyNumber,
  useCostcoTN,
  useGoodDogTN,
  useMarketingChannel,
  useROPC,
  validateEmail,
} from '../../../util';
import {
  MARKETING_CHANNEL,
  MAX_PET_NAME_LENGTH,
  PetGender,
  PetType,
  POWERUP_TYPE,
  USER_ROLL_LIST,
} from '../../../constants';

import './ModalAddPet.css';
import { usePartnersData } from '../../../partners/usePartnersData';
import { saveNotifyMeValuesEmail } from '../../../actions/partners';
import { LANDING_URL } from '../../../routes';

const ModalAddPet = ({
  getQuote = false,
  handleCloseModalAddPet = () => { },
  isEditing = false,
  partnerRowInformation = {
    detailMessage: { Content: '' },
    membersInfo: [],
  },
  show = false,
  validateOnMount,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [firstMount, setFirstMount] = useState(true);
  const [petTypeError, setPetTypeError] = useState('');
  const [petGenderError, setPetGenderError] = useState('');
  const [petNameError, setPetNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [zipCodeError, setZipcodeError] = useState('');
  const [ageError, setAgeError] = useState(false);
  const [breedError, setBreedError] = useState(false);
  const [validating, setValidating] = useState(false);
  const [termsPolicyError, setTermsPolicyError] = useState(false);
  const [isFocusTrapActive, setIsFocusTrapActive] = useState(false);
  const { loginB2C, logoutB2C } = useB2CInfo();
  const { accounts } = useMsal();

  const { t } = useTranslations('landing');
  const {
    goodDogData: {
      isPickupDateValid,
      onPetPickupDateChange,
      petPickupDate,
    },
    isCostco,
    isGoodDog,
  } = usePartnersData();

  const handleKeyDown = useCallback((event) => {
    const TAB_KEY = 9;

    if (event.keyCode === TAB_KEY && !getQuote) {
      setIsFocusTrapActive(true);
    }
  }, [getQuote]);

  const store = useSelector((stores) => stores.quoting);
  const {
    acceptedTermsAndPolicy,
    ageData,
    data,
    parameters,
    partners,
    petQuoteSelected,
    sessionInformation,
    sessionInformation: {
      validateZipCodeLoading,
    },
    subId,
    utmParams,
    newPet: {
      email,
      groupCodeDscr,
      petAge,
      petBreed,
      petBreedId,
      petName,
      petSex,
      petType,
      promoCode,
      zipcode,
    },
    petUpdated,
  } = store;

  const dispatch = useDispatch();
  const dogSpeciesRef = useRef(null);
  const maleGenderRef = useRef(null);
  const petNameRef = useRef(null);
  const petPickupDateRef = useRef(null);
  const petAgeRef = useRef(null);
  const petBreedRef = useRef(null);
  const petZipcodeRef = useRef(null);
  const petEmailRef = useRef(null);

  const quoteSelected = store.data?.petQuoteResponseList
    .find((item) => item.petQuoteId === petQuoteSelected);

  useEffect(() => {
    if (firstMount && show) {
      dispatch(loadBreedData());
      dispatch(loadAgeData());

      if (accounts.length && getQuote) {
        logoutB2C({ logoutUrl: LANDING_URL });
      }

      setFirstMount(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accounts.length,
    ageData,
    dispatch,
    firstMount,
    getQuote,
    show,
  ]);

  function getQuteButtonEnabled() {
    if (!ageData) {
      return false;
    }

    return (parameters.email || email) && zipcode && petName
      && petBreed && petBreedId && petAge && petAge !== ageData[0].Description
      && !petNameError && !emailError && !zipCodeError
      && !petTypeError && petType.value !== PetType.None.value
      && !petGenderError && petSex.value !== PetGender.None.value;
  }

  const getBodyForQuote = useCallback((isEditingPet) => {
    const { data: { petQuoteResponseList }, effectiveDateCustom } = store;

    // pets from rate quote response (now including URL pet)
    const currentPets = petQuoteResponseList
      .map((petQuote) => (
        {
          cloudOrderId: petQuote.cloudOrderId || 0,
          Id: petQuote.petQuoteId,
          Modifiers: petQuote.modifiers,
          PetAge: petQuote.petAgeName,
          PetBreed: petQuote.breedName,
          PetBreedId: petQuote.breedId,
          PetName: petQuote.petName,
          PetSex: petQuote.genderName,
          PetType: petQuote.petType === PetType.Dog.value
            ? PetType.Dog.name : PetType.Cat.name,
        }
      ));

    const higherId = Math.max(...petQuoteResponseList
      .map((quote) => quote.petQuoteId));

    const newPetIdAdded = higherId + 1;

    if (!isEditingPet) {
      // current pet for add
      const { insuranceProduct: { InsuranceModifiers } } = data;
      const modifiers = InsuranceModifiers
        .filter(
          (modifier) => modifier.InsuranceModifierTypeId === POWERUP_TYPE
            && modifier.IsVisible,
        )
        .map((item) => ({
          id: item.Id,
          isSelected: null,
        }));

      currentPets.push({
        Id: newPetIdAdded,
        Modifiers: modifiers,
        PetAge: petAge,
        PetBreed: petBreed,
        PetBreedId: petBreedId,
        PetName: petName,
        PetSex: petSex.name,
        PetType: petType.name,
      });
    }

    const quoteId = isEditingPet ? petQuoteSelected : newPetIdAdded;

    const body = {
      EffectiveDate: null,
      GroupCode: promoCode,
      GroupCodeDscr: parameters.groupCodeDscr,
      petQuotes: currentPets,
      quoteId,
      QuoteSubId: subId,
      TestEffectiveDate: effectiveDateCustom,
      ZipCode: parameters.petZipCode,
    };

    return body;
  }, [
    data,
    parameters.groupCodeDscr,
    parameters.petZipCode,
    petAge,
    petBreed,
    petBreedId,
    petName,
    petQuoteSelected,
    petSex.name,
    petType.name,
    promoCode,
    store,
    subId,
  ]);

  useEffect(() => {
    const { nopCommerceUser, userEmail } = sessionInformation;

    if (petUpdated) {
      const body = {
        ...getBodyForQuote(true),
        DiamondClientId: nopCommerceUser.DiamonClientdId,
        eMail: userEmail || parameters.email,
        nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
      };

      dispatch(updatePet({ body, isCostco, update: isEditing }));
      handleCloseModalAddPet();
      setFirstMount(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    getBodyForQuote,
    isCostco,
    parameters.email,
    petUpdated,
    sessionInformation,
  ]);

  useOnEscEvent(() => {
    handleCloseModalAddPet();
  }, []);

  useEffect(() => {
    setAgeError(false);
  }, [petAge]);

  useEffect(() => {
    setBreedError(false);
  }, [petBreed]);

  const handlePetNameChange = useCallback((event) => {
    const petNameTyped = removeEmojis(event.target.value);
    if (petNameTyped.length <= MAX_PET_NAME_LENGTH) {
      dispatch(saveNewPetName(petNameTyped));
      setPetNameError(!petNameTyped);
    }
  }, [dispatch]);

  const onFocusPetName = useCallback(() => {
    setPetNameError('');
  }, []);

  const handleZipcodeChange = useCallback((event) => {
    const { value } = event.target;
    const newValue = testOnlyNumber(value) ? value : zipcode;

    dispatch(saveNewPetZipCode(newValue));
  }, [dispatch, zipcode]);

  const handlePromoCodeChange = useCallback((event) => {
    const promoCodeTyped = event.target.value;
    dispatch(saveNewPetPromoCode(removeEmojis(promoCodeTyped)));
  }, [dispatch]);

  const handleEmail = useCallback((event) => {
    const { value } = event.target;

    dispatch(saveNewPetEmail(removeEmojis(value)));
  }, [dispatch]);

  const handleOnCancelClick = useCallback(() => {
    handleCloseModalAddPet();
    dispatch(saveSelectedEditPetModal(false));
  }, [dispatch, handleCloseModalAddPet]);

  const isValidZipCode = useCallback(async (showLoading = false) => {
    const zipValid = await dispatch(validZipCode({ showLoading, zipcode }));

    return zipValid;
  }, [dispatch, zipcode]);

  const onFocusEmail = useCallback(() => {
    setEmailError('');
  }, []);

  const onBlurEmail = useCallback(() => {
    let message = 'Email is required';

    if (email) {
      message = validateEmail(email) ? '' : 'Invalid email';
    }

    setEmailError(message);
  }, [email]);

  const areTermsRejected = useCallback(() => {
    setTermsPolicyError(!acceptedTermsAndPolicy);
    return !acceptedTermsAndPolicy;
  }, [acceptedTermsAndPolicy]);

  const handleEnterPress = useCallback((event) => {
    if (event.key === 'Enter') {
      onBlurEmail();
    }
  }, [onBlurEmail]);

  const onFocusZipcode = useCallback(() => {
    setZipcodeError('');
  }, []);

  const onBlurZipcode = useCallback(async () => {
    let message = 'Zip code is required';

    if (zipcode) {
      if (zipcode.length < 5) {
        message = 'Invalid zip code';
      } else {
        const zipCodeValidate = await isValidZipCode();

        message = zipCodeValidate ? '' : 'Invalid zip code';
      }
    }

    setZipcodeError(message);
  }, [isValidZipCode, zipcode]);

  const onBlurPetName = useCallback(() => {
    const newPetName = petName.split(' ').filter((item) => !!item).join(' ');
    dispatch(saveNewPetName(newPetName));
    const message = petName.length ? '' : 'Pet\'s name is required';
    setPetNameError(message);
  }, [dispatch, petName]);

  function setFocusOnErrorField() {
    if (petType.value === PetType.None.value && dogSpeciesRef.current) {
      dogSpeciesRef.current.focus();
      return;
    }

    if (petSex.value === PetGender.None.value && maleGenderRef.current) {
      maleGenderRef.current.focus();
      return;
    }

    if (!petName && petNameRef.current) {
      petNameRef.current.focus();
      return;
    }

    if (!petAge && petAgeRef.current) {
      petAgeRef.current.focus();
      return;
    }

    if (!petBreed && petBreedRef.current) {
      petBreedRef.current.focus();
      return;
    }

    if ((!zipcode || zipCodeError) && petZipcodeRef.current) {
      petZipcodeRef.current.focus();
      return;
    }

    if (!email && petEmailRef.current) {
      petEmailRef.current.focus();
    }
  }

  function doFormValidations() {
    setAgeError(!petAge);
    setBreedError(!petBreed);
    onBlurPetName();
    onBlurZipcode();
    onBlurEmail();
    setPetTypeError(petType.value === PetType.None.value
      ? 'Pet type must be selected in order to proceed' : '');
    setPetGenderError(petSex.value === PetGender.None.value
      ? 'Pet gender must be selected in order to proceed' : '');
    areTermsRejected();

    setFocusOnErrorField();
  }

  useEffect(() => {
    if (getQuote && validateOnMount) {
      doFormValidations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuote, validateOnMount]);

  const onBlurPromoCode = useCallback(() => {
    const newPromoCode =
      promoCode?.split(' ').filter((item) => !!item).join(' ');
    dispatch(saveNewPetPromoCode(newPromoCode));
  }, [dispatch, promoCode]);

  function validateFields() {
    return petName
      && (petSex.value !== PetType.None.value)
      && petBreed
      && petBreedId
      && petAge
      && (zipcode && !zipCodeError)
      && (ageData && petAge !== ageData[0].Description);
  }

  const handleOnSaveClick = async () => {
    const { sessionInformation: { nopCommerceUser, userEmail } } = store;
    if (handleCloseModalAddPet) {
      doFormValidations();

      if (validateFields()) {
        window.scrollTo(0, 0);

        if (isEditing) {
          const zipValid = await isValidZipCode(true);

          if (!zipValid) {
            setZipcodeError('Invalid zip code');

            return;
          }

          if (isCostco && useCostcoTN) {
            const validZipCodeComingSoon = await dispatch(
              validateZipCodeComingSoon({ zipcode }),
            );

            const isAvailableZipCode = !sessionInformation.showComingSoonModal
              && validZipCodeComingSoon;

            if (!isAvailableZipCode) {
              handleOnCancelClick();
              dispatch(saveNotifyMeValuesEmail({
                email,
                petAge,
                petBreed,
                petBreedId,
                petName,
                petSex: petSex.name,
                zipcode,
              }));

              return;
            }
          }

          dispatch(saveUpdatedPet());
        } else {
          const body = getBodyForQuote(isEditing);
          const bodyWithDiamondClientId = {
            ...body,
            DiamondClientId: nopCommerceUser.DiamonClientdId,
            eMail: userEmail || parameters.email,
            nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
          };
          dispatch(addPet(bodyWithDiamondClientId));
          setFirstMount(true);
          handleCloseModalAddPet();
        }
      }
    }
  };

  const onContinueWithoutLoginClick = ({ urlQuoting = '' }) => () => {
    dispatch(continueWithoutLogin());
    history.push(urlQuoting);
  };

  const onClickGetQuote = async () => {
    if (validating) {
      return;
    }

    setValidating(true);

    if (!getQuteButtonEnabled()) {
      doFormValidations();
      setValidating(false);
      return;
    }

    const zipValid = await isValidZipCode();

    if (!zipValid) {
      setZipcodeError('Invalid zip code');

      setValidating(false);
      return;
    }

    if (areTermsRejected()) {
      setValidating(false);
      return;
    }

    const { partnerId } = partners;
    const { query } = queryString.parseUrl(location.search);
    const agentIdParam = query.agentId ? { agentId: query.agentId } : {};

    if (query.inLoginApp) {
      dispatch(inAppStarted());
    }

    let quote = {
      ...utmParams,
      ...agentIdParam,
      email,
      id: '1',
      partnerGuid: partnerId || '',
      petAge,
      petBreed,
      petBreedId,
      petName,
      petSex: petSex.name,
      petType: petType.name,
      petZipCode: zipcode,
    };

    if (promoCode) {
      quote = {
        ...quote,
        groupCode: promoCode,
        groupCodeDscr: groupCodeDscr || '',
      };
    }

    if (subId && subId.Name && subId.Value) {
      quote[subId.Name] = subId.Value;
    }

    if (isGoodDog) {
      const pickupDateValid = isGoodDog ? isPickupDateValid : false;

      if (!pickupDateValid) {
        setValidating(false);

        return;
      }

      quote = {
        ...quote,
        // TestEffectiveDate: petPickupDate, // WIP: good dog
        petPickupDate, // WIP: good dog
      };
    }

    if (isCostco && useCostcoTN) {
      const validZipCodeComingSoon = await dispatch(
        validateZipCodeComingSoon({ zipcode }),
      );

      const isAvailableZipCode = !sessionInformation.showComingSoonModal
        && validZipCodeComingSoon;

      if (!isAvailableZipCode) {
        setValidating(false);
        dispatch(saveNotifyMeValuesEmail({
          email,
          petAge,
          petBreed,
          petBreedId,
          petName,
          petSex: petSex.name,
          zipcode,
        }));

        return;
      }
    }

    const payload = await validateCustomerExistsByEmail(email);
    const { IsValid, Data } = payload;
    let userAlreadyExists = IsValid && !!Data;

    if (useMarketingChannel && !useROPC) {
      userAlreadyExists = IsValid
        && Data?.MarketingChannelId === MARKETING_CHANNEL.Figo;
    }
    let hasCustomerRoll = false;

    if (IsValid && typeof Data === 'object') {
      hasCustomerRoll = !!Data.CustomerRoles
        .find((roll) => USER_ROLL_LIST.includes(roll));
    }

    if (userAlreadyExists && !hasCustomerRoll) {
      // users with no roll or diamondCliendId: 0 need call customer service.
      // WPI: Petclout Free user?

      dispatch(noDiamondClientIdError('User without customer roll'));
      setValidating(false);
      return;
    }

    const quoteBase = queryString.stringify(quote);
    const urlQuoting = `/quoting/getQuote?${quoteBase}`;

    setValidating(false);
    if (userAlreadyExists) {
      if (useROPC) {
        const urlLogin = `/login?${quoteBase}`;
        history.push(urlLogin, { hasPassword: Data?.HasPassword ?? true });
      } else {
        loginB2C({
          continueWithoutLogin: onContinueWithoutLoginClick({ urlQuoting }),
          existingEmail: email,
          urlState: urlQuoting,
        });
      }
    } else {
      window.open(urlQuoting, '_self');
    }
  };

  const handleSubmitPetForm = (event) => {
    event.preventDefault();

    if (isGoodDog && useGoodDogTN) {
      const stateAbbr = getStateAbbreviation(zipcode);

      if (stateAbbr === 'TN') {
        setValidating(false);

        dispatch(changeGoodDogComingSoonModal(true));
        return;
      }
    }

    if (getQuote) {
      onClickGetQuote();
      return;
    }

    if (validateZipCodeLoading) {
      return;
    }

    forceFocusElement(`plan${quoteSelected.Plan}Container`);
    handleOnSaveClick();
  };

  const InputError = useCallback(({ error, className = '' }) => (
    <span
      aria-live="assertive"
      className={`Modal-add-pet-input-error-label ${className}`}
    >
      {error}
    </span>
  ), []);

  function getTitle() {
    if (getQuote) {
      return (
        <div className="Modal-add-pet-title-container">
          <span>{t('modalAddPet.getQuote')}</span>
        </div>
      );
    }

    return isEditing ? t('modalAddPet.editPet') : t('modalAddPet.addPet');
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
      setIsFocusTrapActive(false);
    };
  }, [handleKeyDown]);

  return (
    <FocusTrap active={isFocusTrapActive}>
      <div
        className={`Modal-add-pet-content ${getQuote
          ? 'Modal-add-pet-content-landing' : ''}`}
      >
        <h1 className="Modal-add-pet-title">
          {getTitle()}
        </h1>

        <hr aria-hidden className="Modal-add-pet-title-separator" />

        <span
          className={'Modal-add-pet-title-description'
            + `${isEditing ? ' Gone' : ''}`}
        >
          Tell us a little bit about your pet
        </span>

        <AddPetForm
          ageError={ageError}
          breedError={breedError}
          emailError={emailError}
          getQuote={getQuote}
          handleEmail={handleEmail}
          handleOnCancelClick={handleOnCancelClick}
          handlePetNameChange={handlePetNameChange}
          handlePromoCodeChange={handlePromoCodeChange}
          handleSubmitPetForm={handleSubmitPetForm}
          handleZipcodeChange={handleZipcodeChange}
          InputError={InputError}
          isEditing={isEditing}
          onBlurEmail={onBlurEmail}
          onBlurPetName={onBlurPetName}
          onBlurPromoCode={onBlurPromoCode}
          onBlurZipcode={onBlurZipcode}
          onFocusEmail={onFocusEmail}
          onFocusPetName={onFocusPetName}
          onFocusZipcode={onFocusZipcode}
          onKeyPress={handleEnterPress}
          onPetPickupDateChange={onPetPickupDateChange}
          petAgeRef={petAgeRef}
          petBreedRef={petBreedRef}
          petEmailRef={petEmailRef}
          petGenderError={petGenderError}
          petGenderRef={dogSpeciesRef}
          petNameError={petNameError}
          petNameRef={petNameRef}
          petPickupDateError={!isPickupDateValid}
          petPickupDateRef={petPickupDateRef}
          petTypeError={petTypeError}
          petTypeRef={maleGenderRef}
          petZipcodeRef={petZipcodeRef}
          setPetGenderError={setPetGenderError}
          setPetTypeError={setPetTypeError}
          setTermsPolicyError={setTermsPolicyError}
          show={show}
          termsPolicyError={termsPolicyError}
          zipCodeError={zipCodeError}
        />

        {!isEditing && (
          <p className="Landing-pet-insurance-detail-text-mobile">
            {partnerRowInformation?.detailMessage?.Content}
          </p>
        )}
      </div>
    </FocusTrap>
  );
};

export { ModalAddPet };
