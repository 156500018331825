import { useEffect, useState } from 'react';

/**
 * @deprecated Use HtmlContent component instead. 🙏
 */
const useHtmlInjection = ({
  classNameContainer,
  content,
  elementType = 'div',
  ref,
}) => {
  const [contentLoaded, setContentLoaded] = useState();

  useEffect(() => {
    if (ref.current && !contentLoaded && content) {
      const htmlContent = content;
      const htmlContainer = document.createElement(elementType);
      htmlContainer.classList.add(classNameContainer);
      htmlContainer.innerHTML = htmlContent;
      ref.current.appendChild(htmlContainer);
      setContentLoaded(true);
    }
  }, [classNameContainer, content, contentLoaded, elementType, ref]);
};

export { useHtmlInjection };
