import { useSelector } from 'react-redux';
import { useTracking } from 'react-tracking';
import {
  baseRequest,
  customTrackEvent,
  EVENT_TYPE,
  PAYMENT_TYPES_TRACKING,
} from './baseRequest';

const useTrackingPaymentMethodSelection = () => {
  const { trackEvent } = useTracking({ }, { dispatch: customTrackEvent });
  const store = useSelector(({ quoting }) => quoting);
  const {
    data,
    parameters: { groupCodeDscr, email },
    subId,
  } = store;

  function trackPaymentMethodSelection({ isCreditCard }) {
    const paymentMethod = isCreditCard
      ? PAYMENT_TYPES_TRACKING.CreditCard : PAYMENT_TYPES_TRACKING.BankAccount;

    trackEvent({
      ...baseRequest({
        codeMask: groupCodeDscr,
        email,
        promoCode: data.groupCode || '',
        subId: subId.Value,
      }),
      event: EVENT_TYPE.PAYMENT_METHOD_SELECTION,
      paymentMethod,
    });
  }

  return { trackPaymentMethodSelection };
};

export { useTrackingPaymentMethodSelection };
