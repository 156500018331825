import React from 'react';
import './GenericPartnerDiscountLabel.css';

const GenericPartnerDiscountLabel = ({ logo, label }) => (
  <div className="Generic-partner-discount-label">
    <div className="Generic-partner-discount-label-container">
      {logo
        ? <img alt="" className="Generic-partner-discount-image" src={logo} />
        : null}

      {label ? <span>{label}</span> : null}
    </div>
  </div>
);

export { GenericPartnerDiscountLabel };
