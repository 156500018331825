/**
 * @Route
 * URL_PURCHASE = '/quoting/purchase/success'
 *
 * @Component
 * src/components/quoting/purchase/PurchaseSuccess.js
 *
 * @Description
 * A purchase event represents when the user reach the confirmation page and
 * generates a Policy completing the transaction
 * This event is fired when the Confirmation Page is Loaded.
 * The information comes from the Session Storage store.quoting key.
 * Be careful: It is a nested object that is difficult to process and prone
 * to errors.
 *
 * @Reference
 * https://developers.google.com/tag-manager/ecommerce-ga4?hl=es
 * #measure_purchases
 */
import { useEffect } from 'react';
import { dataLayerEvent, getDataLayerPurchaseEvent } from './dataLayer';
import { removeSessionStorage, useGoogleTagManager } from '../util';
import { STORE_ATTRIBUTE, EMPTY_STRING } from './constants';

export const useDataLayerPurchase = () => {
  useEffect(() => {
    if (!useGoogleTagManager) {
      return;
    }

    // clear the previous ecommerce data object
    dataLayerEvent({ ecommerce: null });

    getDataLayerPurchaseEvent()
      .then((purchaseEvent) => {
        if (purchaseEvent.ecommerce.items !== EMPTY_STRING) {
          dataLayerEvent(purchaseEvent);
        }
        removeSessionStorage(STORE_ATTRIBUTE.GTM);
      })
      .catch();
  }, []);
};
