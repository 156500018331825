import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PrepackagedPlanCard } from './PrepackagedPlanCard';
import { WhatsCoveredLink } from '../WhatsCoveredLink';

import customInactive from '../../../assets/plans/custom-inactive.svg';
import customActive from '../../../assets/plans/custom-active.svg';

import {
  isPaycheck,
  useCustomPlanPills,
  useTrackingEvents,
} from '../../../util';
import { MODAL_RELATION_LIST } from '../../../constants';
import {
  CUSTOM_PLAN_ID,
  prepackagedPlansIcons,
  WHATS_COVERED_LINK_ID,
} from './constants';
import {
  needToKnowModalVisibleChanged,
  saveDeductibleSelected,
  saveSelectedReimbursement,
  selectedPlan,
} from '../../../actions';
import { useCalculatePlanPrice, usePrevious } from '../../../hooks';
import { useTrackingPlanSelection } from '../../../trackingEvents';
import './PrepackagedPlanCard.css';

const { NEED_TO_KNOW_MODAL_ID } = MODAL_RELATION_LIST;

// TODO: paycheck only
const billingCycleLabel = {
  desktop: isPaycheck ? 'Per Paycheck' : 'Monthly Premium',
  mobile: isPaycheck ? '/paycheck' : '/month',
};

export const PlansSection = () => {
  const { t } = useTranslation('quoting');
  const dispatch = useDispatch();
  const store = useSelector(({ quoting }) => quoting);
  const { trackPlanSelection } = useTrackingPlanSelection();

  const { multiModalData } = store;

  const [ESSENTIAL_PLAN] =
    useMemo(() => store.plans, [store.plans]);

  const { quoteSelected, plansPackaged, plans } = useCalculatePlanPrice();

  const [planIdSelected, setPlanIdSelected] =
    useState(quoteSelected.selectedPlanPackaged);
  const prevPlanIdSelected = usePrevious(planIdSelected);

  const isCustomPlanSelected = quoteSelected.selectedPlanPackaged
    === CUSTOM_PLAN_ID;

  const isEssentialSelected = quoteSelected.selectedPlanIndex
    === ESSENTIAL_PLAN.index;

  function getFirstCategoryId(menuId, subnMenuId = null) {
    let categoryId = 1;

    if (multiModalData.InsuranceMultiModalSectionDtoList) {
      const categoryList = multiModalData
        .InsuranceMultiModalSectionDtoList
        .find((element) => element.Id === menuId);

      if (categoryList) {
        categoryId = categoryList
          .InsuranceMultiModalCategoryDtoList
          .find((sub) => sub.Id === subnMenuId || !subnMenuId).Id;
      }
    }

    return categoryId;
  }

  function showWhatCoverageModal() {
    const categoryId = getFirstCategoryId(NEED_TO_KNOW_MODAL_ID);
    dispatch(needToKnowModalVisibleChanged({ categoryId, visible: true }));
  }

  function getAriaLabelSelected(isSelected = true) {
    const { petName, total } = quoteSelected;

    if (!total) {
      return '';
    }

    let label = '';
    if (isSelected && planIdSelected !== prevPlanIdSelected) {
      label = `Updated premium for ${petName} is
        $${total} per month`;
    }

    return label;
  }

  const onChangePrepackagedPlan = useCallback((plan) => ({
    planId,
    planAmount,
    planPackagedId,
  }) => {
    dispatch(selectedPlan({ planAmount, planId, planPackagedId }));
    dispatch(saveSelectedReimbursement({
      description: plan.reimbursementName,
      id: plan.ReimbursementId,
    }));
    dispatch(saveDeductibleSelected({
      description: plan.deductibleName,
      id: plan.DeductibleId,
    }));

    if (useTrackingEvents) {
      const planIndex = plans
        .findIndex((item) => item.Plan === planId);
      if (quoteSelected.selectedPlanIndex !== planIndex) {
        trackPlanSelection({ planIndex, planPrice: planAmount });
      }
    }
  }, [dispatch, plans, quoteSelected.selectedPlanIndex, trackPlanSelection]);

  const onChangeCustomPlan = useCallback(({
    planAmount,
    planId,
    planPackagedId,
  }) => {
    dispatch(selectedPlan({ planAmount, planId, planPackagedId }));

    if (useTrackingEvents) {
      const planIndex = plans
        .findIndex((item) => item.Plan === planId);
      if (quoteSelected.selectedPlanPackaged
        !== CUSTOM_PLAN_ID) {
        trackPlanSelection({ planIndex, planPrice: planAmount });
      }
    }
  }, [dispatch, plans, quoteSelected.selectedPlanPackaged, trackPlanSelection]);

  useEffect(() => {
    setPlanIdSelected(quoteSelected.selectedPlanPackaged);
  }, [quoteSelected.selectedPlanPackaged]);

  const customPlanDisclaimer = useMemo(() => {
    if (!store.petQuoteSelected || !store.data
      || !store.data.petQuoteResponseList) {
      return '';
    }

    const byPet = store.data.petQuoteResponseList
      .find((pet) => pet.petQuoteId === store.petQuoteSelected);

    return byPet?.PrepackagedPlanDisclaimer;
  }, [store.petQuoteSelected, store.data]);

  return (
    <>
      <p className="Card-plan-main-text">
        {t('prepackagedPlans.text')}
      </p>

      <WhatsCoveredLink
        className="Powerup-see-whats-covered-link-main-container-plans"
        id={WHATS_COVERED_LINK_ID}
        onClick={showWhatCoverageModal}
        text={t('prepackagedPlans.coverage')}
      />

      <div className="Card-plan-main-prepackaged-plans">
        {!useCustomPlanPills && (
          <div aria-live="polite" className="Card-plan-main-log" role="log">
            {getAriaLabelSelected()}
          </div>
        )}

        {plansPackaged.map((plan, index) => (
          <PrepackagedPlanCard
            key={String(plan.Id)}
            activeIcon={prepackagedPlansIcons[index].active}
            billingCycleLabel={billingCycleLabel.mobile}
            description={plan.Description}
            inactiveIcon={prepackagedPlansIcons[index].inactive}
            maxAnnual={plan.maxAnnual}
            name={plan.Name}
            onPlanChange={onChangePrepackagedPlan(plan)}
            planId={plan.PlanId}
            planPackagedId={plan.Id}
            price={plan.monthlyPrice}
            selected={quoteSelected.selectedPlanPackaged === plan.Id}
          />
        ))}

        <PrepackagedPlanCard
          activeIcon={customActive}
          billingCycleLabel={billingCycleLabel.mobile}
          bottomDisclaimer={customPlanDisclaimer}
          inactiveIcon={customInactive}
          isEssentialSelected={isEssentialSelected}
          name={t('prepackagedPlans.customPlan')}
          onPlanChange={onChangeCustomPlan}
          planId={quoteSelected.selectedPlan}
          planPackagedId={CUSTOM_PLAN_ID}
          plans={plans}
          price={quoteSelected.totalMontly}
          quoteSelected={quoteSelected}
          selected={isCustomPlanSelected}
        />
      </div>
    </>
  );
};
