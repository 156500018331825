import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import calendarIcon from '../../../../assets/yourInfo/calendar-icon.svg';
import { isOpenQuote } from '../../../../utils/storage';

import './ImportantDatesNewDesign.css';

const DISCLAIMER_TEXT_ID = 3;

const ImportantDatesNewDesign = () => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const contentRef = useRef();
  const [contentLoaded, setContentLoaded] = useState();
  const { t } = useTranslation('quoting');

  const {
    data: {
      insuranceProduct: {
        InsuranceInformationSections,
      },
    },
  } = store;

  useEffect(() => {
    const isNotLoaded = contentRef.current && !contentLoaded;

    if (isNotLoaded && InsuranceInformationSections) {
      const disclaimerText = InsuranceInformationSections
        .find((item) => item.InsuranceInformationTypeId === DISCLAIMER_TEXT_ID)
        || { InsuranceInformationDetails: [{}] };

      const htmlContent = disclaimerText.InsuranceInformationDetails[0].Text;
      const htmlContainer = document.createElement('div');
      htmlContainer.innerHTML = htmlContent;

      setContentLoaded(true);
      contentRef.current.appendChild(htmlContainer);
    }
  }, [contentLoaded, InsuranceInformationSections]);

  const getEffectiveDate = () => {
    const { data: { effectiveDate } } = store;
    const openQuote = isOpenQuote();

    if (!openQuote) {
      return effectiveDate;
    }

    // TODO: work arround, effective date wrong when opening saved quote
    return moment(Date.now()).add(1, 'days');
  };

  function renderImportantDatesList() {
    const { data } = store;
    const importantDates = data.insuranceProduct.InsuranceWaitingPeriods;

    const effectiveDate = getEffectiveDate();

    return importantDates.map((date) => {
      const dateText = moment(effectiveDate)
        .add(date.Units, date.IsMonth ? 'months' : 'days')
        .format('MMMM D, YYYY');

      return (
        <section
          key={String(date.Id)}
          className="Important-dates-invdividual-card"
        >
          <img
            alt=""
            className="Important-dates-invdividual-card-icon"
            src={calendarIcon}
          />

          <div className="Important-dates-invdividual-card-col">
            <span className="Important-dates-invdividual-card-title">
              {date.InsuranceWaitingPeriodType
                && date.InsuranceWaitingPeriodType.Name}
            </span>

            <span className="Important-dates-invdividual-card-description">
              {dateText}
            </span>
          </div>
        </section>
      );
    });
  }

  return (
    <div className="Important-dates-main-card">
      <h1 className="Important-dates-title-header">
        {t('importantDates.title')}
      </h1>

      <div className="Important-dates-cards-container">
        {renderImportantDatesList()}
      </div>

      <span ref={contentRef} className="Important-dates-description" />
    </div>
  );
};

export { ImportantDatesNewDesign };
